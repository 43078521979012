import React, { useContext, useState } from "react";
import {
  IonPage,
  IonContent,
  IonItem,
  IonLabel,
  IonButton,
  IonLoading,
} from "@ionic/react";
import { UserContext } from "providers/User";

import "./Register.css";

import EsperiHeaderWithoutNavigation from "components/EsperiHeaderWithoutNavigation";
import { withUserService, WithUserServiceProps } from "service";
import { Redirect, RouteComponentProps } from "react-router";
import { useTranslation } from "react-i18next";
import { withErrorHandler, WithErrorHandlerProps } from "../index";
import { getBrandStrings } from '../../util/Branding';

const RegisterVerificationCode = (
  props: WithUserServiceProps & RouteComponentProps & WithErrorHandlerProps
) => {
  const { state } = useContext(UserContext);
  const [isSending, setIsSending] = useState(false);
  const { t } = useTranslation();

  function fetchVerificationCode(e: any) {
    e.preventDefault();
    setIsSending(true);
    props.userService
      .sendConfirmationCodeSMS({ invitationToken: state.invitationToken })
      .then(() => {
        setIsSending(false);
        props.history.push("/varmistuskoodi");
      })
      .catch((error) => {
        setIsSending(false);
        props.handleError(error);
      });
  }

  if (!state.invitationToken) {
    return <Redirect to="/" />;
  }

  return (
    <IonPage className="pageWhite pageWidth centered">
      <IonLoading isOpen={isSending} message={t("creatingActivationCode")} />
      <IonContent className="contentPaddingHorizontal">
        <EsperiHeaderWithoutNavigation />
        <IonItem className="title centered theme" lines="none">
          <IonLabel>{t("hello", { name: state.firstName })}</IonLabel>
        </IonItem>
        <IonItem className="body1 centered grey" lines="none">
          <IonLabel className="ion-text-wrap">
            {t("welcomeToRegister", getBrandStrings())}
          </IonLabel>
        </IonItem>
        <IonItem className="noPadding smallText grey" lines="none">
          <IonLabel className="registerLabelTitleText">{t("name")}</IonLabel>
        </IonItem>
        <IonItem className="noPadding charCoal" lines="none">
          <IonLabel className="registerLabelText">
            {state.firstName + " " + state.lastName}
          </IonLabel>
        </IonItem>
        <IonItem className="noPadding smallText grey" lines="none">
          <IonLabel className="registerLabelTitleText">{t("email")}</IonLabel>
        </IonItem>
        <IonItem className="noPadding charCoal" lines="none">
          <IonLabel className="registerLabelText">{state.email}</IonLabel>
        </IonItem>
        <IonItem className="noPadding smallText grey" lines="none">
          <IonLabel className="registerLabelTitleText">
            {t("phoneNumber")}
          </IonLabel>
        </IonItem>
        <IonItem className="noPadding charCoal" lines="none">
          <IonLabel className="registerLabelText">{state.phoneNumber}</IonLabel>
        </IonItem>
        <IonItem className="noPadding smallText grey" lines="none">
          <IonLabel className="registerAdditionalText">
            {t("codeWillBeSent")}
          </IonLabel>
        </IonItem>
      </IonContent>
      <IonItem>
        <IonButton
          className="buttonRed buttonBottom"
          onClick={(e) => fetchVerificationCode(e)}
        >
          {t("continue")}
        </IonButton>
      </IonItem>
    </IonPage>
  );
};

export default withUserService(withErrorHandler(RegisterVerificationCode));

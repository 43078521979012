import React, { useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  IonCheckbox,
  IonIcon,
  IonInput,
  IonItem,
  IonSearchbar,
} from "@ionic/react";
import ActiveHomePicker from "./ActiveHomePicker";

import "./SearchMenu.css";
import { HomeItem } from "../providers/Admin/AdminActionTypes";

interface SearchMenuProps {
  nameSearch?: boolean | undefined;
  activeMenu?: boolean | undefined;
  onActiveOnlyChange?: (onlyActive: boolean) => void;
  onQueryChange?: (quert: string | undefined) => void;
  isLoading?: boolean;
}

const SearchMenu = (props: SearchMenuProps) => {
  const { t } = useTranslation();

  const [query, setQuery] = useState<string | undefined>(undefined);
  const [activeOnly, setActiveOnly] = useState<boolean>(true);

  // Ref for resetting focus on searchbar, when customerPicker is open
  const inputRef = useRef<any>(null);

  // UseEffect which will make sure that focus is reset on search bar
  useEffect(() => {
    setTimeout(() => inputRef.current.setFocus(), 100);
  })

  useEffect(() => {
    if (props.onQueryChange) {
      props.onQueryChange(query);
    }
  }, [query]);

  useEffect(() => {
    if (props.onActiveOnlyChange) {
      props.onActiveOnlyChange(activeOnly);
    }
  }, [activeOnly]);

  return (
    <div className="searchMenu" slot="end">
      <ActiveHomePicker slot="end" />

      <div className="bottomRow">
        <IonInput
          className="searchInput"
          value={query}
          debounce={1000}
          onIonChange={(e: any) =>
            setQuery((e.target as HTMLInputElement).value)
          }
          placeholder={t("searchPlaceHolder")}
          ref={(ref) => inputRef.current = ref}
          disabled={props.isLoading}
        >
          <IonIcon className="searchIcon" name="search-outline"></IonIcon>
        </IonInput>
        <div className="activeSelector">
          {t("onlyActive")}
          <IonCheckbox
            className="activeSelectorCb"
            onIonChange={(e: any) => {
              setActiveOnly(e.detail.checked);
            }}
            checked={activeOnly}
          ></IonCheckbox>
        </div>
      </div>
    </div>
  );
};

export default SearchMenu;

import {
  ActionType,
  AdminActionTypes,
  AdminUser,
  HomeItem,
} from "./AdminActionTypes";

export interface AdminAppState {
  adminUsers: AdminUser[]; // Array containing homes where user has family
  homes: HomeItem[];
}

export const initialState: AdminAppState = {
  adminUsers: [],
  homes: [],
};

export const adminReducer = (
  state: AdminAppState,
  action: AdminActionTypes
) => {
  switch (action.type) {
    case ActionType.ClearAdminUsers: {
      return { ...state, adminUsers: [] };
    }
    case ActionType.GetAdminUserResponse:
    case ActionType.UpdateAdminUserResponse:
      const index = state.adminUsers.findIndex(
        (a) => a.id === action.adminUser.id
      );
      const adminUsers =
        index >= 0
          ? [
              ...state.adminUsers.slice(0, index),
              action.adminUser,
              ...state.adminUsers.slice(index + 1),
            ]
          : [...state.adminUsers, action.adminUser];

      return { ...state, adminUsers };
    case ActionType.GetAdminUsersResponse:
      return {
        ...state,
        adminUsers: [...state.adminUsers, ...action.adminUsers],
      };
    case ActionType.GetHomesResponse:
      return { ...state, homes: action.homes };
    default:
      return state;
  }
};

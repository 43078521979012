import React, { createContext, useReducer, Dispatch } from "react";
import { logger } from "../index";
import { AdminAppState, adminReducer, initialState } from "./AdminReducer";
import { AdminActionTypes } from "./AdminActionTypes";

interface AdminContextProps {
  state: AdminAppState;
  dispatch: Dispatch<AdminActionTypes>;
}

let AdminContext = createContext({} as AdminContextProps);
const loggerReducer = logger(adminReducer, "Admin");

function AdminContextProvider(props: any) {
  let [state, dispatch] = useReducer(loggerReducer, initialState);
  let value = { state, dispatch };

  return (
    <AdminContext.Provider value={value}>
      {props.children}
    </AdminContext.Provider>
  );
}

export { AdminContext, AdminContextProvider };

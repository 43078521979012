import axios from "axios";
import React from "react";
import { HTTP_API_URL } from "util/Constants";
import { UserAppState } from "providers/User/UserReducer";
import {
  PostConfirmCodeRequest,
  PostConfirmInvitationRequest,
  PostSignupRequest,
  PostSigninRequest,
  PostPasswordRecoveryRequest,
  PostSettingsRequest,
  PostConfirmPasswordRecoveryRequest,
} from "./RegisterRequests";
import { ConsentItem, UserActionTypes } from "providers/User/UserActionTypes";

import { UserActions } from "providers/User";
import { SessionUtils } from "util/SessionUtils";
import { BaseService } from "./BaseService";

export interface UserSession {
  email: string;
  IdToken: string;
  RefreshToken: string;
  AccessToken: string;
  ClientId: string;
  UserPoolId: string;
  business: string;
}

export default class UserService extends BaseService {
  private readonly dispatch: React.Dispatch<UserActionTypes>;

  constructor(
    userAppState: UserAppState,
    dispatch: React.Dispatch<UserActionTypes>
  ) {
    super(userAppState);
    this.dispatch = dispatch;
  }

  /*
   API
   */

  checkInvitationToken(invitationToken: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/signup/confirm/invitation");
      url.searchParams.append("invitationToken", invitationToken);
      try {
        const response = await axios.get(url.toString());
        this.dispatch(
          UserActions.onGetConfirmInvitationResponse(
            invitationToken,
            response.data
          )
        );
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  sendConfirmationCodeSMS(request: PostConfirmInvitationRequest): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/signup/confirm/invitation");
      try {
        const response = await axios.post(url.toString(), request);
        this.dispatch(
          UserActions.onPostConfirmInvitationResponse(response.data)
        );
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  checkConfirmationCode(request: PostConfirmCodeRequest): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/signup/confirm/code");
      try {
        const response = await axios.post(url.toString(), request);
        this.dispatch(UserActions.onPostConfirmCodeResponse(response.data));
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  setPassword(request: PostSignupRequest): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/signup");
      try {
        const response = await axios.post(url.toString(), request);
        SessionUtils.storeSession(request.email, response.data);
        this.dispatch(UserActions.onPostSignupResponse(response.data));
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  fetchConsents(idToken: string = ""): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/consents");
      try {
        const response = await axios.get(
          url.toString(),
          this.addAuthorizationHeaders(idToken)
        );
        this.dispatch(UserActions.onConsentsResponse(response.data));
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  fetchTermsAndConditions(): Promise<ConsentItem> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/consents");
      url.searchParams.append("accepted", "true");
      try {
        const response = await axios.get(
          url.toString(),
          this.addAuthorizationHeaders()
        );

        let consent: ConsentItem = response.data.consents.find(
          (consent: ConsentItem) => consent.type === "terms"
        );
        if (consent) {
          resolve(consent);
        } else {
          reject();
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  fetchPrivacyPolicy(): Promise<ConsentItem> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/consents");
      url.searchParams.append("accepted", "true");
      try {
        const response = await axios.get(
          url.toString(),
          this.addAuthorizationHeaders()
        );

        let consent: ConsentItem = response.data.consents.find(
          (consent: ConsentItem) => consent.type === "privacy"
        );
        if (consent) {
          resolve(consent);
        } else {
          reject();
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  approveConsent(id: number): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/consents/" + id.toString());
      try {
        const response = await axios.post(
          url.toString(),
          null,
          this.addAuthorizationHeaders()
        );
        this.dispatch(UserActions.onConsentsResponse(response.data));
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  signIn(request: PostSigninRequest): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/signin");
      try {
        const response = await axios.post(url.toString(), request);
        SessionUtils.storeSession(request.email, response.data);
        this.dispatch(UserActions.onPostSigninResponse(response.data));
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  signOut(): Promise<any> {
    return new Promise(async (resolve) => {
      const url = new URL(HTTP_API_URL + "/signout");
      try {
        const response = await axios.post(url.toString(), null, {
          headers: {
            Authorization: this.userAppState.AccessToken,
          },
        });
        SessionUtils.clearSession();
        this.dispatch(UserActions.onSignedOut());
        resolve(response);
      } catch (e) {
        SessionUtils.clearSession(true);
        this.dispatch(UserActions.onSignedOut());
        resolve();
      }
    });
  }

  recoverPassword(request: PostPasswordRecoveryRequest): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/password/recovery");
      try {
        const response = await axios.post(url.toString(), request);
        this.dispatch(UserActions.onRecoveryEmailSent(request.email));
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  confirmNewPassword(
    request: PostConfirmPasswordRecoveryRequest
  ): Promise<any> {
    const url = new URL(HTTP_API_URL + "/password/recovery/confirm");
    return axios.post(url.toString(), request);
  }

  fetchSettings(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/settings");
      try {
        const response = await axios.get(
          url.toString(),
          this.addAuthorizationHeaders()
        );
        this.dispatch(UserActions.onSettingsResponse(response.data));
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  saveSettings(request: PostSettingsRequest): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/settings");
      try {
        if (request.settings) {
          const response = await axios.post(
            url.toString(),
            request,
            this.addAuthorizationHeaders()
          );
          this.dispatch(UserActions.onSettingsResponse(response.data));
          resolve(response);
        } else {
          resolve([]);
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  fetchProfile(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/profile");
      try {
        const response = await axios.get(
          url.toString(),
          this.addAuthorizationHeaders()
        );
        this.dispatch(UserActions.onUserProfileResponse(response.data));
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }
}

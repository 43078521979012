import React, { useContext } from "react";
import { IonList, IonItem, IonImg } from "@ionic/react";
import "./EsperiHeader.css";
import { getLogo } from 'util/Branding';
import { UserContext } from 'providers/User';

const EsperiBigHeader = () => {
  const { state } = useContext(UserContext);

  return (
    <IonList className="ion-no-border" lines="none">
      <IonItem className="bigHeaderTopSpacer" />
      <IonImg className="centerBigImage" src={getLogo(state.business)} />
      <IonItem className="bigHeaderBottomSpacer" />
    </IonList>
  );
};

export default EsperiBigHeader;

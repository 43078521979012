import { IonButton, IonCol, IonHeader, IonIcon, IonModal, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { withErrorHandler, WithErrorHandlerProps, withInfoHandler, WithInfoHandlerProps } from "pages";
import CustomerDetailsPage from "pages/Main/Customers/CustomerDetailsPage";
import { Customer } from "providers/Customer/CustomerActionTypes";
import { OmaUser } from "providers/OmaUser/OmaUserActionTypes";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { withCustomerService, WithCustomerServiceProps, withOmaUserService, WithOmaUserServiceProps } from "service";
import OmaUserDetailsPage from "../pages/Main/OmaUsers/OmaUserDetailsPage";
import EsperiSpinner, { SpinnerSize } from "./EsperiSpinner";

interface WizardModalProps extends WithCustomerServiceProps, WithOmaUserServiceProps, WithErrorHandlerProps, WithInfoHandlerProps {
  isOpen: boolean
  customer: Customer | undefined

  onWizardCompleted: (customer: Customer, omaUser: OmaUser) => any
  onCancel: () => any
}

const WizardModal = (props: WizardModalProps) => {
  const { t } = useTranslation();

  const [customer, setCustomer] = useState<Customer | undefined>(undefined)
  const [headerTitle, setHeaderTitle] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const createCustomer = !props.customer

  useEffect(() => {
    setCustomer(props.customer)
  }, [props.customer])

  useEffect(() => {
    if (customer) {
      setHeaderTitle(`${t('createOmaUserForCustomerNamed')} ${customer.firstName} ${customer.lastName}`)
    } else {
      setHeaderTitle(t('createCustomer'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const didDismissOmaUser = async (omaUser: OmaUser) => {
    setLoading(true);
    try {
      // @ts-ignore
      const createdOmaUser = await props.omaUserService.postCreateOmaUser({ omaUser })

      const c: Customer = { ...customer } as Customer
      c.users = c.users ? c.users.concat(createdOmaUser) : [createdOmaUser]

      const updatedCustomer = createCustomer ?
        // @ts-ignore
        await props.customerService.postCreateCustomer({ customer: c }) :
        // @ts-ignore
        await props.customerService.postUpdateCustomer({ customer: c })

      // @ts-ignore
      const { data: invitedOmaUSer } = await props.omaUserService.postInviteOmaUser({ omaUser: createdOmaUser })
      props.onWizardCompleted(updatedCustomer as Customer, invitedOmaUSer)

      props.showInfo(t('wizardCompleted'))
      setCustomer(undefined);
    } catch (error) {
      props.handleError(error);
    }
    setLoading(false)
  }

  const didDismissCustomer = (customer: Customer) => {
    setCustomer(customer)
    setHeaderTitle(`${t('createOmaUserForCustomerNamed')} ${customer.firstName} ${customer.lastName}`)
  }

  const renderCustomerModal = () => {
    return (<CustomerDetailsPage isModal={true} didDismiss={didDismissCustomer}></CustomerDetailsPage>)
  }

  const renderOmaUserModal = () => {
    return (<OmaUserDetailsPage createForCustomer={customer} isModal={true} didDismiss={didDismissOmaUser}></OmaUserDetailsPage>)
  }

  const renderContent = () => {
    if (customer) {
      return renderOmaUserModal()
    } else {
      return renderCustomerModal()
    }
  }

  return (
    <IonModal
      isOpen={props.isOpen}
      cssClass="pickerModal"
      showBackdrop={false}
    >
      <EsperiSpinner size={SpinnerSize.Large} hidden={!loading} />
      <IonHeader>
        <IonToolbar>
          <IonRow slot="start">
            <IonCol>
              <IonButton
                fill="clear"
                onClick={() => {
                  setCustomer(undefined);
                  props.onCancel();
                }}
              >
                <IonIcon
                  className="appTitleIconNormal"
                  src={"/assets/ic_menu_close.svg"}
                />
              </IonButton>
            </IonCol>
          </IonRow>
          <IonTitle className="pageHeaderTitle grey">{headerTitle}</IonTitle>
        </IonToolbar>
      </IonHeader>
      {renderContent()}
    </IonModal>
  )
};


export default withInfoHandler(withErrorHandler(withOmaUserService(withCustomerService(WizardModal))));

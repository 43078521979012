export enum MainTab {
  NewsTab,
  MessagesTab,
  AdminsTab,
  EmployeesTab,
  DevicesTab,
  OmaUsersTab,
  CustomersTab,
  CustomersNewsTab
}

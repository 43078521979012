export enum ActionType {
  ClearCustomerNews = "ClearCustomerNews",
  GetCustomerNewsResponse = "GetCustomersNewsResponse",
  GetCustomerNewsDetailsResponse = "GetCustomerNewsDetailsResponse",
  CreateCustomerNewsResponse = "CreateCustomerNewsResponse",
  UpdateCustomerNewsResponse = "UpdateCustomerNewsResponse"
}

export interface ClearCustomerNews {
  type: ActionType.ClearCustomerNews;
}

export interface GetCustomerNewsResponse {
  type: ActionType.GetCustomerNewsResponse;
  customerNews: CustomerNews[];
  nextPage: string;
  unreadCount: number;
}

export interface GetCustomerNewsDetailsResponse {
  type: ActionType.GetCustomerNewsDetailsResponse;
  customerNewsDetails: CustomerNewsDetails;
}

export interface CreateCustomerNewsResponse {
  type: ActionType.CreateCustomerNewsResponse;
  customerNewsDetails: CustomerNewsDetails;
}

export interface UpdateCustomerNewsResponse {
  type: ActionType.UpdateCustomerNewsResponse;
  customerNewsDetails: CustomerNewsDetails;
}

export interface CustomerNews {
  id: string;
  title: string;
  authorName: string;
  itemType: string;
  published: boolean;
  timestamp: string;
}

export interface CustomerNewsDetails {
  id: string;
  homeId?: string;
  title: string;
  lead: string;
  content: string;
  authorName: string;
  canEdit: boolean,
  itemType: string;
  published: boolean;
  timestamp: string;
  headerImage: CustomerNewsImage;
  headerImageUuid: string;
}

export interface CustomerNewsImage {
  url: string;
}

export type CustomerNewsActionTypes =
  | ClearCustomerNews
  | GetCustomerNewsResponse
  | GetCustomerNewsDetailsResponse
  | CreateCustomerNewsResponse
  | UpdateCustomerNewsResponse

import { HomeItem } from "../Admin/AdminActionTypes";

export enum ActionType {
  ClearDevices = "DeviceEmployees",
  GetDevicesResponse = "GetDevicesResponse",
  GetDeviceResponse = "GetDeviceResponse",
  UpdateDeviceResponse = "UpdateDeviceResponse",
  CreateDeviceResponse = "CreateDeviceResponse",
  GetDeviceRegistrationCode = "GetDeviceRegistrationCode",
  DeactivateDeviceResponse = "DeactivateDeviceResponse",
}

export interface ClearDevices {
  type: ActionType.ClearDevices;
}

export interface GetDevicesResponse {
  type: ActionType.GetDevicesResponse;
  devices: Device[];
}

export interface GetDeviceResponse {
  type: ActionType.GetDeviceResponse;
  device: Device;
}

export interface CreateDeviceResponse {
  type: ActionType.CreateDeviceResponse;
  device: Device;
}

export interface UpdateDeviceResponse {
  type: ActionType.UpdateDeviceResponse;
  device: Device;
}

export interface GetDeviceRegistrationCode {
  type: ActionType.GetDeviceRegistrationCode;
  registrationCode: RegistrationCode;
}

export interface DeactivateDeviceResponse {
  type: ActionType.DeactivateDeviceResponse;
  deviceId: number;
}

export type DeviceActionTypes =
  | ClearDevices
  | GetDevicesResponse
  | GetDeviceResponse
  | UpdateDeviceResponse
  | GetDeviceRegistrationCode
  | DeactivateDeviceResponse
  | CreateDeviceResponse;

/*
 MODEL
 */

export enum DeviceStatus {
  CREATED = "CREATED",
  INVITED = "INVITED",
  REGISTERED = "REGISTERED",
  DEACTIVATED = "DEACTIVATED",
}

export interface Device {
  id: number;
  os: string;
  vendor: string;
  name: string;
  regiteredAt: string;
  model: string;
  status: string;
  home: HomeItem | undefined;
}

export interface DevicesResponse {
  devices: Device[];
  nextPage: string;
  unreadCount: number;
}

export interface DeviceResponse {
  device: Device;
}

export interface RegistrationCode {
  code: string;
  expiresAt: string;
}

import {
  ActionType,
  Employee,
  EmployeeActionTypes,
} from "./EmployeeActionTypes";

export class EmployeeActions {
  static clearEmployees(): EmployeeActionTypes {
    return {
      type: ActionType.ClearEmployees,
    };
  }

  static onGetEmployeesResponse(employees: Employee[]): EmployeeActionTypes {
    return {
      type: ActionType.GetEmployeesResponse,
      employees: employees,
    };
  }

  static onGetEmployeeResponse(employee: Employee): EmployeeActionTypes {
    return {
      type: ActionType.GetEmployeeResponse,
      employee: employee,
    };
  }

  static onUpdatedEmployeeResponse(employee: Employee): EmployeeActionTypes {
    return {
      type: ActionType.UpdateEmployeeResponse,
      employee: employee,
    };
  }

  static onActivateEmployeeResponse(employee: Employee): EmployeeActionTypes {
    return {
      type: ActionType.PostActivateEmployeeResponse,
      employee: employee,
    };
  }

  static onDeactivateEmployeeResponse(employee: Employee): EmployeeActionTypes {
    return {
      type: ActionType.PostDeactivateEmployeeResponse,
      employee: employee,
    };
  }

  static onEmployeePasswordResetResponse(
    password: string
  ): EmployeeActionTypes {
    return {
      type: ActionType.EmployeePasswordResetResponse,
      password: password,
    };
  }
}

import React from "react";
import { IonCol, IonRow, IonText } from "@ionic/react";
import { withCustomerService, WithCustomerServiceProps } from "service";
import "../MainPage.css";
import { Customer } from "../../../providers/Customer/CustomerActionTypes";

interface OmaUserCellProps extends WithCustomerServiceProps {
  customer: Customer;
  onClick: () => void;
}

const CustomerCell = (props: OmaUserCellProps) => {

  const omaUsers = props.customer?.users?.map((c) => {
    return <div key={c.id}>{`${c.lastName}, ${c.firstName}`}</div>;
  });


  return (
    <div
      className="OmaUserCell"
      onClick={(e) => {
        e.preventDefault();
        props.onClick();
      }}
    >
      <IonRow style={{ background: "white" }}>
        <IonCol
          size="4"
          className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <IonText className="body2 grey ion-padding-bottom">
            {`${props.customer.lastName}, ${props.customer.firstName}`}
          </IonText>
        </IonCol>
        <IonCol
          size="4"
          className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <IonText className="body2 grey ion-padding-bottom">
            {omaUsers}
          </IonText>
        </IonCol>
        <IonCol
          size="4"
          className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <IonText className="body2 grey ion-padding-bottom">
            {`${props.customer.home?.name}`}
          </IonText>
        </IonCol>
      </IonRow>
    </div>
  );
};

export default withCustomerService(CustomerCell);

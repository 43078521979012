import moment from "moment";
import { TFunction } from "i18next";
import { EmployeeStatus } from "../providers/Employee/EmployeeActionTypes";
import { OmaUserStatus } from "../providers/OmaUser/OmaUserActionTypes";

export class Formatter {
  static boldParts(text: string, boldParts: string[] | null): string {
    boldParts?.forEach((highlight) => {
      text = text.replace(RegExp(highlight, "g"), `<b>${highlight}</b>`);
    });
    return text;
  }

  static getMessageSendDate(date: moment.Moment, t: TFunction): string {
    if (moment().isSame(date, "day")) {
      return t("today");
    } else if (moment().subtract(1, "day").isSame(date, "day")) {
      return t("yesterday");
    } else if (moment().isSame(date, "weeks")) {
      return t("thisWeek");
    } else if (moment().subtract(1, "weeks").isSame(date, "weeks")) {
      return t("lastWeek");
    } else {
      return date.calendar();
    }
  }

  static getMessageSendTime(created: string, t: TFunction): string {
    const date = moment(created);
    if (moment().isSame(date, "day")) {
      return date.format("LT");
    } else if (moment().subtract(1, "day").isSame(date, "day")) {
      return t("yesterday") + " " + date.format("LT");
    } else if (moment().isSame(date, "weeks")) {
      return date.format("dddd LT");
    } else {
      return date.format("DD.MM.YYYY LT");
    }
  }

  static getTimeAndDate(timestamp: string): string {
    const date = moment(timestamp);
    return date.format("HH:MM DD.MM.YYYY");
  }

  static getDate(timestamp: string): string {
    const date = moment(timestamp);
    return date.format("DD.MM.YYYY");
  }

  static customerNewsStatusLocalization(status: boolean, t: TFunction): string {
    const lc = status.toString();
    const keyCapitalized = lc.charAt(0).toUpperCase() + lc.slice(1);
    const key = `customerNewsPublished${keyCapitalized}`;
    return t(key);
  }


  static statusLocalization(status: string, t: TFunction): string {
    const lc = status.toLowerCase();
    const keyCapitalized = lc.charAt(0).toUpperCase() + lc.slice(1);
    const key = `status${keyCapitalized}`;
    return t(key);
  }

  static employeeStatusLocalization(status: string, t: TFunction): string {
    switch (status) {
      case EmployeeStatus.CREATED:
      case EmployeeStatus.REGISTERED:
        return t("employeeStatusActive");
      default:
        return Formatter.statusLocalization(status, t);
    }
  }

  static omaUserStatusLocalization(status: string, t: TFunction): string {
    switch (status) {
      case OmaUserStatus.CREATED:
        return t("omaUserStatusCreated");
      case OmaUserStatus.INVITED:
        return t("omaUserStatusInvited");
      case OmaUserStatus.REGISTERED:
        return t("omaUserStatusActive");
      default:
        return t("omaUserStatusDeactivate");
    }
  }

  static roleLocalization(role: string, t: TFunction): string {
    if (!role) return "";
    const lc = role.toLowerCase();
    const keyCapitalized = lc.charAt(0).toUpperCase() + lc.slice(1);
    const key = `role${keyCapitalized}`;
    return t(key);
  }

  static deviceStatusLocalization(status: string, t: TFunction): string {
    const lc = status.toLowerCase();
    const keyCapitalized = lc.charAt(0).toUpperCase() + lc.slice(1);
    const key = `deviceStatus${keyCapitalized}`;
    return t(key);
  }
}

import React from "react";
import { IonRow, IonText, IonGrid, IonImg, IonButton } from "@ionic/react";
import "./ErrorView.css";
import { useTranslation } from "react-i18next";

interface ErrorViewProps {
  title: string;
  body: string;
  hidden: boolean;
  onTryAgain: () => void;
}

const ErrorView = (props: ErrorViewProps) => {
  const { t } = useTranslation();

  function getTitle() {
    return props.title || t("errorGeneralTitle");
  }

  function getBody() {
    return props.title || t("errorGeneralBody");
  }

  return (
    <IonGrid hidden={props.hidden} className="errorView contentMaxWidth">
      <IonRow>
        <IonImg className="centered" src="/assets/img_error.svg" />
      </IonRow>
      <IonRow>
        <IonText className="centered body1Bold errorTitle">
          {getTitle()}
        </IonText>
      </IonRow>
      <IonRow>
        <IonText className="centered body2 errorBody">{getBody()}</IonText>
      </IonRow>
      <IonRow hidden={props.onTryAgain === null}>
        <IonButton
          onClick={props.onTryAgain}
          className="buttonRedOutline errorButton"
        >
          {t("tryAgain")}
        </IonButton>
      </IonRow>
    </IonGrid>
  );
};

ErrorView.defaultProps = {
  title: "",
  body: "",
  onTryAgain: null,
};

export default ErrorView;

export function isEmailValid(email: string) {
  return (
    null !=
    email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  );
}

export function isPasswordValid(password: string) {
  return (
    null != password.match(/^((?!.*[\s])(?=.*[a-zäö])(?=.*[A-ZÄÖ]).{10,})/)
  );
}

export function isVerificationCodeValid(verificationCode: string) {
  return verificationCode.length > 0;
}

export function isChanged(obj1: any, obj2: any, keyList: string[]): boolean {
  if (!obj1 || !obj2) {
    return false;
  }

  const index = keyList.findIndex((k) => {
    return JSON.stringify(obj1[k]) !== JSON.stringify(obj2[k]);
  });
  return index >= 0;
}

export function isValid(obj1: any, keyList: string[]): boolean {
  if (!obj1) {
    return true;
  }

  const index = keyList.findIndex((k) => {
    return !obj1[k] || obj1[k].length === 0;
  });
  return index < 0;
}

import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import "./CustomerNewsDetailsPage.css";

import PageHeader from "components/PageHeader";
import { RouteComponentProps, withRouter } from "react-router";

import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { withCustomerNewsService, WithCustomerNewsServiceProps } from "service";
import { withErrorHandler, WithErrorHandlerProps, withInfoHandler, WithInfoHandlerProps } from "pages";
import { useTranslation } from "react-i18next";
import { AdminContext } from "providers/Admin";
import { UserContext } from "providers/User";
import { UIContext } from "providers/UI/UIAppState";
import { CustomerNews, CustomerNewsDetails } from "providers/CustomerNews/CustomerNewsActionTypes";
import { isChanged, isValid } from "util/Validator";
import EsperiSpinner, { SpinnerSize } from "components/EsperiSpinner";
import EmptyView, { EmptyViewType } from "components/EmptyView";
import PhotoUpload from "components/PhotoUpload";

import "./CustomerNews.css";
import CustomerNewsTypePicker from "./CustomerNewsTypePicker";

interface CustomerNewsDetailsProps
  extends WithCustomerNewsServiceProps,
  RouteComponentProps,
  WithErrorHandlerProps,
  WithInfoHandlerProps {
  isModal: boolean | undefined,
  customerNews: CustomerNews | undefined,
  isOpen?: boolean,
  onCancel?: () => void,
  didDismiss?: (details: CustomerNewsDetails) => void,
  fetchCustomerNews?: () => void | undefined,
  clearActiveNews?: () => void,
}

const CustomerNewsDetailsPage = (props: CustomerNewsDetailsProps) => {

  const { t } = useTranslation();

  const { state: adminState } = useContext(AdminContext);
  const { state: userState } = useContext(UserContext);
  const { state: uiState } = useContext(UIContext);

  const [photo, setPhoto] = useState<File | null>(null)
  const [updatedPhoto, setUpdatedPhoto] = useState<File | null>(null)
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty())
  const [details, setDetails] = useState<CustomerNewsDetails | undefined>(undefined);
  const [originalDetails, setOriginalDetails] = useState<CustomerNewsDetails | undefined>(
    undefined
  );
  const [isNewDetails, setIsNewDetails] = useState<boolean>(false);
  const isNew = !(details && details.id === undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [isConfirmDeleteDetailsOpen, setIsConfirmDeleteDetailsOpen] = useState<
    boolean
  >(false);

  const [isContentEditing, setIsContentEditing] = useState<boolean>(false);

  const keys = ["title", "content", "lead"];
  const isEdited = isChanged(details, originalDetails, keys) || updatedPhoto;
  const validDetails = isValid(details, keys);

  const fetchCustomerNewsDetails = (customerNewsId: string) => {
    setIsNewDetails(false);
    setIsLoading(true);

    props.customerNewsService
      .fetchCustomerNewsDetails(customerNewsId)
      .then((response) => {
        setDetails({ ...response.customerNewsDetails });
        setOriginalDetails({ ...response.customerNewsDetails });

        const blocksFromHtml = htmlToDraft(response.customerNewsDetails.content);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        setEditorState(EditorState.createWithContent(contentState))

        if (isOpen && response.customerNewsDetails.id !== undefined) {
          setIsOpen(false);
        }
      })
      .catch((error) => {
        props.handleError(error);
      })
      .finally(() => {
        setIsLoadingPage(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // @ts-ignore
    if (props.match.params.id && !props.isModal) {
      // @ts-ignore
      const customerNewsId: string = props.match.params.id;
      setIsLoadingPage(true);
      fetchCustomerNewsDetails(customerNewsId);
    } else {

      if (props.customerNews) {
        setIsLoadingPage(true);
        fetchCustomerNewsDetails(props.customerNews.id);
      } else {
        setIsNewDetails(true);
        // @ts-ignore
        const newDetails: CustomerNewsDetails = {
          itemType: uiState.csTargetHome ? "home" : uiState.csItemType,
          homeId: uiState.csTargetHome ? uiState.csTargetHome.id.toString() : undefined,
          published: true
        };
        setDetails({ ...newDetails });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.url, props.customerNews]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const interval = setInterval(() => {
      // @ts-ignore
      fetchC(device.id);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [isOpen]);

  useEffect(() => {
    if (details) {
      const newDetails: CustomerNewsDetails = {
        ...details,
        homeId: uiState.csTargetHome ? uiState.csTargetHome.id.toString() : undefined,
        itemType: uiState.csItemType
      }
      setDetails({ ...newDetails });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiState.csTargetHome, uiState.csItemType])

  const editDetails = (newState: {}) => {
    if (details) {
      const newValue = { ...details, ...newState };
      setDetails({ ...newValue });
    }
  };

  const handleNewEditorState = (newState: EditorState) => {
    const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    editDetails({ content });
    setEditorState(newState);
  };

  const closeModalAndClearActive = () => {
    // Clear active news, so it doesnt show old news data when clicking another item
    props.clearActiveNews && props.clearActiveNews();
    // Set modal to false
    props.onCancel && props.onCancel();
    // Fetch news again, so view updates
    props.fetchCustomerNews && props.fetchCustomerNews();
  };

  const handleSave = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const headerImageUuid = updatedPhoto ?
        await props.customerNewsService.postHeaderImage(updatedPhoto) :
        undefined;

      // @ts-ignore
      await props.customerNewsService.putUpdateCustomerNews({ ...details, headerImageUuid })
      // @ts-ignore
      //fetchCustomerNewsDetails(details.id);

      setUpdatedPhoto(null);

      props.showInfo(t("customerNewsSaved"));

      setIsLoading(false);

      closeModalAndClearActive();
    } catch (error) {
      props.showInfo(t("customerNewsSaveFailed"));
      setIsLoading(false);
    }
  };

  const handlePublishedStatusChange = async (e: any, newStatus: boolean) => {
    e.preventDefault();
    setIsLoading(true);

    const operationType = newStatus ? 'Published' : 'Unpublished';

    try {
      // @ts-ignore
      await props.customerNewsService.putUpdateCustomerNews({ ...details, published: newStatus })
      // @ts-ignore
      //fetchCustomerNewsDetails(details.id);
      setIsLoading(false);
      props.showInfo(t("customerNews" + operationType));
      closeModalAndClearActive();
    } catch (error) {
      props.showInfo(t("customerNews" + operationType + "Failed"));
      setIsLoading(false);
    }
  };

  const handleCreate = async () => {

    setIsLoading(true);

    try {
      const headerImageUuid = updatedPhoto ? await props.customerNewsService.postHeaderImage(updatedPhoto) : undefined;
      const newDetails = headerImageUuid ? { ...details, headerImageUuid } : details;
      // @ts-ignore
      const response = await props.customerNewsService.postCreateCustomerNews(newDetails);

      setDetails({ ...response.customerNewsDetails });
      setOriginalDetails({ ...response.customerNewsDetails });

      setIsNewDetails(false);
      setUpdatedPhoto(null);

      setIsLoading(false);
      props.showInfo(t("customerNewsPublished"));
      closeModalAndClearActive();
    } catch (error) {
      props.showInfo(t("customerNewsSaveFailed"));
      setIsLoading(false)
    }

  };

  const handlePhotoUpload = useCallback(
    (file: any): any => {
      setPhoto(file)
      setUpdatedPhoto(file)
    },
    []
  )

  const renderDetails = () => {
    if (!details) {
      return (
        <IonItemDivider>
          <IonLabel>{t("customerNewsDetails")}</IonLabel>
        </IonItemDivider>
      );
    }

    return (
      <>
        <IonLabel className="fieldLabel">
          {t("customerNewsHeader")}
        </IonLabel>
        <IonItem
          className="headerItem"
          lines="none">
          <IonInput
            className="titleInput"
            value={details.title}
            placeholder={t("customerNewsTitlePL")}
            onIonChange={(e: any) =>
              editDetails({ title: (e.target as HTMLInputElement).value })
            }
          />
        </IonItem>
        <IonItem lines="none" className="photoUploadItem center">
          <PhotoUpload
            className="headerPhoto"
            src={details.headerImage ? details.headerImage.url : ''}
            setPhoto={handlePhotoUpload}>
          </PhotoUpload>
        </IonItem>
        <IonLabel className="fieldLabel">{t("customerNewsLead")}</IonLabel>
        <IonItem className="leadItem" lines="none">
          <IonInput
            type="text"
            className="leadInput"
            value={details.lead}
            placeholder={t("customerNewsLeadPL")}
            onIonChange={(e: any) =>
              editDetails({ lead: (e.target as HTMLInputElement).value })
            }
          />
        </IonItem>
        <IonLabel className="fieldLabel">{t("customerNewsContent")}</IonLabel>
        <IonItem lines="none" hidden={isContentEditing} onClick={() => { setIsContentEditing(true) }} className="contentPreview">
          <p
            className="ion-padding-horizontal ion-margin-vertical contentMaxWidth"
            dangerouslySetInnerHTML={{ __html: details.content ? details.content : t('customerNewsEditorPlaceholder') }}
          />
        </IonItem>
        <IonItem lines="none" hidden={!isContentEditing} className="contentEditorItem">
          <Editor
            editorState={editorState}
            // Editor toolbar defines shown buttons and options
            toolbar={{
              options: ['inline', 'list', 'textAlign', 'history'],
              inline: {
                options: [
                  'bold', 'italic', 'underline',
                ],
              },
              blockType: {
                inDropdown: false,
                options: []
              }
            }}
            toolbarClassName="toolbarClass"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={handleNewEditorState}
          />
        </IonItem>
      </>
    );
  };

  const renderContent = () => {

    return (<>
      <IonContent hidden={isDeleted}>
        <IonGrid className="customerNewsEditor">
          <IonRow hidden={isLoadingPage}>
            <IonCol>
              {renderDetails()}
            </IonCol>
          </IonRow>
          <IonRow className="AdminFormContainer" hidden={isLoadingPage}>
            <IonCol>
              <IonItem lines="none" class="ion-text-end">
                {!isNewDetails &&
                  details &&
                  details.id !== undefined &&
                  details.published && (
                    <IonButton slot="end" onClick={(e) => handlePublishedStatusChange(e, false)} disabled={!!isEdited}>
                      {t("unpublishCustomerNews")}
                    </IonButton>
                  )}
                {!isNewDetails &&
                  details &&
                  details.id !== undefined &&
                  !details.published && (
                    <IonButton
                      slot="end"
                      onClick={(e) => handlePublishedStatusChange(e, true)}
                      disabled={!!isEdited}
                    >
                      {t("publishCustomerNews")}
                    </IonButton>
                  )}
                {isNewDetails && (
                  <IonButton
                    slot="end"
                    onClick={() => { handleCreate() }}
                    disabled={!validDetails}
                  >
                    {t("create")}
                  </IonButton>
                )}
                {!isNewDetails && (
                  <IonButton
                    slot="end"
                    onClick={handleSave}
                    disabled={!isEdited}
                  >
                    {t("save")}
                  </IonButton>
                )}
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>)
  }

  const renderPage = () => {
    return (
      <IonPage className="pageLightGrey calendarEventDetails">
        <PageHeader
          title={details
            ? details.title :
            t("createCustomerNews")} />
        <EsperiSpinner
          size={SpinnerSize.Large}
          hidden={!(isLoading && !isOpen)}
        />
        <IonAlert
          isOpen={isConfirmDeleteDetailsOpen}
          onDidDismiss={() => setIsConfirmDeleteDetailsOpen(false)}
          header={t("confirmDeleteDeviceHeader")}
          message={t("confirmDeleteDevice")}
          buttons={[
            {
              text: t("cancel"),
              role: "cancel",
              handler: () => { },
            },
            {
              text: t("OK"),
              /*handler: deactivateDevice,*/
            },
          ]}
        />
        <EmptyView hidden={!isDeleted} type={EmptyViewType.DeviceDeleted} />
        {renderContent()}
      </IonPage>
    );
  }

  const renderModal = () => {

    return (
      <IonModal
        isOpen={!!props.isOpen}
        cssClass="customerNewsModal"
        showBackdrop={false}
        onDidDismiss={() => {
          // Clear active news, so they dont show up when opening next news
          props.clearActiveNews && props.clearActiveNews();
          // Close modal
          props.onCancel && props.onCancel();
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonRow >
              <IonButton
                fill="clear"
                onClick={() => {
                  // Clear active news, so old data doesnt show when opening new
                  props.clearActiveNews && props.clearActiveNews();
                  // Clear editor state, so it doesnt show old stuff in inputfield
                  setEditorState(EditorState.createEmpty());
                  // Closes modal
                  props.onCancel && props.onCancel();
                }}
              >
                <IonIcon
                  className="appTitleIconNormal"
                  src={"/assets/ic_menu_close.svg"}
                />
              </IonButton>
              <IonTitle className="pageHeaderTitle grey">{details?.title}</IonTitle>
              <CustomerNewsTypePicker
                className="customerNewsHomePicker" />
            </IonRow>
          </IonToolbar>
        </IonHeader>
        {renderContent()}
      </IonModal>
    )
  }

  if (props.isModal) {
    return renderModal()
  } else {
    return renderPage()
  }
};

export default withRouter(
  withCustomerNewsService(withErrorHandler(withInfoHandler(CustomerNewsDetailsPage)))
);

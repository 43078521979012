import React, { Suspense, useContext, useEffect, useRef } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { IonApp, IonPage, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import LoginPage from "pages/Login/LoginPage";
import ForgotPasswordPage from "pages/ForgotPassword/ForgotPasswordPage";
import RegisterPersonalInfo from "pages/Register/RegisterPersonalInfo";
import RegisterVerificationCode from "pages/Register/RegisterVerificationCode";
import RegisterPassword from "pages/Register/RegisterPassword";
import RegisterEmailNotifications from "pages/Register/RegisterEmailNotifications";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "theme/variables.css";
import "theme/common.css";

import { UserContext, UserContextProvider } from "providers/User/UserAppState";
import { UIContext, UIContextProvider } from "providers/UI/UIAppState";
import ConsentPage from "pages/Consent/ConsentPage";
import SplashPage from "pages/Splash/SplashPage";
import { PrivateRoute } from "pages";
import OnboardingPage from "pages/Onboarding/OnboardingPage";
import SideMenu from "pages/SideMenu/SideMenu";
import MainPage from "pages/Main/MainPage";
import ProfilePage from "pages/Profile/ProfilePage";
import FeedEventDetailsPage from "pages/Main/Feed/FeedEventDetails/FeedEventDetailsPage";
import { UIActions } from "providers/UI/UIActions";
import RecoverPasswordPage from "pages/ForgotPassword/RecoverPasswordPage";
import { UserAppState } from "providers/User/UserReducer";
import { UIAppState } from "providers/UI/UIReducer";
import SideMenuDesktop from "pages/SideMenu/SideMenuDesktop";
import TermsPage from "pages/Terms/TermsPage";
import PrivacyPolicyPage from "pages/PrivacyPolicy/PrivacyPolicyPage";
import { AdminContextProvider } from "./providers/Admin/AdminAppState";
import AdminDetailsPage from "./pages/Main/Admins/AdminDetailsPage";
import { EmployeeContextProvider } from "./providers/Employee/EmployeeAppState";
import EmployeesDetailsPage from "./pages/Main/Employees/EmployeesDetailsPage";
import DevicesDetailsPage from "./pages/Main/Devices/DevicesDetailsPage";
import { DeviceContextProvider } from "./providers/Device/DeviceAppState";
import { OmaUserContextProvider } from "./providers/OmaUser/OmaUserAppState";
import OmaUserDetailsPage from "./pages/Main/OmaUsers/OmaUserDetailsPage";
import { CustomerContextProvider } from "providers/Customer/CustomerAppState";
import CustomerDetailsPage from "./pages/Main/Customers/CustomerDetailsPage"
import CustomerNewsDetailsPage from "pages/Main/CustomerNews/CustomerNewsDetailsPage";
import { CustomerNewsContext } from "providers/CustomerNews";
import { CustomerNewsContextProvider } from "providers/CustomerNews/CustomerNewsAppState";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { getFavicon, getManifest, getPageTitle } from "util/Branding";
import { getThemeColor } from './util/Branding';

// @ts-ignore
const App: React.FC = () => {
  const routerRef = useRef<IonReactRouter>(null);

  const WindowResize = () => {
    const { state } = useContext(UserContext);
    const { state: uiState, dispatch } = useContext(UIContext);
    const location = useLocation();



    const onWindowResize = () => {
      dispatch(UIActions.setSplitPaneVisible(window.innerWidth >= 768));
    };

    useEffect(() => {
      // Dynamically set brand assets
      const brand = state.business;
      document.title = getPageTitle(brand);
      const favicon = document.getElementById('favicon-placeholder');
      if (favicon) {
        favicon.setAttribute('href', getFavicon(brand));
      }
      const manifest = document.getElementById('manifest-placeholder');
      if (manifest) {
        manifest.setAttribute('href', getManifest(brand));
      }
      document.documentElement.style.setProperty('--ion-customer-theme', getThemeColor(brand));
    }, [state.business]);

    useEffect(() => {
      window.addEventListener("resize", onWindowResize);
      return () => {
        window.removeEventListener("resize", onWindowResize);
      };
    });

    function isMenuDisabled(userAppState: UserAppState) {
      return (
        !userAppState.AccessToken ||
        location.pathname === "/ehdot" ||
        location.pathname === "/ilmoitukset" ||
        location.pathname === "/luoSalasana"
      );
    }

    function getMenu(userAppState: UserAppState, uiAppState: UIAppState) {
      if (isMenuDisabled(userAppState)) {
        return null;
      }
      if (uiAppState.isSplitPaneVisible) {
        return <SideMenuDesktop />;
      }
      return <SideMenu />;
    }

    function getMainPageLeft(
      userAppState: UserAppState,
      uiAppState: UIAppState
    ) {
      if (isMenuDisabled(userAppState)) {
        return 0;
      }
      if (uiAppState.isSplitPaneVisible) {
        return 320;
      }
      return 0;
    }

    return (
      <>
        {getMenu(state, uiState)}
        <IonPage
          id="mainPage"
          style={{
            left: getMainPageLeft(state, uiState),
          }}
        >
          <IonRouterOutlet id="router">
            <Route path="/" component={SplashPage} exact />
            <Route path="/kirjautuminen" component={LoginPage} exact />
            <Route
              path="/unohtuikoSalasana"
              component={ForgotPasswordPage}
              exact
            />
            <Route
              path="/palautaSalasana"
              component={RecoverPasswordPage}
              exac
            />
            <Route
              path="/rekisteröityminen"
              component={RegisterPersonalInfo}
              exact
            />
            <Route
              path="/varmistuskoodi"
              component={RegisterVerificationCode}
              exact
            />
            <Route path="/luoSalasana" component={RegisterPassword} exact />
            <Route path="/ehdot" component={ConsentPage} exact />
            <PrivateRoute
              path="/ilmoitukset"
              component={RegisterEmailNotifications}
              exact
            />
            <PrivateRoute path="/ohje" component={OnboardingPage} exact />
            <PrivateRoute path="/etusivu" component={MainPage} exact />
            <PrivateRoute
              path="/etusivu/:id"
              component={FeedEventDetailsPage}
              exact
            />
            <PrivateRoute
              path="/admin/uusi"
              component={AdminDetailsPage}
              exact
            />
            <PrivateRoute
              path="/admin/muokkaa/:id"
              component={AdminDetailsPage}
              exact
            />
            <PrivateRoute
              path="/tyontekija/uusi"
              component={EmployeesDetailsPage}
              exact
            />
            <PrivateRoute
              path="/tyontekija/muokkaa/:id"
              component={EmployeesDetailsPage}
              exact
            />
            <PrivateRoute
              path="/omainen/muokkaa/:id"
              component={OmaUserDetailsPage}
              exact
            />
            <PrivateRoute
              path="/omainen/uusi"
              component={OmaUserDetailsPage}
              exact
            />
            <PrivateRoute
              path="/asiakas/muokkaa/:id"
              component={CustomerDetailsPage}
              exact
            />
            <PrivateRoute
              path="/asiakas/uusi"
              component={CustomerDetailsPage}
              exact
            />
            <PrivateRoute
              path="/laitteet/uusi"
              component={DevicesDetailsPage}
              exact
            />
            <PrivateRoute
              path="/laitteet/muokkaa/:id"
              component={DevicesDetailsPage}
              exact
            />
            <PrivateRoute
              path="/uutiset/uusi"
              component={CustomerNewsDetailsPage}
              exact
            />
            <PrivateRoute
              path="/uutiset/muokkaa/:id"
              component={CustomerNewsDetailsPage}
              exact
            />
            <PrivateRoute path="/profiili" component={ProfilePage} exact />
            <PrivateRoute path="/käyttöehdot" component={TermsPage} exact />
            <PrivateRoute
              path="/tietosuojakäytäntö"
              component={PrivacyPolicyPage}
              exact
            />
            <Redirect to={"/"} />
          </IonRouterOutlet>
        </IonPage>
      </>
    );
  };

  const getContent = () => {
    return (
      <IonReactRouter ref={routerRef}>
        <WindowResize />
      </IonReactRouter>
    );
  };

  return (
    <Suspense fallback={null}>
      <IonApp>
        <DndProvider backend={HTML5Backend}>
          <UserContextProvider>
            <UIContextProvider>
              <AdminContextProvider>
                <CustomerNewsContextProvider>
                  <DeviceContextProvider>
                    <EmployeeContextProvider>
                      <OmaUserContextProvider>
                        <CustomerContextProvider>
                          {getContent()}
                        </CustomerContextProvider>
                      </OmaUserContextProvider>
                    </EmployeeContextProvider>
                  </DeviceContextProvider>
                </CustomerNewsContextProvider>
              </AdminContextProvider>
            </UIContextProvider>
          </UserContextProvider>
        </DndProvider>
      </IonApp>
    </Suspense>
  );
};

export default App;

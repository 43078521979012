import React, { useState } from "react";
import { IonCol, IonImg, IonItem, IonRow, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { Device } from "../../../providers/Device/DeviceActionTypes";
import { withDeviceService, WithDeviceServiceProps } from "../../../service";
import { Formatter } from "../../../util/Formatter";

import "../MainPage.css";

interface DeviceCellProps extends WithDeviceServiceProps {
  device: Device;
  onClick: () => void;
}

const DeviceCell = (props: DeviceCellProps) => {
  const { t } = useTranslation();

  return (
    <div
      className="DeviceCell"
      onClick={(e) => {
        e.preventDefault();
        props.onClick();
      }}
    >
      <IonRow style={{ background: "white" }}>
        <IonCol
          size="6"
          className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <IonText className="body2 grey ion-padding-bottom">
            {`${props.device.name ? props.device.name : "-"}`}
          </IonText>
        </IonCol>
        <IonCol
          size="3"
          className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <IonText className="body2 grey ion-padding-bottom">
            {`${props.device.vendor ? props.device.vendor : ""}
             ${props.device.model ? props.device.model : "-"}`}
          </IonText>
        </IonCol>
        <IonCol
          size="3"
          className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <IonText className="body2 grey ion-padding-bottom">
            {Formatter.deviceStatusLocalization(props.device.status, t)}
          </IonText>
        </IonCol>
      </IonRow>
    </div>
  );
};

export default withDeviceService(DeviceCell);

import { MainTab } from "./index";
import { ActionType, UIActionTypes } from "./UIActionTypes";
import { HomeItem } from "../Admin/AdminActionTypes";

export interface UIAppState {
  isSplitPaneVisible: boolean;
  selectedMainTab: MainTab;
  activeHome: HomeItem | undefined;
  csTargetHome: HomeItem | undefined;
  csItemType: string   
}

export const initialState: UIAppState = {
  isSplitPaneVisible: window.matchMedia("(min-width: 768px)").matches,
  selectedMainTab: MainTab.EmployeesTab,
  activeHome: undefined,
  csTargetHome: undefined,
  csItemType: "general"   
};

export const reducer = (state: UIAppState, action: UIActionTypes) => {
  switch (action.type) {
    case ActionType.SplitPaneVisibilityChanged:
    case ActionType.MainPageTabChanged:
    case ActionType.SetActiveCSTargetChanged:
    case ActionType.SetActiveHome:
      return { ...state, ...action };
  }
};

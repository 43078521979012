import React from "react";
import { IonLoading, IonSpinner } from "@ionic/react";
import "./EsperiSpinner.css";
import { useTranslation } from "react-i18next";

export enum SpinnerSize {
  Small = "spinnerSmall",
  Default = "spinnerDefault",
  Large = "spinnerLarge",
}

interface EsperiSpinnerProps {
  size: SpinnerSize;
  hidden: boolean;
}

const EsperiSpinner = (props: EsperiSpinnerProps) => {
  const { t } = useTranslation();

  return (
    <div className="contentMaxWidth">
      <IonLoading
        cssClass="loadingCustomCss"
        isOpen={!props.hidden}
        message={t("loading")}
        {...props}
        showBackdrop={false}
      />
      <IonSpinner
        {...props}
        color="primary"
        name="crescent"
        className={props.size}
        hidden={true}
      />
    </div>
  );
};

export default EsperiSpinner;

import React from "react";
import { IonRow, IonText, IonGrid, IonImg, IonButton } from "@ionic/react";
import "./ErrorViewSmall.css";
import { useTranslation } from "react-i18next";

interface ErrorViewProps {
  title: string;
  onTryAgain: () => void;
}

const ErrorViewSmall = (props: ErrorViewProps) => {
  const { t } = useTranslation();

  function getTitle() {
    return props.title || t("errorGeneralTitle");
  }

  return (
    <IonGrid className="errorSmallView contentMaxWidth">
      <IonRow>
        <IonImg
          className="centered errorSmallImage"
          src="/assets/img_error.svg"
        />
      </IonRow>
      <IonRow>
        <IonText className="centered red body2 errorSmallTitle">
          {getTitle()}
        </IonText>
      </IonRow>
      <IonRow hidden={props.onTryAgain === null}>
        <IonButton
          onClick={props.onTryAgain}
          className="buttonRedOutline errorSmallButton"
        >
          {t("tryAgain")}
        </IonButton>
      </IonRow>
    </IonGrid>
  );
};

ErrorViewSmall.defaultProps = {
  title: "",
  onTryAgain: null,
};

export default ErrorViewSmall;

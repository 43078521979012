import {
  ActionType,
  Customer,
  CustomerActionTypes,
} from "./CustomerActionTypes";

export interface CustomerAppState {
  customers: Customer[];
}

export const initialState: CustomerAppState = {
  customers: [],
};

export const customerReducer = (
  state: CustomerAppState,
  action: CustomerActionTypes
) => {
  switch (action.type) {
    case ActionType.ClearCustomers: 
      return { ...state, customers: [] };
    case ActionType.GetCustomersResponse:
      return { ...state, customers: [...state.customers, ...action.customers] };
    case ActionType.UpdateCustomerResponse:
      const index = state.customers.findIndex(
        (a) => a.id === action.customer.id
      );
      const customers =
        index >= 0
          ? [
              ...state.customers.slice(0, index),
              action.customer,
              ...state.customers.slice(index + 1),
            ]
          : [...state.customers, action.customer];

      return { ...state, customers };
    default:
      return { ...state };
  }
};

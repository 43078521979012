import axios from "axios";
import React from "react";

import { HTTP_API_URL } from "util/Constants";
import { BaseService } from "./BaseService";
import { UserAppState } from "../providers/User/UserReducer";
import {
  OmaUser,
  OmaUserActionTypes,
  OmaUserResponse,
  OmaUsersResponse,
} from "../providers/OmaUser/OmaUserActionTypes";
import { OmaUserActions } from "../providers/OmaUser";
import { HomeItem } from "../providers/Admin/AdminActionTypes";

export interface PostDeactivateOmaUser {
  omaUser: OmaUser;
}

export interface PostReactivateOmaUser {
  omaUser: OmaUser;
}

export interface PostCreateOmaUser {
  omaUser: OmaUser;
}

export interface PostUpdateOmaUser {
  omaUser: OmaUser;
}

export interface PostInviteOmaUser {
  omaUser: OmaUser;
}

export default class OmaUserService extends BaseService {
  /**
   * Using static field for debouncing the mark as read API call.
   */
  private static timeout: any | null = null;

  private readonly dispatch: React.Dispatch<OmaUserActionTypes>;

  constructor(
    state: UserAppState,
    dispatch: React.Dispatch<OmaUserActionTypes>
  ) {
    super(state);
    this.dispatch = dispatch;
  }

  fetchOmaUsers(
    page: string = "0",
    home: HomeItem | undefined,
    activeOnly: boolean | undefined,
    query: string | undefined,
    pageSize: string | undefined
  ): Promise<OmaUsersResponse> {
    return new Promise(async (resolve, reject) => {
      if (page === "0") {
        this.dispatch(OmaUserActions.clearOmaUsers());
      }

      const url = new URL(HTTP_API_URL + "/users");
      url.searchParams.append("pagesize", pageSize ? pageSize : "25");
      url.searchParams.append("page", page);
      if (home) {
        url.searchParams.append("homeId", home.id.toString());
      }
      if (activeOnly) {
        url.searchParams.append("active", activeOnly ? "true" : "false");
      }
      if (query) {
        url.searchParams.append("nameSearch", query);
      }

      try {
        const response = await axios.get(
          url.toString(),
          this.addAuthorizationHeaders()
        );
        response.data.users.sort((a: OmaUser, b: OmaUser) => {
          // @ts-ignore
          return a.lastName - b.lastName;
        });

        this.dispatch(
          OmaUserActions.onGetOmaUsersResponse(response.data.users)
        );
        resolve(response.data as OmaUsersResponse);
      } catch (e) {
        reject(e);
      }
    });
  }

  fetchOmaUser(omaUserId: number): Promise<OmaUserResponse> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/users/" + omaUserId);
      try {
        const response = await axios.get(
          url.toString(),
          this.addAuthorizationHeaders()
        );

        this.dispatch(OmaUserActions.onGetOmaUserResponse(response.data));
        resolve({ omaUser: response.data });
      } catch (e) {
        reject(e);
      }
    });
  }

  postCreateOmaUser(request: PostCreateOmaUser): Promise<any> {
    return new Promise(async (resolve, reject) => {
      console.log('*** postCreateEmployee', request.omaUser);
      const url = new URL(HTTP_API_URL + "/users");
      try {
        const response = await axios.post(
          url.toString(),
          request.omaUser,
          this.addAuthorizationHeaders()
        );
        console.log('*** postCreateEmployee SUCCESS', response);
        this.dispatch(OmaUserActions.onUpdatedOmaUserResponse(response.data));
        resolve(response.data);
      } catch (e) {
        console.log('*** postCreateEmployee FAIL', e);
        reject(e);
      }
    });
  }

  postUpdateOmaUser(request: PostUpdateOmaUser): Promise<any> {
    return new Promise(async (resolve, reject) => {
      console.log('*** postUpdateOmaUser');
      try {
        const url = new URL(HTTP_API_URL + "/users/" + request.omaUser.id);
        const response = await axios.put(
          url.toString(),
          request.omaUser,
          this.addAuthorizationHeaders()
        );
        console.log('*** putCreateEmployee SUCCESS', response);
        this.dispatch(OmaUserActions.onUpdatedOmaUserResponse(response.data));
        resolve(response.data);
      } catch (e) {
        console.log('*** putCreateEmployee FAIL', e);
        reject(e);
      }
    });
  }

  postInviteOmaUser(request: PostInviteOmaUser): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(
        HTTP_API_URL + "/users/" + request.omaUser.id + "/invite"
      );
      try {
        console.log('*** postInviteOmaUser', request.omaUser.id);
        const response = await axios.post(
          url.toString(),
          this.addAuthorizationHeaders()
        );
        // this.dispatch(
        //   OmaUserActions
        // );
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  postDeactivateOmaUser(request: PostDeactivateOmaUser): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(
        HTTP_API_URL + "/users/" + request.omaUser.id + "/deactivate"
      );
      try {
        const response = await axios.post(
          url.toString(),
          request.omaUser,
          this.addAuthorizationHeaders()
        );

        this.dispatch(
          OmaUserActions.onDeactivateOmaUserResponse(response.data)
        );
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  postReactivateOmaUser(request: PostReactivateOmaUser): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(
        HTTP_API_URL + "/users/" + request.omaUser.id + "/reactivate"
      );
      try {
        const response = await axios.post(
          url.toString(),
          request.omaUser,
          this.addAuthorizationHeaders()
        );

        this.dispatch(
          OmaUserActions.onReactivateOmaUserResponse(response.data)
        );
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }
}

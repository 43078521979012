import { ActionType, Device, DeviceActionTypes } from "./DeviceActionTypes";

export interface DeviceAppState {
  devices: Device[]; // Array containing homes where user has family
}

export const initialState: DeviceAppState = {
  devices: [],
};

export const deviceReducer = (
  state: DeviceAppState,
  action: DeviceActionTypes
) => {
  switch (action.type) {
    case ActionType.ClearDevices: {
      return { ...state, devices: [] };
    }
    case ActionType.DeactivateDeviceResponse: {
      const devices = state.devices.filter((d) => d.id !== action.deviceId);
      return { ...state, devices };
    }
    case ActionType.CreateDeviceResponse:
    case ActionType.GetDeviceResponse:
    case ActionType.UpdateDeviceResponse: {
      const index = state.devices.findIndex((a) => a.id === action.device.id);

      const devices =
        index >= 0
          ? [
              ...state.devices.slice(0, index),
              action.device,
              ...state.devices.slice(index + 1),
            ]
          : [...state.devices, action.device];

      return { ...state, devices };
    }
    case ActionType.GetDevicesResponse:
      return { ...state, devices: [...state.devices, ...action.devices] };
    default:
      return { ...state };
  }
};

import { IonCol, IonRow, IonText } from "@ionic/react";
import { CustomerNews } from "providers/CustomerNews/CustomerNewsActionTypes";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { withCustomerNewsService, WithCustomerNewsServiceProps } from "service";
import { Formatter } from "util/Formatter";
import "../MainPage.css";
import "./CustomerNewsCell.css";

interface CustomerNewsCellProps extends WithCustomerNewsServiceProps {
  customerNews: CustomerNews;
  onClick: () => void;
}

const CustomerNewsCell = (props: CustomerNewsCellProps) => {
  const { t } = useTranslation();

  return (
    <div
      className="CustomerNewsCell"
      onClick={(e) => {
        e.preventDefault();
        props.onClick();
      }}
    >
      <IonRow style={{ background: "white" }}>
      <IonCol
          size="2"
          className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <IonText className="body2 grey ion-padding-bottom">
            {Formatter.getDate( props.customerNews.timestamp)}
          </IonText>
        </IonCol>
        <IonCol
          size="5"
          className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <IonText className="body2 grey ion-padding-bottom">
            {`${props.customerNews.title ? props.customerNews.title : "-"}`}
          </IonText>
        </IonCol>
        <IonCol
          size="3"
          className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <IonText className="body2 grey ion-padding-bottom">
            {`${props.customerNews.authorName ? props.customerNews.authorName : "-"}`}
          </IonText>
        </IonCol>
        <IonCol
          size="2"
          className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <IonText className="body2 grey ion-padding-bottom">
            {Formatter.customerNewsStatusLocalization( props.customerNews.published, t)}
          </IonText>
        </IonCol>
      </IonRow>
    </div>
  );
};

export default withCustomerNewsService(CustomerNewsCell);

import React from "react";
import {
  IonItem,
  IonIcon,
  IonLabel,
  IonInput,
  IonList,
  IonText,
} from "@ionic/react";
import "./EsperiTextField.css";
import { useTranslation } from "react-i18next";

interface EmailProps {
  hidden?: boolean;
  email: string;
  onEmailChanged: (password: string) => void;
  error: string;
  onPressEnter: (e: any) => void | null;
}

const EsperiEmailTextField = (props: EmailProps) => {
  const { t } = useTranslation();

  return (
    <IonList className={props.hidden ? "hiddenField" : ""}>
      <IonItem className="titleStartPadding">
        <IonLabel className="body2" color="grey">
          {t("email")}
        </IonLabel>
      </IonItem>
      <IonItem className="inputBackground" lines="none">
        <IonIcon icon={"/assets/ic_mail.svg"} />
        <IonItem style={{ width: 10 }} />
        <IonInput
          color="grey"
          type="text"
          value={props.email}
          onIonChange={(e) =>
            props.onEmailChanged((e.target as HTMLInputElement).value)
          }
          onKeyUp={(e) => {
            if (e.nativeEvent.key === "Enter" && props.onPressEnter !== null) {
              props.onPressEnter(e);
            }
          }}
        />
      </IonItem>
      <IonItem className="inputInfo" hidden={!props.error}>
        <IonIcon icon={"/assets/ic_alert.svg"} />
        <IonText className="smallText inputAlert" color="red">
          {props.error}
        </IonText>
      </IonItem>
    </IonList>
  );
};

EsperiEmailTextField.defaultProps = {
  onPressEnter: null,
};

export default EsperiEmailTextField;

import React, { useEffect, useState } from "react";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { Device } from "../providers/Device/DeviceActionTypes";
import { withDeviceService, WithDeviceServiceProps } from "../service";
import { QRCode } from "react-qr-svg";

import "./RegistrationCodeRotator.css";

interface RegistrationCodeRotatorProps extends WithDeviceServiceProps {
  device: Device;
}

const RegistrationCodeRotator = (props: RegistrationCodeRotatorProps) => {
  const { t } = useTranslation();

  const [newCodeRequired, setNewCodeRequired] = useState<boolean>(true);
  const [registrationCode, setRegistrationCode] = useState<string | undefined>(
    undefined
  );
  const [codeExpires, setCodeExpires] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!newCodeRequired) {
      return;
    }
    props.deviceService
      .fetchRegistrationCode(props.device)
      .then((response) => {
        setRegistrationCode(response.code);
        setCodeExpires(response.expiresAt);
        setNewCodeRequired(false);
      })
      .catch((e) => {});
  }, [newCodeRequired]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (codeExpires) {
        const diff = moment(codeExpires).diff(moment.now(), "seconds", false);
        if (diff < 0) {
          setNewCodeRequired(true);
          setCodeExpires(undefined);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [codeExpires]);

  return (
    <>
      <div className="codeContainer">
        {registrationCode && (
          <div className="code">
            <QRCode
              value={registrationCode}
              bgColor="#FFFFFF"
              fgColor="#000000"
              level="H"
              style={{ width: "70%" }}
            />
          </div>
        )}
        {registrationCode && (
          <div className="innstructions">
            {t("readRegitrationCodeInstructions")}
          </div>
        )}
      </div>
    </>
  );
};

export default withDeviceService(RegistrationCodeRotator);

import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonListHeader,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonToolbar,
} from "@ionic/react";
import InfiniteScroll from "react-infinite-scroll-component";
import { withAdminService, WithAdminServiceProps } from "service";
import EsperiSpinner, { SpinnerSize } from "components/EsperiSpinner";
import ErrorView from "components/ErrorView";
import EmptyView, { EmptyViewType } from "components/EmptyView";
import { RouteComponentProps, withRouter } from "react-router";
import { LocalStorageKeys } from "util/Constants";
import { UserContext } from "providers/User";
import { useTranslation } from "react-i18next";
import "./Admins.css";
import ErrorViewSmall from "components/ErrorViewSmall";
import LoadMoreView from "components/LoadMoreView";
import { AdminUser } from "../../../providers/Admin/AdminActionTypes";
import AdminCell from "./AdminCell";
import { Formatter } from "../../../util/Formatter";
import { AdminUserRole } from "../../../providers/User/UserActionTypes";
import { AdminContext } from "../../../providers/Admin";

interface AdminsProps extends WithAdminServiceProps, RouteComponentProps {
  hidden: boolean;
}

const Admins = (props: AdminsProps) => {
  const { state } = useContext(UserContext);
  const { state: adminState } = useContext(AdminContext);

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [role, setRole] = useState("ypa");
  const [nextPage, setNextPage] = useState<string>("0");
  const [hasMoreItems, setHasMoreItems] = useState<boolean>(false);
  const [isFetchMoreError, setFetchMoreError] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    fetchAdminUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  function fetchAdminUsers() {
    setLoading(true);
    setError(false);
    props.adminService
      .fetchAdmins(role)
      .then((response) => {
        setLoading(false);
        setNextPage(response.nextPage);
        setHasMoreItems(response.nextPage !== null);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
        setHasMoreItems(false);
      });
  }

  const fetchMore = () => {
    setFetchMoreError(false);
    setHasMoreItems(false);
    props.adminService
      .fetchAdmins(role, nextPage)
      .then((response) => {
        setNextPage(response.nextPage);
        setHasMoreItems(response.nextPage !== null);
      })
      .catch(() => {
        setFetchMoreError(true);
        setHasMoreItems(false);
      });
  };

  useEffect(() => {
    const hidden =
      isLoading ||
      isError ||
      localStorage.getItem(LocalStorageKeys.SeenOnboarding) !== null ||
      state.firstName.length === 0;
  }, [state.firstName, isLoading, isError]);

  function renderFetchMoreError() {
    if (isFetchMoreError) {
      return (
        <ErrorViewSmall
          title={t("adminUsersLoadingMoreError")}
          onTryAgain={() => {
            setFetchMoreError(false);
            setHasMoreItems(true);
          }}
        />
      );
    }
  }

  const isEmpty = !isLoading && adminState.adminUsers.length === 0;
  return (
    <>
      <IonContent
        className="contentLightGrey contentMaxWidth"
        hidden={props.hidden}
      >
        <div id="contentId" className="feed">
          <EsperiSpinner size={SpinnerSize.Large} hidden={!isLoading} />
          <ErrorView
            hidden={!isError}
            onTryAgain={() => {
              fetchAdminUsers();
            }}
          />
          <InfiniteScroll
            scrollableTarget="contentId"
            dataLength={adminState.adminUsers.length}
            next={fetchMore}
            hasMore={hasMoreItems}
            loader={""}
          >
            <IonGrid className="feedList ion-padding">
              <IonListHeader className="itemListHeader">
                <IonToolbar className="headerToolbar">
                  <IonButton
                    className="buttonAddNew"
                    slot="start"
                    onClick={() => {
                      props.history.push("/admin/uusi");
                    }}
                  >
                    {t("createUser")}
                  </IonButton>
                  <IonSelect
                    slot="end"
                    value={role}
                    onIonChange={(e: any) => setRole(e.detail.value)}
                    cancelText={t("cancel")}
                  >
                    {state.role === AdminUserRole.SUPERADMIN && (
                      <IonSelectOption value="superadmin">
                        {Formatter.roleLocalization("superadmin", t)}
                      </IonSelectOption>
                    )}
                    <IonSelectOption value="admin">
                      {Formatter.roleLocalization("admin", t)}
                    </IonSelectOption>
                    <IonSelectOption value="ypa">
                      {Formatter.roleLocalization("ypa", t)}
                    </IonSelectOption>
                  </IonSelect>
                </IonToolbar>
              </IonListHeader>
              <IonRow style={{ background: "white" }}>
                <IonCol
                  size="4"
                  className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <IonText className="body1Bold black ion-padding-bottom">
                    {t("name")}
                  </IonText>
                </IonCol>
                <IonCol
                  size="4"
                  className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <IonText className="body1Bold black ion-padding-bottom">
                    {t("role")}
                  </IonText>
                </IonCol>
                <IonCol
                  size="4"
                  className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <IonText className="body1Bold black ion-padding-bottom">
                    {t("status")}
                  </IonText>
                </IonCol>
              </IonRow>
              <EmptyView hidden={!isEmpty} type={EmptyViewType.Admin} />
              {adminState.adminUsers.map((item: AdminUser) => {
                return (
                  <AdminCell
                    key={item.id}
                    adminUser={item}
                    onClick={() => {
                      props.history.push("/admin/muokkaa/" + item.id);
                    }}
                  />
                );
              })}
              {renderFetchMoreError()}
            </IonGrid>
          </InfiniteScroll>
        </div>
      </IonContent>
    </>
  );
};

export default withRouter(withAdminService(Admins));

import axios from "axios";
import { HomeItem } from "providers/Admin/AdminActionTypes";
import { CustomerNewsActions } from "providers/CustomerNews";
import { CreateCustomerNewsResponse, CustomerNews, CustomerNewsActionTypes, CustomerNewsDetails, GetCustomerNewsDetailsResponse, GetCustomerNewsResponse, UpdateCustomerNewsResponse } from "providers/CustomerNews/CustomerNewsActionTypes";
import { UserAppState } from "providers/User/UserReducer";
import { HTTP_API_URL } from "util/Constants";
import { BaseService } from "./BaseService";

export interface CreateCustomerNews {
  customerNewsDetails: CustomerNewsDetails;
}

export interface UpdateCustomerNews {
  customerNewsDetails: CustomerNewsDetails;
}

export default class CustomerNewsService extends BaseService {
  /**
 * Using static field for debouncing the mark as read API call.
 */
  private static timeout: any | null = null;
  private readonly dispatch: React.Dispatch<CustomerNewsActionTypes>;

  constructor(
    state: UserAppState,
    dispatch: React.Dispatch<CustomerNewsActionTypes>
  ) {
    super(state);
    this.dispatch = dispatch;
  }

  fetchCustomerNews(
    page: string = "0",
    home: HomeItem | undefined,
    itemType: string | undefined,
  ): Promise<GetCustomerNewsResponse> {
    return new Promise(async (resolve, reject) => {
      if (page === "0") {
        this.dispatch(CustomerNewsActions.clearCustomerNews());
      }

      const url = new URL(HTTP_API_URL + "/news");
      url.searchParams.append("pagesize", "20");
      url.searchParams.append("page", page);
      if (home) {
        url.searchParams.append("homeId", home.id.toString());
      }
      if (itemType && !home) {
        url.searchParams.append("type", itemType);
      }

      try {
        const response = await axios.get(
          url.toString(),
          this.addAuthorizationHeaders()
        );
        response.data.news.sort((a: CustomerNews, b: CustomerNews) => {
          // @ts-ignore
          return a.timestamp - b.timestamp;
        });

        const dispatchResponse = CustomerNewsActions.onGetCustomerNewsResponse(
          response.data.news,
          response.data.nextPage,
          response.data.unreadCount)
        this.dispatch(
          dispatchResponse
        );
        resolve(dispatchResponse as GetCustomerNewsResponse);
      } catch (e) {
        reject(e);
      }
    });
  }

  fetchCustomerNewsDetails(customerNewsId: string): Promise<GetCustomerNewsDetailsResponse> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/news/" + customerNewsId);
      try {
        const response = await axios.get(
          url.toString(),
          this.addAuthorizationHeaders()
        );

        debugger

        const dispatchResponse = CustomerNewsActions.onGetCustomerNewsDetailsResponse(response.data);
        this.dispatch(dispatchResponse);
        resolve(dispatchResponse as GetCustomerNewsDetailsResponse);
      } catch (e) {
        reject(e);
      }
    });
  }

  postCreateCustomerNews(details: CustomerNewsDetails): Promise<CreateCustomerNewsResponse> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/news");
      try {
        const response = await axios.post(
          url.toString(),
          details,
          this.addAuthorizationHeaders()
        );

        console.log('*** postCreateCustomer SUCCESS', response);

        const dispatchResponse = CustomerNewsActions.onCreateCustomerNewsResponse(response.data)
        this.dispatch(dispatchResponse);
        resolve(dispatchResponse as CreateCustomerNewsResponse);
      } catch (e) {
        console.log('*** postCreateCustomer FAIL', e);
        reject(e);
      }
    });
  }

  putUpdateCustomerNews(details: CustomerNewsDetails): Promise<UpdateCustomerNewsResponse> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/news/" + details.id);
      try {
        const response = await axios.put(
          url.toString(),
          details,
          this.addAuthorizationHeaders()
        );

        console.log('*** postUpdateCustomer SUCCESS', response);

        this.dispatch(CustomerNewsActions.onUpdateCustomerNewsResponse(response.data));
        resolve(CustomerNewsActions.onUpdateCustomerNewsResponse(response.data) as UpdateCustomerNewsResponse);
      } catch (e) {
        console.log('*** postUpdateCustomer FAIL', e);
        reject(e);
      }
    });
  }

  postHeaderImage(photo: File): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/upload");
      try {
        // get upload url
        const response = await axios.put(
          url.toString(),
          null,
          this.addAuthorizationHeaders()
        );

        const { uuid, uploadUrl } = response.data;

        // upload image to S3
        await axios.put(uploadUrl,
          photo, {
          headers: {
            "Content-Type": "image/jpeg",
          }
        });
        resolve(uuid);
      } catch (e) {
        reject(e);
      }
    })
  }
}
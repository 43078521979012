import React, { createContext, useReducer, Dispatch } from "react";
import { logger } from "../index";
import {
  EmployeeAppState,
  employeeReducer,
  initialState,
} from "./EmployeeReducer";
import { EmployeeActionTypes } from "./EmployeeActionTypes";

interface EmployeeContextProps {
  state: EmployeeAppState;
  dispatch: Dispatch<EmployeeActionTypes>;
}

let EmployeeContext = createContext({} as EmployeeContextProps);
const loggerReducer = logger(employeeReducer, "Employee");

function EmployeeContextProvider(props: any) {
  let [state, dispatch] = useReducer(loggerReducer, initialState);
  let value = { state, dispatch };

  return (
    <EmployeeContext.Provider value={value}>
      {props.children}
    </EmployeeContext.Provider>
  );
}

export { EmployeeContext, EmployeeContextProvider };

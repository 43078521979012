import React, { useState } from "react";
import * as jwt from "jsonwebtoken";
import {
  IonPage,
  IonContent,
  IonItem,
  IonLabel,
  IonButton,
  IonLoading,
  IonList,
} from "@ionic/react";

import "pages/Register/Register.css";

import EsperiEmailTextField from "components/EsperiEmailTextField";
import EsperiHeaderWithoutNavigation from "components/EsperiHeaderWithoutNavigation";
import EsperiPasswordTextField from "components/EsperiPasswordTextField";

import { isPasswordValid } from "util/Validator";
import { withUserService, WithUserServiceProps } from "service";
import { RouteComponentProps } from "react-router";
import { MIN_PASSWORD_LENGTH } from "util/Constants";
import { useTranslation } from "react-i18next";
import { withErrorHandler, WithErrorHandlerProps } from "../index";

const RecoverPasswordPage = (
  props: WithUserServiceProps & RouteComponentProps & WithErrorHandlerProps
) => {
  const [password, setPassword] = useState("");
  const [matchPassword, setMatchPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [matchPasswordError, setMatchPasswordError] = useState("");
  const [isSending, setIsSending] = useState(false);
  const { t } = useTranslation();
  // @ts-ignore
  const recoveryToken = props.location.state?.recoveryToken || "";
  // @ts-ignore
  const email = jwt.decode(recoveryToken)?.email || "";

  function submitPassword(e: any) {
    e.preventDefault();
    if (!isPasswordValid(password)) {
      setPasswordError(
        t("passwordValidationError", { minLength: MIN_PASSWORD_LENGTH })
      );
    } else if (password !== matchPassword) {
      setMatchPasswordError(t("passwordMismatchError"));
    } else {
      setIsSending(true);
      props.userService
        .confirmNewPassword({ password, recoveryToken })
        .then(() => {
          setIsSending(false);
          props.history.replace("/kirjautuminen");
        })
        .catch((error) => {
          setIsSending(false);
          props.handleError(error);
        });
    }
  }

  return (
    <IonPage className="pageWhite pageWidth centered">
      <IonLoading isOpen={isSending} message={t("creatingPassword")} />
      <IonContent className="contentPaddingHorizontal">
        <EsperiHeaderWithoutNavigation />
        <IonItem className="title centered red" lines="none">
          <IonLabel>Luo salasana</IonLabel>
        </IonItem>
        <IonItem className="body1 centered grey" lines="none">
          <IonLabel className="ion-text-wrap">
            {t("recoverPasswordHint")}
          </IonLabel>
        </IonItem>
        <IonList lines="none">
          <EsperiEmailTextField
            hidden={true}
            email={email}
            onEmailChanged={() => {}}
            error={""}
          />
          <EsperiPasswordTextField
            title={t("newPassword")}
            password={password}
            onPasswordChanged={(password: string) => {
              setPassword(password);
              setPasswordError("");
            }}
            error={passwordError}
            hasIcon={false}
            showPasswordLength={true}
          />
          <EsperiPasswordTextField
            title={t("typePasswordAgain")}
            password={matchPassword}
            onPasswordChanged={(password: string) => {
              setMatchPassword(password);
              setMatchPasswordError("");
            }}
            error={matchPasswordError}
            hasIcon={false}
            showPasswordLength={false}
          />
        </IonList>
      </IonContent>
      <IonItem>
        <IonButton
          className="buttonRed buttonBottom"
          onClick={(e) => submitPassword(e)}
        >
          {t("continue")}
        </IonButton>
      </IonItem>
    </IonPage>
  );
};

export default withUserService(withErrorHandler(RecoverPasswordPage));

import React, { useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonListHeader,
  IonRow,
  IonSearchbar,
  IonText,
  IonToolbar,
} from "@ionic/react";
import InfiniteScroll from "react-infinite-scroll-component";
import { withEmployeeService, WithEmployeeServiceProps } from "service";
import EsperiSpinner, { SpinnerSize } from "components/EsperiSpinner";
import ErrorView from "components/ErrorView";
import EmptyView, { EmptyViewType } from "components/EmptyView";
import { RouteComponentProps, withRouter } from "react-router";
import { LocalStorageKeys } from "util/Constants";
import { UserContext } from "providers/User";
import { useTranslation } from "react-i18next";
import "./Employees.css";
import ErrorViewSmall from "components/ErrorViewSmall";
import LoadMoreView from "components/LoadMoreView";
import { Employee } from "../../../providers/Employee/EmployeeActionTypes";
import EmployeeCell from "./EmployeeCell";
import { HomePicker } from "../../../components/pickers";
import { HomeItem } from "../../../providers/Admin/AdminActionTypes";
import ActiveHomePicker from "../../../components/ActiveHomePicker";
import { UIContext } from "../../../providers/UI/UIAppState";
import { EmployeeContext } from "../../../providers/Employee";
import SearchMenu from "../../../components/SearchMenu";

interface EmployeesProps extends WithEmployeeServiceProps, RouteComponentProps {
  hidden: boolean;
}

const Employees = (props: EmployeesProps) => {
  const { state } = useContext(UserContext);
  const { state: uiState } = useContext(UIContext);
  const { state: employeeState } = useContext(EmployeeContext);

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [nextPage, setNextPage] = useState<string>("0");
  const [query, setQuery] = useState<string | undefined>(undefined);
  const [activeOnly, setActiveOnly] = useState<boolean>(true);
  const [hasMoreItems, setHasMoreItems] = useState<boolean>(false);
  const [isFetchMoreError, setFetchMoreError] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    fetchEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiState.activeHome, query, activeOnly]);

  function fetchEmployees() {
    setLoading(true);
    setError(false);
    props.employeeService
      .fetchEmployees("0", uiState.activeHome, activeOnly, query)
      .then((response) => {
        setLoading(false);
        setNextPage(response.nextPage);
        setHasMoreItems(response.nextPage !== null);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
        setHasMoreItems(false);
      });
  }

  const fetchMore = () => {
    setFetchMoreError(false);
    setHasMoreItems(false);
    props.employeeService
      .fetchEmployees(nextPage, uiState.activeHome, activeOnly, query)
      .then((response) => {
        setNextPage(response.nextPage);
        setHasMoreItems(response.nextPage !== null);
      })
      .catch(() => {
        setFetchMoreError(true);
        setHasMoreItems(false);
      });
  };

  useEffect(() => {
    const hidden =
      isLoading ||
      isError ||
      localStorage.getItem(LocalStorageKeys.SeenOnboarding) !== null ||
      state.firstName.length === 0;
  }, [state.firstName, isLoading, isError]);

  function renderFetchMoreError() {
    if (isFetchMoreError) {
      return (
        <ErrorViewSmall
          title={t("adminUsersLoadingMoreError")}
          onTryAgain={() => {
            setFetchMoreError(false);
            setHasMoreItems(true);
          }}
        />
      );
    }
  }

  const isEmpty = !isLoading && employeeState.employees.length === 0;
  return (
    <>
      <IonContent
        className="contentLightGrey contentMaxWidth"
        hidden={props.hidden}
      >
        <div id="contentId" className="feed">
          <EsperiSpinner size={SpinnerSize.Large} hidden={!isLoading} />
          <ErrorView
            hidden={!isError}
            onTryAgain={() => {
              fetchEmployees();
            }}
          />
          <InfiniteScroll
            scrollableTarget="contentId"
            dataLength={employeeState.employees.length}
            next={fetchMore}
            hasMore={hasMoreItems}
            loader={""}
          >
            <IonGrid className="feedList ion-padding">
              <IonListHeader className="itemListHeader">
                <IonToolbar className="headerToolbar">
                  <IonButton
                    className="buttonAddNew"
                    onClick={() => {
                      props.history.push("/tyontekija/uusi");
                    }}
                  >
                    {t("createEmployee")}
                  </IonButton>
                  <SearchMenu
                    onQueryChange={(q: string | undefined) => setQuery(q)}
                    onActiveOnlyChange={(activeOnly: boolean) =>
                      setActiveOnly(activeOnly)
                    }
                    isLoading={isLoading}
                  />
                </IonToolbar>
              </IonListHeader>
              <EmptyView hidden={!isEmpty} type={EmptyViewType.Employees} />
              <IonRow hidden={isEmpty} style={{ background: "white" }}>
                <IonCol
                  size="4"
                  className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <IonText className="body1Bold black ion-padding-bottom">
                    {t("name")}
                  </IonText>
                </IonCol>
                <IonCol
                  size="4"
                  className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <IonText className="body1Bold black ion-padding-bottom">
                    {t("role")}
                  </IonText>
                </IonCol>
                <IonCol
                  size="4"
                  className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <IonText className="body1Bold black ion-padding-bottom">
                    {t("status")}
                  </IonText>
                </IonCol>
              </IonRow>
              {employeeState.employees.map((item: Employee) => {
                return (
                  <EmployeeCell
                    key={item.id}
                    employee={item}
                    onClick={() => {
                      props.history.push("/tyontekija/muokkaa/" + item.id);
                    }}
                  />
                );
              })}
              {renderFetchMoreError()}
            </IonGrid>
          </InfiniteScroll>
        </div>
      </IonContent>
    </>
  );
};

export default withRouter(withEmployeeService(Employees));

import React from "react";
import { IonItem, IonLabel, IonInput, IonList } from "@ionic/react";

import "./EsperiTextField.css";
import { useTranslation } from "react-i18next";

interface VerificationCodeProps {
  verificationCode: string;
  onVerificationChanged: (verificationCode: string) => void;
  onRequestNewVerificationCode: () => void;
}

const EsperiVerificationCodeTextField: React.FC<VerificationCodeProps> = (
  props
) => {
  const { t } = useTranslation();

  return (
    <IonList>
      <IonItem className="titleStartPadding">
        <IonItem
          className="noPadding"
          detail={false}
          button
          onClick={() => props.onRequestNewVerificationCode()}
          slot="end"
        >
          <IonItem />
          <IonLabel className="textRedRightInfoText">
            {t("requestNewCode")}
          </IonLabel>
        </IonItem>
        <IonLabel className="textGrayLeftInfoText">
          {t("verificationCode")}
        </IonLabel>
      </IonItem>
      <IonItem className="inputBackground" lines="none">
        <IonItem style={{ width: 10 }} />
        <IonInput
          className="grey"
          type="text"
          value={props.verificationCode}
          onIonChange={(e) =>
            props.onVerificationChanged((e.target as HTMLInputElement).value)
          }
        />
      </IonItem>
    </IonList>
  );
};

export default EsperiVerificationCodeTextField;

import {
  ActionType,
  Device,
  DeviceActionTypes,
  RegistrationCode,
} from "./DeviceActionTypes";

export class DeviceActions {
  static clearDevices(): DeviceActionTypes {
    return {
      type: ActionType.ClearDevices,
    };
  }

  static onGetDevicesResponse(devices: Device[]): DeviceActionTypes {
    return {
      type: ActionType.GetDevicesResponse,
      devices: devices,
    };
  }

  static onGetDeviceResponse(device: Device): DeviceActionTypes {
    return {
      type: ActionType.GetDeviceResponse,
      device: device,
    };
  }

  static onUpdatedDeviceResponse(device: Device): DeviceActionTypes {
    return {
      type: ActionType.UpdateDeviceResponse,
      device: device,
    };
  }

  static onCreateDeviceResponse(device: Device): DeviceActionTypes {
    return {
      type: ActionType.CreateDeviceResponse,
      device: device,
    };
  }

  static onGetRegistrationCode(
    registrationCode: RegistrationCode
  ): DeviceActionTypes {
    return {
      type: ActionType.GetDeviceRegistrationCode,
      registrationCode: registrationCode,
    };
  }

  static onDeactivateDeviceResponse(deviceId: number): DeviceActionTypes {
    return {
      type: ActionType.DeactivateDeviceResponse,
      deviceId,
    };
  }
}

import {
  ActionType,
  CustomerNews,
  CustomerNewsActionTypes,
  CustomerNewsDetails,
} from "./CustomerNewsActionTypes";

export class CustomerNewsActions {
  static clearCustomerNews(): CustomerNewsActionTypes {
    return {
      type: ActionType.ClearCustomerNews
    }
  }

  static onGetCustomerNewsResponse(customersNews: CustomerNews[], nextPage: string, unreadCount: number): CustomerNewsActionTypes {
    return {
      type: ActionType.GetCustomerNewsResponse,
      customerNews: customersNews,
      nextPage,
      unreadCount 
    };
  }

  static onGetCustomerNewsDetailsResponse(customerNewsDetails: CustomerNewsDetails): CustomerNewsActionTypes {
    return {
      type: ActionType.GetCustomerNewsDetailsResponse,
      customerNewsDetails: customerNewsDetails
    };
  }

  static onUpdateCustomerNewsResponse(customerNewsDetails: CustomerNewsDetails): CustomerNewsActionTypes {
    return {
      type: ActionType.CreateCustomerNewsResponse,
      customerNewsDetails: customerNewsDetails
    };
  }

  static onCreateCustomerNewsResponse(customerNewsDetails: CustomerNewsDetails): CustomerNewsActionTypes {
    return {
      type: ActionType.CreateCustomerNewsResponse,
      customerNewsDetails: customerNewsDetails
    };
  }
}
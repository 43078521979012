import React from "react";
import { IonContent, IonImg, IonPage } from "@ionic/react";

import "./FeedEventDetailsPage.css";

import PageHeader from "components/PageHeader";
import { RouteComponentProps } from "react-router";

const FeedEventDetailsPage = (props: RouteComponentProps) => {
  function getImage(): string {
    // @ts-ignore
    return props.location.state?.event?.headerImage?.url || "";
  }

  function getContent(): string {
    // @ts-ignore
    return props.location.state?.event?.content || "";
  }

  const image = getImage();

  return (
    <IonPage className="pageLightGrey calendarEventDetails">
      <PageHeader title="" />
      <IonContent>
        <IonImg className="contentMaxWidth" hidden={image === ""} src={image} />
        <p
          className="ion-padding-horizontal ion-margin-vertical contentMaxWidth"
          dangerouslySetInnerHTML={{ __html: getContent() }}
        />
      </IonContent>
    </IonPage>
  );
};

export default FeedEventDetailsPage;

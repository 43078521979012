import axios from "axios";
import React from "react";
import { HTTP_API_URL } from "util/Constants";
import { BaseService } from "./BaseService";
import { Image } from "./index";

export enum FeedEventItemType {
  General = "general",
  Customer = "customer",
}

export enum FeedEventItemReaction {
  Like = "like",
}

export interface FeedEvent {
  id: string;
  url: string;
  title: string;
  lead: string;
  content: string;
  headerImage: Image | null;
  created: string;
  authorName: string;
  customerName: string;
  itemType: FeedEventItemType;
  reactions: FeedEventItemReaction[] | null;
}

export default class FeedService extends BaseService {
  /*
   API
   */
  fetchFeed(page: string = "0"): Promise<any> {
    const url = new URL(HTTP_API_URL + "/feed");
    url.searchParams.append("pagesize", "10");
    url.searchParams.append("page", page);
    return axios.get(url.toString(), this.addAuthorizationHeaders());
  }

  likePost(id: string, like: boolean): Promise<any> {
    const url = new URL(HTTP_API_URL + "/feed/reaction");
    const data = {
      id,
      reaction: "like",
      action: like ? "add" : "remove",
    };
    return axios.post(url.toString(), data, this.addAuthorizationHeaders());
  }
}

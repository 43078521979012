import React, { FormEvent, useState } from "react";

import {
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  IonPage,
  IonLoading,
} from "@ionic/react";

import EsperiEmailTextField from "components/EsperiEmailTextField";
import EsperiBigHeader from "components/EsperiBigHeader";
import { RouteComponentProps } from "react-router";
import { withUserService, WithUserServiceProps } from "service";
import { useTranslation } from "react-i18next";
import { withErrorHandler, WithErrorHandlerProps } from "../index";

const ForgotPasswordPage: React.FC<
  WithUserServiceProps & RouteComponentProps & WithErrorHandlerProps
> = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const { t } = useTranslation();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    props.userService
      .recoverPassword({ email })
      .then(() => {
        setLoading(false);
        props.history.goBack();
      })
      .catch((error) => {
        setLoading(false);
        props.handleError(error);
      });
  };

  return (
    <IonPage className="pageWhite pageWidth centered">
      <IonLoading isOpen={isLoading} message={t("sendingRequest")} />
      <IonContent className="contentPaddingHorizontal">
        <EsperiBigHeader />
        <IonItem className="title centered theme" lines="none">
          <IonLabel>{t("forgottenPassword")}</IonLabel>
        </IonItem>
        <IonItem className="body1 centered grey" lines="none">
          <IonLabel className="ion-text-wrap">
            {t("forgotPasswordInstructions")}
          </IonLabel>
        </IonItem>
        <form onSubmit={(e) => handleSubmit(e)} action="post">
          <IonList lines="none">
            <EsperiEmailTextField
              email={email}
              onEmailChanged={(email: string) => setEmail(email)}
              error={""}
            />
            <div className="marginTop30px" />
            <IonButton className="buttonRed" expand="block" type="submit">
              {t("send")}
            </IonButton>
            <div className="marginTop20px" />
            <IonButton
              className="buttonRedOutline"
              expand="block"
              onClick={() => {
                props.history.goBack();
              }}
            >
              {t("backToLogin")}
            </IonButton>
          </IonList>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default withUserService(withErrorHandler(ForgotPasswordPage));

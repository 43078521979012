import React, { useContext, useEffect, useState } from "react";
import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";

import "./Devices.css";

import PageHeader from "components/PageHeader";
import { RouteComponentProps, withRouter } from "react-router";

import {
  withErrorHandler,
  WithErrorHandlerProps,
  withInfoHandler,
  WithInfoHandlerProps,
} from "../../index";
import { UserContext } from "../../../providers/User";
import { useTranslation } from "react-i18next";
import { AdminContext } from "../../../providers/Admin";
import { withDeviceService, WithDeviceServiceProps } from "../../../service";
import { DeviceContext } from "../../../providers/Device";
import {
  Device,
  DeviceStatus,
} from "../../../providers/Device/DeviceActionTypes";
import RegistrationCodeRotator from "../../../components/RegistrationCodeRotator";
import { AdminUserStatus } from "../../../providers/Admin/AdminActionTypes";
import { UIContext } from "../../../providers/UI/UIAppState";
import { HomePicker } from "../../../components/pickers";
import { UIActions } from "../../../providers/UI/UIActions";
import {
  default as EsperiSpinner,
  SpinnerSize,
} from "../../../components/EsperiSpinner";
import {
  default as EmptyView,
  EmptyViewType,
} from "../../../components/EmptyView";
import { AdminUserRole } from "../../../providers/User/UserActionTypes";
import { isChanged, isValid } from "../../../util/Validator";

interface DeviceDetailsProps
  extends WithDeviceServiceProps,
  RouteComponentProps,
  WithErrorHandlerProps,
  WithInfoHandlerProps { }

const DevicesDetailsPage = (props: DeviceDetailsProps) => {
  const { t } = useTranslation();

  const { state: adminState } = useContext(AdminContext);
  const { state: userState } = useContext(UserContext);
  const { state: uiState } = useContext(UIContext);

  const [device, setDevice] = useState<Device | undefined>(undefined);
  const [originalDevice, setOriginalDevice] = useState<Device | undefined>(
    undefined
  );
  const [isNewDevice, setIsNewDevice] = useState<boolean>(false);
  const isNew = !(device && device.status === DeviceStatus.CREATED);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [isConfirmDeleteDeviceOpen, setIsConfirmDeleteDeviceOpen] = useState<
    boolean
  >(false);

  const keys = ["name", "home"];
  const isEdited = isChanged(device, originalDevice, keys);
  const validDevice = isValid(device, keys);

  const fetchDevice = (deviceId: number) => {
    setIsNewDevice(false);
    setIsLoading(true);

    props.deviceService
      .fetchDevice(deviceId)
      .then((response) => {
        setDevice(response.device);
        setOriginalDevice(response.device);
        if (isOpen && response.device.status !== DeviceStatus.CREATED) {
          setIsOpen(false);
        }
      })
      .catch((error) => {
        props.handleError(error);
      })
      .finally(() => {
        setIsLoadingPage(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // @ts-ignore
    if (props.match.params.id) {
      // @ts-ignore
      const deviceId: number = parseInt(props.match.params.id);
      setIsLoadingPage(true);
      fetchDevice(deviceId);
    } else {
      setIsNewDevice(true);
      // @ts-ignore
      const newDevice: Device = {
        home: uiState.activeHome ? { ...uiState.activeHome } : undefined,
      };
      setDevice(newDevice);
    }
    // @ts-ignore
  }, [props.match.url]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const interval = setInterval(() => {
      // @ts-ignore
      fetchDevice(device.id);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [isOpen]);

  const editDevice = (newState: {}) => {
    if (device) {
      const newValue = { ...device, ...newState };
      setDevice(newValue);
    }
  };

  const deactivateDevice = () => {
    setIsLoading(true);
    props.deviceService
      // @ts-ignore
      .postDeactivation(device)
      // @ts-ignore
      .then(() => {
        props.showInfo(t("deviceDeleted"));
        props.history.replace("/etusivu");
      })
      .catch((e: any) => {
        props.handleError(e);
      })
      .finally(() => setIsLoading(false));
  };

  const handleDeactivate = (e: any) => {
    e.preventDefault();
    setIsConfirmDeleteDeviceOpen(true);
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    props.deviceService
      // @ts-ignore
      .postUpdateDevice(device)
      .then(() => {
        // @ts-ignore
        fetchDevice(device.id);
        props.showInfo(t("profileSaved"));
      })
      .catch((error: any) => {
        props.handleError(error);
      })
      .finally(() => setIsLoading(false));
  };

  const handleCreate = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    props.deviceService
      // @ts-ignore
      .postCreateDevice(device)
      .then((response: Device) => {
        setDevice(response);
        setOriginalDevice(response);
        setIsNewDevice(false);
      })
      .catch((error: any) => {
        props.handleError(error);
      })
      .finally(() => setIsLoading(false));
  };

  const renderDeviceDetails = () => {
    if (!device) {
      return (
        <IonItemDivider>
          <IonLabel>{t("deviceDetails")}</IonLabel>
        </IonItemDivider>
      );
    }

    return (
      <>
        <IonItemDivider>
          <IonLabel>{t("deviceDetails")}</IonLabel>
        </IonItemDivider>
        <IonItem lines="none">
          <IonLabel>{t("deviceName")}</IonLabel>
          <IonInput
            value={device.name}
            placeholder={t("deviceNamePL")}
            onIonChange={(e: any) =>
              editDevice({ name: (e.target as HTMLInputElement).value })
            }
          />
        </IonItem>
        <IonItem lines="none" hidden={!device.vendor}>
          <IonLabel>{t("deviceVendor")}</IonLabel>
          <IonInput
            value={device.vendor}
            placeholder=""
            readonly={true}
          /*onIonChange={(e: any) =>
            editDevice({vendor: (e.target as HTMLInputElement).value})
          }*/
          />
        </IonItem>
        <IonItem lines="none" hidden={!device.vendor}>
          <IonLabel>{t("deviceModel")}</IonLabel>
          <IonInput
            value={device.model}
            placeholder=""
            readonly={true}
          /*onIonChange={(e: any) =>
            editDevice({model: (e.target as HTMLInputElement).value})
          }*/
          />
        </IonItem>
      </>
    );
  };

  const renderRegistration = () => {
    if (!device || device.status !== DeviceStatus.CREATED) {
      return (
        <>
          <IonItemDivider>
            <IonLabel>{t("registrationCode")}</IonLabel>
          </IonItemDivider>
          <IonItem lines="none">{t("createYourDeviceBeforePairing")}</IonItem>
        </>
      );
    }

    return (
      <>
        <IonItemDivider>
          <IonLabel>{t("registrationCode")}</IonLabel>
        </IonItemDivider>
        <IonModal
          isOpen={isOpen}
          cssClass="registrationModel"
          showBackdrop={true}
          onDidDismiss={() => setIsOpen(false)}
        >
          <IonContent>
            <RegistrationCodeRotator device={device} />
          </IonContent>
          <IonItem
            lines="none"
            style={{ margin: "auto", "padding-bottom": "15px" }}
          >
            <IonButton onClick={() => setIsOpen(!isOpen)}>
              {t("close")}
            </IonButton>
          </IonItem>
        </IonModal>
        <IonItem hidden={isOpen} lines="none">
          <IonLabel className="ion-text-wrap">
            {t("startRegistration")}
          </IonLabel>
          <IonButton slot="end" onClick={() => setIsOpen(!isOpen)}>
            {t("registrateDevice")}
          </IonButton>
        </IonItem>
      </>
    );
  };

  const renderDeviceHomes = () => {
    if (!device) {
      return <></>;
    }

    const adminRole =
      userState.role === AdminUserRole.ADMIN ||
      userState.role === AdminUserRole.SUPERADMIN;
    const homes = adminRole ? adminState.homes : userState.homes;

    const options = homes.map((h, i) => {
      return (
        <IonSelectOption
          key={i}
          value={h.id}
        >
          {h.name}
        </IonSelectOption>
      );
    });

    const value = device.home ? device.home.id : undefined;
    const onChange = (homeId: string) => {
      const selected = homes.find((i) => i.id.toString() === homeId);
      editDevice({ home: selected });
    };

    const customActionSheetOptions = {
      subHeader: t("pickHomeEmpty"),
      cssClass: "deviceHomePicker",
    };
    return (
      <>
        <IonItemDivider>
          {isNew && <IonLabel>{t("newDeviceSelectHome")}</IonLabel>}
          {!isNew && <IonLabel>{t("deviceSelectedHome")}</IonLabel>}
        </IonItemDivider>
        <IonItem lines="none">
          {isNew && (
            <IonSelect
              style={{ width: "95%", "maxWidth": "95%" }}
              interfaceOptions={customActionSheetOptions}
              value={value}
              placeholder={t("pickHomeEmpty")}
              onIonChange={(e: any) => onChange(e.detail.value)}
              cancelText={t("cancel")}
            >
              {options}
            </IonSelect>
          )}
          {!isNew && device.home && <>{device.home.name}</>}
        </IonItem>
      </>
    );
  };

  return (
    <IonPage className="pageLightGrey calendarEventDetails">
      <PageHeader title={device ? device.name : t("createDevice")} />
      <EsperiSpinner
        size={SpinnerSize.Large}
        hidden={!(isLoading && !isOpen)}
      />
      <IonAlert
        isOpen={isConfirmDeleteDeviceOpen}
        onDidDismiss={() => setIsConfirmDeleteDeviceOpen(false)}
        header={t("confirmDeleteDeviceHeader")}
        message={t("confirmDeleteDevice")}
        buttons={[
          {
            text: t("cancel"),
            role: "cancel",
            handler: () => { },
          },
          {
            text: t("OK"),
            handler: deactivateDevice,
          },
        ]}
      />
      <EmptyView hidden={!isDeleted} type={EmptyViewType.DeviceDeleted} />
      <IonContent hidden={isDeleted}>
        <IonGrid>
          <IonRow hidden={isLoadingPage}>
            <IonCol size="6">
              <div className="AdminFormContainer">{renderDeviceDetails()}</div>
              {!isNewDevice &&
                device &&
                device.status !== DeviceStatus.REGISTERED && (
                  <div className="AdminFormContainer">
                    {renderRegistration()}
                  </div>
                )}
            </IonCol>
            <IonCol size="6">
              <div className="AdminFormContainer">{renderDeviceHomes()}</div>
            </IonCol>
          </IonRow>
          <IonRow className="AdminFormContainer" hidden={isLoadingPage}>
            <IonCol>
              <IonItem lines="none" class="ion-text-end">
                {!isNewDevice &&
                  device &&
                  device.status !== DeviceStatus.DEACTIVATED && (
                    <IonButton slot="end" onClick={handleDeactivate}>
                      {t("deactivateDevice")}
                    </IonButton>
                  )}
                {isNewDevice && (
                  <IonButton
                    slot="end"
                    onClick={handleCreate}
                    disabled={!validDevice}
                  >
                    {t("create")}
                  </IonButton>
                )}
                {!isNewDevice && (
                  <IonButton
                    slot="end"
                    onClick={handleSave}
                    disabled={!isEdited}
                  >
                    {t("save")}
                  </IonButton>
                )}
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(
  withDeviceService(withErrorHandler(withInfoHandler(DevicesDetailsPage)))
);

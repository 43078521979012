import React, { useContext, useEffect, useState, useRef } from "react";
import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonModal,
  IonSearchbar,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import "./Picker.css";
import { OmaUser } from "providers/OmaUser/OmaUserActionTypes";
import { OmaUserContext } from "providers/OmaUser";

import { withOmaUserService, WithOmaUserServiceProps } from "service";
import { UIContext } from "providers/UI/UIAppState";
import { IonSpinner } from '@ionic/react';
import InfiniteScroll from "react-infinite-scroll-component";
import LoadMoreView from "components/LoadMoreView";
import ErrorView from "components/ErrorView";
import ErrorViewSmall from "components/ErrorViewSmall";
import EsperiSpinner, { SpinnerSize } from "components/EsperiSpinner";


interface OmaUserPickerProps extends WithOmaUserServiceProps {
  activeOmaUsers?: OmaUser[] | undefined;
  onChange?: (selected: OmaUser[]) => void;
  adminRole?: boolean | undefined;
}

const OmaUserPicker = ({ activeOmaUsers, onChange, adminRole, omaUserService }: OmaUserPickerProps) => {
  const { t } = useTranslation();
  const { state: uiState } = useContext(UIContext);

  const [isOpen, setIsOpen] = useState(false);
  const [openModeIds, setOpenModeIds] = useState<OmaUser[]>([]);
  const { state: omauserState } = useContext(OmaUserContext);
  const [query, setQuery] = useState<string | undefined>(undefined);


  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [activeOnly, setActiveOnly] = useState<boolean>(true);
  const [nextPage, setNextPage] = useState<string>("0");
  const [hasMoreItems, setHasMoreItems] = useState<boolean>(false);
  const [isFetchMoreError, setFetchMoreError] = useState(false);

  // Ref for resetting focus on searchbar, when omaUserPicker is open
  const inputRef = useRef<any>(null);

  // UseEffect which will make sure that focus is reset on
  // search bar when omaUserPicker modal is open
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => inputRef.current.setFocus(), 100);
    }
  })

  const handleOk = () => {
    if (onChange) {
      onChange(openModeIds);
    }
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    fetchOmaUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  function fetchOmaUsers() {
    setLoading(true);
    setError(false);
    omaUserService
      .fetchOmaUsers("0", uiState.activeHome, activeOnly, query, undefined)
      .then((response) => {
        setLoading(false);
        setNextPage(response.nextPage);
        setHasMoreItems(response.nextPage !== null);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        setHasMoreItems(false);
      });
  }

  const fetchMore = () => {
    console.log('*** fetchMore');
    setFetchMoreError(false);
    setHasMoreItems(false);
    omaUserService
      .fetchOmaUsers(nextPage, uiState.activeHome, activeOnly, query, undefined)
      .then((response) => {
        setNextPage(response.nextPage);
        setHasMoreItems(response.nextPage !== null);
      })
      .catch(() => {
        setFetchMoreError(true);
        setHasMoreItems(false);
      });
  };

  // const addNewOmaUser = () => {
  //   console.log('*** addNewOmaUser');
  // }

  const renderOpenMode = () => {
    const allOmaUsers = adminRole ? omauserState.omaUsers : [];

    const handleCheckboxClick = (id: number, value: boolean) => {
      if (!value) {
        setOpenModeIds(openModeIds.filter((i) => i.id.toString() !== id.toString()));
      } else {
        if (!openModeIds.find((i) => i.id === id)) {
          const user: OmaUser | undefined = allOmaUsers.find((i) => i.id.toString() === id.toString());
          if (user) {
            setOpenModeIds([...openModeIds, { ...user }]);
          }
        }
      }
    };

    const createCheckbox = (checked: boolean, value: string, name: string) => {
      return (
        <IonItem key={value}>
          <IonCheckbox
            className="registerCheckBox"
            checked={checked}
            value={value}
            onIonChange={(e: any) =>
              handleCheckboxClick(e.detail.value, e.detail.checked)
            }
          />
          <IonLabel style={{ "marginLeft": "10px" }}>{name}</IonLabel>
        </IonItem>
      )
    }

    const Checkboxes =
      <IonList lines="none">
        {
          allOmaUsers.map((user: OmaUser) => {
            const checked = openModeIds ? !!openModeIds.find((i) => user.id === i.id) : false;
            const value = user.id.toString();
            const name = `${user.lastName}, ${user.firstName}`;
            return createCheckbox(checked, value, name)
          })
        }
      </IonList>

    return (
      <IonModal
        isOpen={isOpen}
        cssClass="pickerModal"
        showBackdrop={false}
        onDidDismiss={() => { setIsOpen(false); }}
      >
        <EsperiSpinner size={SpinnerSize.Small} hidden={!isLoading} />
        <IonSearchbar
          value={query}
          debounce={1000}
          disabled={isLoading}
          onIonChange={(e: any) => setQuery(e.detail.value!)}
          ref={(ref) => inputRef.current = ref}
        />
        <IonContent id="scrollable">
          <IonSpinner
            color="primary"
            name="crescent"
            className="pickerSpinner"
            hidden={!isLoading}
          />
          <ErrorView
            hidden={!isError}
            onTryAgain={() => {
              fetchOmaUsers();
            }}
          />
          <InfiniteScroll
            scrollableTarget="scrollable"
            dataLength={omauserState.omaUsers.length}
            next={fetchMore}
            hasMore={hasMoreItems}
            loader={""}
          >
            {Checkboxes}
            {isFetchMoreError &&
              <ErrorViewSmall
                title={t("adminUsersLoadingMoreError")}
                onTryAgain={() => {
                  setFetchMoreError(false);
                  setHasMoreItems(true);
                }}
              />
            }
          </InfiniteScroll>
        </IonContent>
        <IonItem lines="none" class="ion-text-end">
          <IonButton onClick={handleOk}>{t("OK")}</IonButton>
          <IonButton onClick={handleCancel}>{t("cancel")}</IonButton>
        </IonItem>
      </IonModal>
    );
  };

  const renderListMode = () => {
    const handleOpen = () => {
      setIsOpen(true);
      setOpenModeIds(
        activeOmaUsers ? activeOmaUsers.map((i) => ({ ...i })) : []
      );
    };

    const renderActiveOmaUsers = (homes: OmaUser[]) => {
      return homes.map((user) => {
        return (
          <IonList key={user.id} onClick={handleOpen}>
            <IonItem lines="none">
              <IonLabel>{`${user.firstName} ${user.lastName}`}</IonLabel>
            </IonItem>
          </IonList>
        );
      });
    };

    return (
      <>
        <IonItemDivider>
          <IonLabel>
            {t("omaUsers")}
          </IonLabel>
          {/* <IonButton
          color="red"
          slot="end"
          size="small"
          fill="clear"
          onClick={addNewOmaUser}
        >
          <IonIcon icon={"/assets/ic_user.svg"} />
        </IonButton> */}
        </IonItemDivider>
        <IonList>
          {activeOmaUsers &&
            activeOmaUsers.length > 0 &&
            renderActiveOmaUsers(activeOmaUsers)}
          {!(activeOmaUsers && activeOmaUsers.length > 0) && (
            <IonItem lines="none">
              <IonLabel onClick={handleOpen}>{t("pickOmaUsersEmpty")}</IonLabel>
            </IonItem>
          )}
        </IonList>
      </>
    );
  };

  return (
    <>
      {renderListMode()}
      {isOpen && renderOpenMode()}
    </>
  );
};

export default withOmaUserService(OmaUserPicker);



import React, { useContext, useEffect, useState } from "react";

import {
  IonSelect,
  IonSelectOption,
} from "@ionic/react";

import { useTranslation } from "react-i18next";
import { UserContext } from "providers/User";
import { UIContext } from "providers/UI/UIAppState";
import { AdminContext } from "providers/Admin";
import { HomeItem } from "providers/Admin/AdminActionTypes";
import { AdminUserRole } from "providers/User/UserActionTypes";
import { UIActions } from "providers/UI/UIActions";

import "./CustomerNewsTypePicker.css";

interface CustomerNewsTypePickerProps {
  slot?: string;
  className?: string
}

const CustomerNewsTypePicker = (props: CustomerNewsTypePickerProps) => {
  const { t } = useTranslation();

  const { state } = useContext(UserContext);
  const { state: uiState, dispatch: uiDispatch } = useContext(UIContext);
  const { state: adminState } = useContext(AdminContext);
  const [homes, setHomes] = useState<HomeItem[]>([])
  const isAdmin =
    state.role === AdminUserRole.SUPERADMIN ||
    state.role === AdminUserRole.ADMIN;


  useEffect(() => {
    const homeList = isAdmin ? adminState.homes || [] : state.homes || [];
    setHomes(homeList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin])

  const renderSingleLine = () => {
    const options = isAdmin
      ? [
        <IonSelectOption key="showGeneralNews" value={"general"}>{t("showGeneralCustomerNews")}</IonSelectOption>,
        <IonSelectOption key="showNotices" value={"notice"}>{t("showNoticeCustomerNews")}</IonSelectOption>]
      : [];

    options.push(
      ...homes.map((h) => (
        <IonSelectOption key={h.id} value={h.id}>{h.name}</IonSelectOption>
      )));

    const onChange = (homeId: string) => {
      const selected = homes.find((i) => i.id.toString() === homeId);
      uiDispatch(UIActions.setActiveCustomerNewsTarget(selected, selected ? "home" : homeId));
    };

    // If not admin, set first home as default selected
    const value = uiState.csTargetHome?.id || (isAdmin && uiState.csItemType) || (options[0] as any)?.key;
    if (!isAdmin && uiState.csItemType !== 'home' && value) {
      // Save the selected value to state
      onChange(value);
    }

    const customActionSheetOptions = {
      subHeader: t("pickCustomerNewsTarget"),
      cssClass: "activeHomePickerInterface",
    };

    return (
      <IonSelect
        interfaceOptions={customActionSheetOptions}
        {...(props.slot ? { slot: props.slot } : {})}
        value={value}
        placeholder={t("pickCustomerNewsTarget")}
        onIonChange={(e: any) => onChange(e.detail.value)}
        className={`activeHomePicker ${props.className}`}
        cancelText={t("cancel")}
      >
        {options}
      </IonSelect>
    );
  };

  return <>{renderSingleLine()}</>;
};

export default CustomerNewsTypePicker;

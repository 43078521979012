import { HomeItem } from "providers/Admin/AdminActionTypes";
import { OmaUser } from "../OmaUser/OmaUserActionTypes";

export enum ActionType {
  ClearCustomers = "ClearCustomers",
  GetCustomersResponse = "GetCustomersResponse",
  GetCustomerResponse = "GetCustomerResponse",
  PostDeactivateCustomerResponse = "PostDeactivateCustomerResponse",
  PostActivateCustomerResponse = "PostActivateCustomerResponse",
  UpdateCustomerResponse = "UpdateCustomerResponse",
}

export interface ClearCustomers {
  type: ActionType.ClearCustomers;
}

export interface GetCustomersResponse {
  type: ActionType.GetCustomersResponse;
  customers: Customer[];
}

export interface GetCustomerResponse {
  type: ActionType.GetCustomerResponse;
  customer: Customer;
}

export interface PostDeactivateCustomerResponse {
  type: ActionType.PostDeactivateCustomerResponse;
  customer: Customer;
}

export interface PostActivateCustomerResponse {
  type: ActionType.PostActivateCustomerResponse;
  customer: Customer;
}

export interface UpdateCustomerResponse {
  type: ActionType.UpdateCustomerResponse;
  customer: Customer;
}

export interface Customer {
  id: number;
  firstName: string;
  lastName: string;
  active: boolean;
  home: HomeItem | undefined;
  users: OmaUser[] | undefined;
}

export type CustomerActionTypes =
  | ClearCustomers
  | GetCustomersResponse
  | GetCustomerResponse
  | PostDeactivateCustomerResponse
  | PostActivateCustomerResponse
  | UpdateCustomerResponse;

export interface CustomersResponse {
  customers: Customer[];
  nextPage: string;
  unreadCount: number;
}
export interface CustomerResponse {
  customer: Customer;
}

import React, { useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonListHeader,
  IonRow,
  IonText,
  IonToolbar,
} from "@ionic/react";
import { withCustomerNewsService, WithCustomerNewsServiceProps } from "service";
import { RouteComponentProps, withRouter } from "react-router";
import { UserContext } from "providers/User";
import { UIContext } from "providers/UI/UIAppState";
import { CustomerNewsContext } from "providers/CustomerNews";
import { useTranslation } from "react-i18next";
import { LocalStorageKeys } from "util/Constants";
import EsperiSpinner, { SpinnerSize } from "components/EsperiSpinner";
import ErrorView from "components/ErrorView";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadMoreView from "components/LoadMoreView";
import ActiveHomePicker from "components/ActiveHomePicker";
import EmptyView, { EmptyViewType } from "components/EmptyView";
import { CustomerNews, CustomerNewsDetails } from "providers/CustomerNews/CustomerNewsActionTypes";
import CustomerNewsCell from "./CustomerNewsCell";
import ErrorViewSmall from "components/ErrorViewSmall";
import CustomerNewsTypePicker from "./CustomerNewsTypePicker";
import CustomerNewsModal from "./CustomerNewsModal";
import CustomerNewsDetailsPage from "./CustomerNewsDetailsPage";
import { AdminUserRole } from 'providers/User/UserActionTypes';

interface CustomerNewsProps
  extends WithCustomerNewsServiceProps,
  RouteComponentProps {
  hidden: boolean
}

const Devices = (props: CustomerNewsProps) => {
  const { state } = useContext(UserContext);
  const { state: uiState } = useContext(UIContext);
  const { state: customerNewsState } = useContext(CustomerNewsContext);

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [nextPage, setNextPage] = useState<string>("0");
  const [hasMoreItems, setHasMoreItems] = useState<boolean>(false);
  const [isFetchMoreError, setFetchMoreError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [activeCustomerNews, setActiveCustomerNews] = useState<CustomerNews | undefined>(undefined);
  const isAdmin =
    state.role === AdminUserRole.SUPERADMIN ||
    state.role === AdminUserRole.ADMIN;

  const { t } = useTranslation();

  useEffect(() => {
    fetchCustomerNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiState.csTargetHome, uiState.csItemType]);

  function fetchCustomerNews() {
    setLoading(true);
    setError(false);
    debugger
    // If not admin, set first home as default selected
    const targetHome = uiState.csTargetHome || (!isAdmin && state.homes?.[0]) || undefined;
    props.customerNewsService
      .fetchCustomerNews("0", targetHome, uiState.csItemType)
      .then((response) => {
        setLoading(false);
        setNextPage(response.nextPage);
        setHasMoreItems(response.nextPage !== null);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
        setHasMoreItems(false);
      });
  }

  const fetchMore = () => {
    setFetchMoreError(false);
    setHasMoreItems(false);
    // If not admin, set first home as default selected
    const targetHome = uiState.csTargetHome || (!isAdmin && state.homes?.[0]) || undefined;
    props.customerNewsService
      .fetchCustomerNews(nextPage, targetHome, uiState.csItemType)
      .then((response) => {
        setNextPage(response.nextPage);
        setHasMoreItems(response.nextPage !== null);
      })
      .catch(() => {
        setFetchMoreError(true);
        setHasMoreItems(false);
      });
  };

  useEffect(() => {
    const hidden =
      isLoading ||
      isError ||
      localStorage.getItem(LocalStorageKeys.SeenOnboarding) !== null ||
      state.firstName.length === 0;
  }, [state.firstName, isLoading, isError]);

  function renderFetchMoreError() {
    if (isFetchMoreError) {
      return (
        <ErrorViewSmall
          title={t("adminUsersLoadingMoreError")}
          onTryAgain={() => {
            setFetchMoreError(false);
            setHasMoreItems(true);
          }}
        />
      );
    }
  };

  const isEmpty = !isLoading && customerNewsState.customerNews.length === 0;

  return (
    <>
      <IonContent
        className="contentLightGrey contentMaxWidth"
        hidden={props.hidden}
      >
        {/* {isModalOpen && */}
        <CustomerNewsDetailsPage
          isOpen={isModalOpen}
          customerNews={activeCustomerNews}
          didDismiss={() => setIsModalOpen(false)}
          onCancel={() => setIsModalOpen(false)}
          isModal={true}
          fetchCustomerNews={() => fetchCustomerNews()}
          clearActiveNews={() => setActiveCustomerNews(undefined)}
        />
        {/*  } */}

        <div id="contentId" className="feed">
          <EsperiSpinner
            size={SpinnerSize.Large}
            hidden={!isLoading}
          />
          <ErrorView
            hidden={!isError}
            onTryAgain={() => {
              fetchCustomerNews();
            }}
          />
          <InfiniteScroll
            scrollableTarget="contentId"
            dataLength={customerNewsState.customerNews.length}
            next={fetchMore}
            hasMore={hasMoreItems}
            loader={""}
          >
            <IonGrid className="feedList ion-padding">
              <IonListHeader className="itemListHeader">
                <IonToolbar className="headerToolbar">
                  <IonButton
                    className="buttonAddNew"
                    slot="start"
                    onClick={() => {
                      setActiveCustomerNews(undefined);
                      setIsModalOpen(true);
                      //props.history.push("/uutiset/uusi");
                    }}
                  >
                    {t("createCustomerNews")}
                  </IonButton>
                  <CustomerNewsTypePicker slot="end" />
                </IonToolbar>
              </IonListHeader>
              <IonRow
                hidden={isEmpty}
                style={{ background: "white" }}
              >
                <IonCol
                  size="2"
                  className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <IonText className="body1Bold black ion-padding-bottom">
                    {t("customerNewsTimestamp")}
                  </IonText>
                </IonCol>
                <IonCol
                  size="5"
                  className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <IonText className="body1Bold black ion-padding-bottom">
                    {t("customerNewsTitle")}
                  </IonText>
                </IonCol>
                <IonCol
                  size="3"
                  className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <IonText className="body1Bold black ion-padding-bottom">
                    {t("customerNewsAuthor")}
                  </IonText>
                </IonCol>
                <IonCol
                  size="2"
                  className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <IonText className="body1Bold black ion-padding-bottom">
                    {t("customerNewsPublishedStatus")}
                  </IonText>
                </IonCol>
              </IonRow>
              <EmptyView
                hidden={!isEmpty}
                type={EmptyViewType.CustomerNews}
              />
              {customerNewsState.customerNews.map((item: CustomerNews, i) => {
                return (
                  <CustomerNewsCell
                    key={i}
                    customerNews={item}
                    onClick={() => {
                      setActiveCustomerNews(item);
                      setIsModalOpen(true);
                      //props.history.push("/uutiset/muokkaa/" + item.id);
                    }}
                  />
                );
              })}
              {renderFetchMoreError()}
            </IonGrid>
          </InfiniteScroll>
        </div>
      </IonContent>
    </>
  );
};

export default withRouter(withCustomerNewsService(Devices));

import { HomeItem } from "../Admin/AdminActionTypes";

export enum ActionType {
  ClearEmployees = "ClearEmployees",
  GetEmployeesResponse = "GetEmployeesResponse",
  GetEmployeeResponse = "GetEmployeeResponse",
  PostActivateEmployeeResponse = "PostActivateEmployeeResponse",
  PostDeactivateEmployeeResponse = "PostDeactivateEmployeeResponse",
  UpdateEmployeeResponse = "UpdateEmployeeResponse",
  EmployeePasswordResetResponse = "EmployeePasswordResetResponse",
}

export interface ClearEmployees {
  type: ActionType.ClearEmployees;
}

export interface GetEmployeesResponse {
  type: ActionType.GetEmployeesResponse;
  employees: Employee[];
}

export interface GetEmployeeResponse {
  type: ActionType.GetEmployeeResponse;
  employee: Employee;
}

export interface UpdateEmployeeResponse {
  type: ActionType.UpdateEmployeeResponse;
  employee: Employee;
}

export interface EmployeePasswordResetResponse {
  type: ActionType.EmployeePasswordResetResponse;
  password: string;
}

export interface PostActivateEmployee {
  type: ActionType.PostActivateEmployeeResponse;
  employee: Employee;
}

export interface PostDeactivateEmployee {
  type: ActionType.PostDeactivateEmployeeResponse;
  employee: Employee;
}

export type EmployeeActionTypes =
  | ClearEmployees
  | GetEmployeesResponse
  | GetEmployeeResponse
  | UpdateEmployeeResponse
  | EmployeePasswordResetResponse
  | PostActivateEmployee
  | PostDeactivateEmployee;

/*
 MODEL
 */

export enum EmployeeStatus {
  CREATED = "CREATED",
  REGISTERED = "REGISTERED",
  DEACTIVATED = "DEACTIVATED",
}

export interface Employee {
  id: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  role: string;
  homes: HomeItem[] | undefined;
  username: string;
  created: string;
  status: string;
}

export interface EmployeesResponse {
  employees: Employee[];
  nextPage: string;
  unreadCount: number;
}

export interface EmployeeResponse {
  employee: Employee;
}

import React from "react";
import { UserAppState } from "../providers/User/UserReducer";

export abstract class BaseService {
  protected readonly userAppState: UserAppState;

  constructor(userAppState: UserAppState) {
    this.userAppState = userAppState;
  }

  protected addAuthorizationHeaders(idToken: string = "") {
    return {
      headers: {
        Authorization: idToken || this.userAppState.IdToken,
      },
    };
  }
}

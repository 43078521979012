import React, { useContext, useState } from "react";

import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";

import { useTranslation } from "react-i18next";
import { AdminContext } from "../providers/Admin";

import "./ActiveHomePicker.css";
import { UserContext } from "../providers/User";
import { UIContext } from "../providers/UI/UIAppState";
import { UIActions } from "../providers/UI/UIActions";
import { AdminUserRole } from "../providers/User/UserActionTypes";

interface ActiveHomePickerProps {
  slot?: string;
}

const ActiveHomePicker = (props: ActiveHomePickerProps) => {
  const { t } = useTranslation();

  const { state } = useContext(UserContext);
  const { state: uiState, dispatch: uiDispatch } = useContext(UIContext);
  const { state: adminState } = useContext(AdminContext);
  const isAdmin =
    state.role === AdminUserRole.SUPERADMIN ||
    state.role === AdminUserRole.ADMIN;
  const homes = isAdmin ? adminState.homes || [] : state.homes || [];

  const renderSingleLine = () => {
    const options = [
      <IonSelectOption key="showAllHomes" value={undefined}>{t("showAllHomes")}</IonSelectOption>,
      ...homes.map((h) => (
        <IonSelectOption key={h.id} value={h.id}>{h.name}</IonSelectOption>
      )),
    ];

    const value = uiState.activeHome ? uiState.activeHome.id : undefined;
    const onChange = (homeId: string) => {
      const selected = homes.find((i) => i.id.toString() === homeId);
      uiDispatch(UIActions.setActiveHome(selected));
    };

    const customActionSheetOptions = {
      subHeader: t("pickHomeEmpty"),
      cssClass: "activeHomePickerInterface",
    };

    return (
      <IonSelect
        interfaceOptions={customActionSheetOptions}
        {...(props.slot ? { slot: props.slot } : {})}
        value={value}
        placeholder={t("pickHomeEmpty")}
        onIonChange={(e: any) => onChange(e.detail.value)}
        className="activeHomePicker"
        cancelText={t("cancel")}
      >
        {options}
      </IonSelect>
    );
  };

  return <>{renderSingleLine()}</>;
};

export default ActiveHomePicker;

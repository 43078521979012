import React, { createContext, useReducer, Dispatch } from "react";
import { initialState, UserAppState, userReducer } from "./UserReducer";
import { UserActionTypes } from "./UserActionTypes";
import { logger } from "../index";

interface UserContextProps {
  state: UserAppState;
  dispatch: Dispatch<UserActionTypes>;
}

let UserContext = createContext({} as UserContextProps);
const loggerReducer = logger(userReducer, "User");

function UserContextProvider(props: any) {
  let [state, dispatch] = useReducer(loggerReducer, initialState);
  let value = { state, dispatch };

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
}

export { UserContext, UserContextProvider };

import React, { useContext } from "react";
import {
  IonImg,
  IonRow,
  IonCol,
  IonIcon,
  IonButton,
  IonToolbar,
  IonHeader,
} from "@ionic/react";
import "./EsperiHeader.css";
import { RouteComponentProps, withRouter } from "react-router";
import { UserContext } from 'providers/User';
import { getLogo } from 'util/Branding';

export enum HeaderLeftIcon {
  Menu,
  Close,
}

interface EsperiAppHeaderProps extends RouteComponentProps {
  leftIconType: HeaderLeftIcon;
  hideLeftIcon: boolean;
  hideEsperiLogo: boolean;
  hideRightIcon: boolean;
}

const EsperiAppHeader = (props: EsperiAppHeaderProps) => {
  const { state } = useContext(UserContext);

  function getLeftIconSrc() {
    switch (props.leftIconType) {
      case HeaderLeftIcon.Menu:
        return "/assets/ic_menu.svg";
      case HeaderLeftIcon.Close:
        return "/assets/ic_menu_close.svg";
    }
  }

  function getUserIconSrc() {
    return props.history.location.pathname === "/profiili"
      ? "assets/ic_user_active.svg"
      : "assets/ic_user.svg";
  }

  return (
    <IonHeader>
      <IonToolbar className="contentMaxWidth">
        <IonRow slot="start" hidden={props.hideLeftIcon}>
          <IonCol>
            <IonButton
              fill="clear"
              onClick={() => {
                // @ts-ignore
                document.getElementById("mainMenu")?.toggle();
              }}
            >
              <IonIcon className="appTitleIconNormal" src={getLeftIconSrc()} />
            </IonButton>
          </IonCol>
        </IonRow>
        <IonImg
          hidden={props.hideEsperiLogo}
          className="appTitleHeader"
          src={getLogo(state.business)}
        />
        <IonRow slot="end" hidden={props.hideRightIcon}>
          <IonCol>
            <IonButton
              fill="clear"
              onClick={() => {
                if (props.history.location.pathname !== "/profiili") {
                  props.history.replace("/profiili");
                }
                // @ts-ignore
                document.getElementById("mainMenu")?.close();
              }}
            >
              <IonIcon className="appTitleIconNormal" src={getUserIconSrc()} />
            </IonButton>
          </IonCol>
        </IonRow>
      </IonToolbar>
    </IonHeader>
  );
};

export default withRouter(EsperiAppHeader);

import React, { useEffect, useState } from "react";
import { IonContent, IonItem, IonPage } from "@ionic/react";
import { RouteComponentProps, useLocation } from "react-router";
import { withUserService, WithUserServiceProps } from "service";
import { useTranslation } from "react-i18next";
import PageHeader from "components/PageHeader";
import EsperiSpinner, { SpinnerSize } from "components/EsperiSpinner";
import ErrorView from "components/ErrorView";
import { ConsentItem } from "providers/User/UserActionTypes";
import { Formatter } from "util/Formatter";

const PrivacyPolicyPage = (
  props: RouteComponentProps & WithUserServiceProps
) => {
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [consent, setConsent] = useState<ConsentItem | null>(null);
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/tietosuojakäytäntö") {
      fetchPrivacyPolicy();
    }
  }, [location]);

  const fetchPrivacyPolicy = () => {
    setLoading(true);
    setError(false);
    props.userService
      .fetchPrivacyPolicy()
      .then((consent: ConsentItem) => {
        setLoading(false);
        setConsent(consent);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  function getDescription() {
    if (consent) {
      let description = consent.description;
      description = Formatter.boldParts(
        description,
        consent.descriptionHighlights
      );
      return description;
    }
    return "";
  }

  const description = getDescription();

  return (
    <IonPage className="pageLightGrey">
      <PageHeader title={t("privacyPolicy")} />
      <IonContent className="contentMaxWidth contentPaddingHorizontal">
        <EsperiSpinner size={SpinnerSize.Large} hidden={!isLoading} />
        <ErrorView hidden={!isError} onTryAgain={fetchPrivacyPolicy} />
        <IonItem className="body1 grey ion-padding-vertical" lines="none">
          <p
            className={description.length < 100 ? "centered" : ""}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </IonItem>
      </IonContent>
    </IonPage>
  );
};

export default withUserService(PrivacyPolicyPage);

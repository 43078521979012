import { PostActivateAdmin } from "../../service/AdminRequests";

export enum ActionType {
  ClearAdminUsers = "ClearAdminUsers",
  GetAdminUsersResponse = "GetAdminUsersResponse",
  GetAdminUserResponse = "GetAdminUserResponse",
  UpdateAdminUserResponse = "UpdateAdminUserResponse",
  InviteAdminResponse = "InviteAdminResponse",
  DeactivateAdminResponse = "DeactivateAdminResponse",
  ActivateAdminResponse = "ActivateAdminResponse",
  GetHomesResponse = "GetHomesResponse",
}

export interface ClearAdminUsers {
  type: ActionType.ClearAdminUsers;
}

export interface GetAdminUsersResponse {
  type: ActionType.GetAdminUsersResponse;
  adminUsers: AdminUser[];
}

export interface GetAdminUserResponse {
  type: ActionType.GetAdminUserResponse;
  adminUser: AdminUser;
}

export interface UpdateAdminUserResponse {
  type: ActionType.UpdateAdminUserResponse;
  adminUser: AdminUser;
}

export interface InviteAdminResponse {
  type: ActionType.InviteAdminResponse;
}

export interface DeactivateAdminResponse {
  type: ActionType.DeactivateAdminResponse;
  adminUser: AdminUser;
}

export interface ActivateAdminResponse {
  type: ActionType.ActivateAdminResponse;
  adminUser: AdminUser;
}

export interface GetHomesResponse {
  type: ActionType.GetHomesResponse;
  homes: HomeItem[];
}

export type AdminActionTypes =
  | GetAdminUsersResponse
  | GetAdminUserResponse
  | ClearAdminUsers
  | GetHomesResponse
  | UpdateAdminUserResponse
  | DeactivateAdminResponse
  | ActivateAdminResponse
  | InviteAdminResponse;

/*
 MODEL
 */

export enum AdminUserStatus {
  CREATED = "CREATED",
  INVITED = "INVITED",
  REGISTERED = "REGISTERED",
  DEACTIVATED = "DEACTIVATED",
}

export interface AdminUser {
  id: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  role: string;
  homes: HomeItem[] | undefined;
  email: string;
  created: string;
  status: string;
}

export interface HomeItem {
  id: number;
  name: string;
}

export interface AdminUsersResponse {
  admins: AdminUser[];
  nextPage: string;
  unreadCount: number;
}

export interface AdminUserResponse {
  adminUser: AdminUser;
}

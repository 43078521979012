import React, { useContext, useState } from "react";

import {
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  IonPage,
  IonLoading,
} from "@ionic/react";

import "./LoginPage.css";
import { isPasswordValid } from "util/Validator";

import EsperiBigHeader from "components/EsperiBigHeader";
import EsperiEmailTextField from "components/EsperiEmailTextField";
import EsperiPasswordTextField from "components/EsperiPasswordTextField";
import { RouteComponentProps } from "react-router";
import { withUserService, WithUserServiceProps } from "service";
import { Formatter } from "util/Formatter";
import { UserContext } from "providers/User";
import { useTranslation } from "react-i18next";
import { withErrorHandler, WithErrorHandlerProps } from "../index";
import { getBrandStrings } from '../../util/Branding';

const LoginPage: React.FC<
  WithUserServiceProps & WithErrorHandlerProps & RouteComponentProps
> = (props) => {
  const { state } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    props.userService
      .signIn({ email, password })
      .then((response) => {
        setLoading(false);
        props.history.replace("/etusivu");
      })
      .catch((error) => {
        setLoading(false);
        props.handleError(error);
      });
  };

  const getRecoveryText = () => {
    let text = t("recoveryEmailSent", { email: state.recoveryEmail });
    return Formatter.boldParts(text, [state.recoveryEmail]);
  };

  return (
    <IonPage className="pageWhite pageWidth centered">
      <IonLoading isOpen={isLoading} message={t("loggingIn")} />
      <IonContent className="contentPaddingHorizontal">
        <EsperiBigHeader />
        <IonItem className="body2 centered theme" lines="none">
          <IonLabel color="theme">{t("loginTitle", getBrandStrings())}</IonLabel>
        </IonItem>
        <IonItem
          className="passwordRecovery body2 grey"
          hidden={state.recoveryEmail === ""}
          lines="none"
        >
          <p
            className="centered"
            dangerouslySetInnerHTML={{ __html: getRecoveryText() }}
          />
        </IonItem>
        <form onSubmit={(e) => handleSubmit(e)} action="post">
          <IonList lines="none">
            <EsperiEmailTextField
              email={email}
              onEmailChanged={(email: string) => setEmail(email)}
              error={""}
            />
            <EsperiPasswordTextField
              title={t("password")}
              password={password}
              onPasswordChanged={(password: string) => setPassword(password)}
              error={isPasswordValid(password) ? "" : ""}
              hasIcon={true}
              showPasswordLength={false}
              onPressEnter={(e) => {
                handleSubmit(e);
              }}
            />
            <div className="marginTop30px" />
            <IonButton className="buttonRed" expand="block" type="submit">
              {t("login")}
            </IonButton>
            <div className="marginTop20px" />
            <IonItem className="body2 centered theme clickable">
              <IonLabel
                onClick={() => {
                  props.history.push("/unohtuikoSalasana");
                }}
              >
                {t("forgotPassword")}
              </IonLabel>
            </IonItem>
          </IonList>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default withUserService(withErrorHandler(LoginPage));

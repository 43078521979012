import React, { useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
} from "@ionic/react";

import "./AdminDetailsPage.css";

import PageHeader from "components/PageHeader";
import { RouteComponentProps, withRouter } from "react-router";
import { withAdminService, WithAdminServiceProps } from "../../../service";
import {
  AdminUser,
  AdminUserStatus,
} from "../../../providers/Admin/AdminActionTypes";
import {
  withErrorHandler,
  WithErrorHandlerProps,
  withInfoHandler,
  WithInfoHandlerProps,
} from "../../index";
import { UserContext } from "../../../providers/User";
import { useTranslation } from "react-i18next";
import { HomePicker } from "../../../components/pickers";
import { AdminUserRole } from "../../../providers/User/UserActionTypes";
import {
  default as EsperiSpinner,
  SpinnerSize,
} from "../../../components/EsperiSpinner";
import { isChanged, isValid } from "../../../util/Validator";

interface AdminDetailsProps
  extends WithAdminServiceProps,
  RouteComponentProps,
  WithErrorHandlerProps,
  WithInfoHandlerProps { }

const AdminDetailsPage = (props: AdminDetailsProps) => {
  const { state } = useContext(UserContext);

  const [adminUser, setAdminUser] = useState<AdminUser | undefined>(undefined);
  const [originalAdminUser, setOriginalAdminUser] = useState<
    AdminUser | undefined
  >(undefined);
  const [isNewUser, setIsNewUser] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingPage, setLoadingPage] = useState<boolean>(false);
  const { t } = useTranslation();

  const keys = ["firstName", "lastName", "phoneNumber", "role", "email"];
  const ypaKeys = [
    "firstName",
    "lastName",
    "phoneNumber",
    "role",
    "email",
    "homes",
  ];
  const isEdited = isChanged(adminUser, originalAdminUser, adminUser?.role === AdminUserRole.YPA ? ypaKeys : keys);
  const validAdminUser = isValid(
    adminUser,
    adminUser?.role === AdminUserRole.YPA ? ypaKeys : keys
  );

  const loadAdminuser = (adminId: number) => {
    setIsLoading(true);
    props.adminService
      .fetchAdmin(adminId)
      .then((response: any) => {
        setAdminUser(response.adminUser);
        setOriginalAdminUser(response.adminUser);
      })
      .catch((error) => {
        props.handleError(error);
      })
      .finally(() => {
        setLoadingPage(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // @ts-ignore
    if (props.match.params.id) {
      setLoadingPage(true);

      // @ts-ignore
      const adminId: number = parseInt(props.match.params.id);
      loadAdminuser(adminId);
    } else {
      setIsNewUser(true);
      setAdminUser({ role: "ypa" } as AdminUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.url]);

  const editAdminUser = (newState: {}) => {
    if (adminUser) {
      const newValue = { ...adminUser, ...newState };
      setAdminUser(newValue);
    }
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    props.adminService
      // @ts-ignore
      .postUpdateAdminUser({ adminUser })
      .then((response: any) => {
        setAdminUser(response);
        setOriginalAdminUser(response);
        props.showInfo(t("profileSaved"));
      })
      .catch((error: any) => {
        props.handleError(error);
      })
      .finally(() => setIsLoading(false));
  };

  const handleCreate = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    props.adminService
      // @ts-ignore
      .postCreateAdminUser({ adminUser })
      .then((response: any) => {
        setAdminUser(response);
        setOriginalAdminUser(response);
        setIsNewUser(false);
      })
      .catch((error: any) => {
        props.handleError(error);
      })
      .finally(() => setIsLoading(false));
  };

  const handleInvite = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    props.adminService
      // @ts-ignore
      .postAdminInvite({ adminUser })
      .then((response: any) => {
        // @ts-ignore
        loadAdminuser(adminUser.id);
        props.showInfo(t("adminInvited"));
      })
      .catch((error: any) => {
        props.handleError(error);
      })
      .finally(() => setIsLoading(false));
  };

  const handleDeactivate = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    props.adminService
      // @ts-ignore
      .postDeactivateAdmin({ adminUser })
      .then(() => {
        // @ts-ignore
        loadAdminuser(adminUser.id);
        props.showInfo(t("adminDeactivated"));
      })
      .catch((error: any) => {
        props.handleError(error);
      })
      .finally(() => setIsLoading(false));
  };

  const handleActivate = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    props.adminService
      // @ts-ignore
      .postActivateAdmin({ adminUser })
      .then(() => {
        // @ts-ignore
        loadAdminuser(adminUser.id);
        props.showInfo(t("adminActivated"));
      })
      .catch((error: any) => {
        props.handleError(error);
      })
      .finally(() => setIsLoading(false));
  };

  const renderUserDetails = () => {
    if (!adminUser) {
      return <></>;
    }

    //const caption = ` (${Formatter.statusLocalization(adminUser.status, t)})`

    return (
      <>
        <IonItemDivider>
          <IonLabel>{t("userDetails")}</IonLabel>
        </IonItemDivider>
        <IonItem lines="none">
          <IonLabel>{t("email")}</IonLabel>
          <IonInput
            value={adminUser.email}
            placeholder={t("emailPL")}
            readonly={!isNewUser}
            onIonChange={(e: any) =>
              editAdminUser({
                email: (e.target as HTMLInputElement).value,
              })
            }
          />
        </IonItem>
        <IonItem lines="none">
          <IonLabel>{t("firstName")}</IonLabel>
          <IonInput
            value={adminUser.firstName}
            placeholder={t("firstNamePL")}
            onIonChange={(e: any) =>
              editAdminUser({
                firstName: (e.target as HTMLInputElement).value,
              })
            }
          />
        </IonItem>
        <IonItem lines="none">
          <IonLabel>{t("lastName")}</IonLabel>
          <IonInput
            value={adminUser.lastName}
            placeholder={t("lastNamePL")}
            onIonChange={(e: any) =>
              editAdminUser({
                lastName: (e.target as HTMLInputElement).value,
              })
            }
          />
        </IonItem>
        <IonItem lines="none">
          <IonLabel>{t("phoneNumber")}</IonLabel>
          <IonInput
            value={adminUser.phoneNumber}
            placeholder={t("phoneNumberPL")}
            onIonChange={(e: any) =>
              editAdminUser({
                phoneNumber: (e.target as HTMLInputElement).value,
              })
            }
          />
        </IonItem>
      </>
    );
  };

  const renderUserRoles = () => {
    if (!adminUser) {
      return <></>;
    }

    return (
      <>
        <IonItemDivider>
          <IonLabel>Rooli</IonLabel>
        </IonItemDivider>

        <IonRadioGroup
          value={adminUser.role}
          onIonChange={(e: any) =>
            editAdminUser({
              role: (e.target as HTMLInputElement).value,
            })
          }
        >
          {state.role === AdminUserRole.SUPERADMIN && (
            <IonItem lines="none">
              <IonLabel>{t("roleSuperadmin")}</IonLabel>
              <IonRadio slot="start" value="superadmin" />
            </IonItem>
          )}

          <IonItem lines="none">
            <IonLabel>{t("roleAdmin")}</IonLabel>
            <IonRadio slot="start" value="admin" />
          </IonItem>

          <IonItem lines="none">
            <IonLabel>{t("roleYpa")}</IonLabel>
            <IonRadio slot="start" value="ypa" />
          </IonItem>
        </IonRadioGroup>
      </>
    );
  };

  const renderUserHomes = () => {
    if (!adminUser) {
      return <></>;
    }

    const adminRole =
      state.role === AdminUserRole.ADMIN ||
      state.role === AdminUserRole.SUPERADMIN;
    return (
      <>
        <IonItemDivider>
          <IonLabel>{t("selectAdminHomes")}</IonLabel>
        </IonItemDivider>
        <IonItem lines="none">
          <HomePicker
            activeIds={adminUser.homes}
            onChange={(selected) => editAdminUser({ homes: selected })}
            adminRole={adminRole}
          />
        </IonItem>
      </>
    );
  };

  if (!adminUser) {
    return <></>;
  }

  return (
    <IonPage className="pageLightGrey calendarEventDetails">
      <PageHeader title={adminUser.email ? adminUser.email : t("createUser")} />
      <EsperiSpinner size={SpinnerSize.Large} hidden={!isLoading} />
      <IonContent>
        <IonGrid>
          <IonRow hidden={isLoadingPage}>
            <IonCol size="6">
              <div className="AdminFormContainer">
                {adminUser && renderUserDetails()}
              </div>
            </IonCol>
            <IonCol size="6">
              <div className="AdminFormContainer">
                {adminUser && renderUserRoles()}
              </div>
              {adminUser.role === AdminUserRole.YPA && (
                <div className="AdminFormContainer">
                  {adminUser && renderUserHomes()}
                </div>
              )}
            </IonCol>
          </IonRow>
          <IonRow className="AdminFormContainer" hidden={isLoadingPage}>
            <IonCol>
              <IonItem lines="none" class="ion-text-end">
                {!isNewUser &&
                  adminUser.status !== AdminUserStatus.DEACTIVATED && (
                    <IonButton
                      slot="end"
                      onClick={handleDeactivate}
                      hidden={false}
                    >
                      {t("deactivateUser")}
                    </IonButton>
                  )}
                {adminUser.status === AdminUserStatus.DEACTIVATED && (
                  <IonButton slot="end" onClick={handleActivate} hidden={false}>
                    {t("activateUser")}
                  </IonButton>
                )}
                {(adminUser.status === AdminUserStatus.CREATED ||
                  adminUser.status === AdminUserStatus.INVITED) && (
                    <IonButton slot="end" onClick={handleInvite} hidden={false}>
                      {t("invite")}
                    </IonButton>
                  )}
                {isNewUser && (
                  <IonButton
                    slot="end"
                    onClick={handleCreate}
                    disabled={!validAdminUser}
                  >
                    {t("create")}
                  </IonButton>
                )}
                {!isNewUser &&
                  adminUser.status !== AdminUserStatus.DEACTIVATED && (
                    <IonButton
                      slot="end"
                      onClick={handleSave}
                      disabled={!isEdited}
                    >
                      {t("save")}
                    </IonButton>
                  )}
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(
  withAdminService(withErrorHandler(withInfoHandler(AdminDetailsPage)))
);

import axios from "axios";
import React from "react";

import { HTTP_API_URL } from "util/Constants";
import { BaseService } from "./BaseService";
import { UserAppState } from "../providers/User/UserReducer";
import {
  Employee,
  EmployeeActionTypes,
  EmployeeResponse,
  EmployeesResponse,
} from "../providers/Employee/EmployeeActionTypes";
import { EmployeeActions } from "../providers/Employee";
import {
  Device,
  DeviceActionTypes,
  DeviceResponse,
  DevicesResponse,
  RegistrationCode,
} from "../providers/Device/DeviceActionTypes";
import { DeviceActions } from "../providers/Device";
import { PostCreateAdmin, PostUpdateAdmin } from "./AdminRequests";
import { AdminActions } from "../providers/Admin";
import { HomeItem } from "../providers/Admin/AdminActionTypes";

export default class DeviceService extends BaseService {
  /**
   * Using static field for debouncing the mark as read API call.
   */
  private static timeout: any | null = null;

  private readonly dispatch: React.Dispatch<DeviceActionTypes>;

  constructor(
    state: UserAppState,
    dispatch: React.Dispatch<DeviceActionTypes>
  ) {
    super(state);
    this.dispatch = dispatch;
  }

  fetchDevices(page: string = "0", home?: HomeItem): Promise<DevicesResponse> {
    return new Promise(async (resolve, reject) => {
      if (page === "0") {
        this.dispatch(DeviceActions.clearDevices());
      }

      const url = new URL(HTTP_API_URL + "/devices");
      url.searchParams.append("pagesize", "20");
      url.searchParams.append("page", page);
      if (home) {
        url.searchParams.append("homeId", home.id.toString());
      }

      try {
        const response = await axios.get(
          url.toString(),
          this.addAuthorizationHeaders()
        );
        response.data.devices.sort((a: Device, b: Device) => {
          // @ts-ignore
          return new Date(a.name) - new Date(b.name);
        });

        this.dispatch(
          DeviceActions.onGetDevicesResponse(response.data.devices)
        );
        resolve(response.data as DevicesResponse);
      } catch (e) {
        reject(e);
      }
    });
  }

  fetchDevice(deviceId: number): Promise<DeviceResponse> {
    return new Promise(async (resolve, reject) => {
      //const url = new URL(HTTP_API_URL + "/devices/" + deviceId);
      const url = new URL(HTTP_API_URL + "/devices");

      try {
        const response = await axios.get(
          url.toString(),
          this.addAuthorizationHeaders()
        );

        const device = response.data.devices.find(
          (d: Device) => d.id === deviceId
        );
        this.dispatch(DeviceActions.onGetDeviceResponse(device));
        resolve({ device });
      } catch (e) {
        reject(e);
      }
    });
  }

  postCreateDevice(device: Device): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/devices");
      try {
        const response = await axios.post(
          url.toString(),
          device,
          this.addAuthorizationHeaders()
        );

        this.dispatch(DeviceActions.onCreateDeviceResponse(response.data));
        resolve(response.data);
      } catch (e) {
        reject(e);
      }
    });
  }

  postUpdateDevice(device: Device): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/devices/" + device.id);
      try {
        const response = await axios.put(
          url.toString(),
          device,
          this.addAuthorizationHeaders()
        );

        this.dispatch(DeviceActions.onUpdatedDeviceResponse(response.data));
        resolve(response.data);
      } catch (e) {
        reject(e);
      }
    });
  }

  fetchRegistrationCode(device: Device): Promise<RegistrationCode> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/devices/" + device.id + "/register");

      try {
        const response = await axios.post(
          url.toString(),
          this.addAuthorizationHeaders()
        );

        this.dispatch(DeviceActions.onGetRegistrationCode(response.data));
        resolve(response.data);
      } catch (e) {
        reject(e);
      }
    });
  }

  postDeactivation(device: Device): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(
        HTTP_API_URL + "/devices/" + device.id + "/deactivate"
      );

      try {
        const response = await axios.post(
          url.toString(),
          this.addAuthorizationHeaders()
        );

        this.dispatch(DeviceActions.onDeactivateDeviceResponse(device.id));
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }
}

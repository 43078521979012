import axios from "axios";
import React from "react";

import { HTTP_API_URL } from "util/Constants";
import { BaseService } from "./BaseService";
import { UserAppState } from "../providers/User/UserReducer";
import {
  Employee,
  EmployeeActionTypes,
  EmployeeResponse,
  EmployeesResponse,
} from "../providers/Employee/EmployeeActionTypes";
import { EmployeeActions } from "../providers/Employee";
import { HomeItem } from "../providers/Admin/AdminActionTypes";

export interface PostCreateEmployee {
  employee: Employee;
}

export interface PostUpdateEmployee {
  employee: Employee;
}

export interface PostPasswordReset {
  employee: Employee;
}

export interface PostDeactivateEmployee {
  employee: Employee;
}
export interface PostActivateEmployee {
  employee: Employee;
}

export default class EmployeeService extends BaseService {
  /**
   * Using static field for debouncing the mark as read API call.
   */
  private static timeout: any | null = null;

  private readonly dispatch: React.Dispatch<EmployeeActionTypes>;

  constructor(
    state: UserAppState,
    dispatch: React.Dispatch<EmployeeActionTypes>
  ) {
    super(state);
    this.dispatch = dispatch;
  }

  fetchEmployees(
    page: string = "0",
    home: HomeItem | undefined,
    activeOnly: boolean | undefined,
    query: string | undefined
  ): Promise<EmployeesResponse> {
    return new Promise(async (resolve, reject) => {
      if (page === "0") {
        this.dispatch(EmployeeActions.clearEmployees());
      }

      const url = new URL(HTTP_API_URL + "/employees");
      url.searchParams.append("pagesize", "20");
      url.searchParams.append("page", page);
      if (home) {
        url.searchParams.append("homeId", home.id.toString());
      }
      if (activeOnly) {
        url.searchParams.append("active", activeOnly ? "true" : "false");
      }
      if (query) {
        url.searchParams.append("nameSearch", query);
      }

      try {
        const response = await axios.get(
          url.toString(),
          this.addAuthorizationHeaders()
        );
        response.data.employees.sort((a: Employee, b: Employee) => {
          // @ts-ignore
          return new Date(a.lastName) - new Date(b.lastName);
        });

        this.dispatch(
          EmployeeActions.onGetEmployeesResponse(response.data.employees)
        );
        resolve(response.data as EmployeesResponse);
      } catch (e) {
        reject(e);
      }
    });
  }

  fetchEmployee(employeeId: number): Promise<EmployeeResponse> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/employees/" + employeeId);
      try {
        const response = await axios.get(
          url.toString(),
          this.addAuthorizationHeaders()
        );

        this.dispatch(EmployeeActions.onGetEmployeeResponse(response.data));
        resolve({ employee: response.data });
      } catch (e) {
        reject(e);
      }
    });
  }

  postPasswordReset(request: PostPasswordReset): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(
        HTTP_API_URL + "/employees/" + request.employee.id + "/password/reset"
      );
      try {
        debugger;
        const response = await axios.post(
          url.toString(),
          this.addAuthorizationHeaders()
        );

        debugger;
        this.dispatch(
          EmployeeActions.onEmployeePasswordResetResponse(
            response.data.password
          )
        );
        resolve({ password: response.data.password });
      } catch (e) {
        reject(e);
      }
    });
  }

  postCreateEmployee(request: PostCreateEmployee): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/employees");
      try {
        debugger;
        const response = await axios.post(
          url.toString(),
          request.employee,
          this.addAuthorizationHeaders()
        );

        debugger;
        this.dispatch(EmployeeActions.onUpdatedEmployeeResponse(response.data));
        resolve(response.data);
      } catch (e) {
        reject(e);
      }
    });
  }

  postUpdateEmployee(request: PostUpdateEmployee): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/employees/" + request.employee.id);
      try {
        const response = await axios.put(
          url.toString(),
          request.employee,
          this.addAuthorizationHeaders()
        );

        this.dispatch(EmployeeActions.onUpdatedEmployeeResponse(response.data));
        resolve(response.data);
      } catch (e) {
        reject(e);
      }
    });
  }

  postDeactivateEmployee(request: PostDeactivateEmployee): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(
        HTTP_API_URL + "/employees/" + request.employee.id + "/deactivate"
      );
      try {
        const response = await axios.post(
          url.toString(),
          request.employee,
          this.addAuthorizationHeaders()
        );

        this.dispatch(
          EmployeeActions.onDeactivateEmployeeResponse(response.data)
        );
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  postActivateEmployee(request: PostActivateEmployee): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(
        HTTP_API_URL + "/employees/" + request.employee.id + "/reactivate"
      );
      try {
        const response = await axios.post(
          url.toString(),
          request.employee,
          this.addAuthorizationHeaders()
        );

        this.dispatch(
          EmployeeActions.onActivateEmployeeResponse(response.data)
        );
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }
}

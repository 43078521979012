import {
  withErrorHandler,
  WithErrorHandlerProps,
  withInfoHandler,
  WithInfoHandlerProps,
} from "../../index";
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import {
  withCustomerService,
  WithCustomerServiceProps,
} from "../../../service";
import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonPage,
  IonRow,
} from "@ionic/react";
import PageHeader from "components/PageHeader";
import { Customer } from "providers/Customer/CustomerActionTypes";
import { useTranslation } from "react-i18next";
import EsperiSpinner, { SpinnerSize } from "components/EsperiSpinner";
import { isChanged, isValid } from "../../../util/Validator";
import { UserContext } from "providers/User";
import { AdminUserRole } from "providers/User/UserActionTypes";
import { HomePicker, OmaUserPicker } from "components/pickers";

import "./Customers.css";
import { OmaUser } from "providers/OmaUser/OmaUserActionTypes";
import WizardModal from "components/WizardModal";

interface CustomerDetailsProps
  extends WithCustomerServiceProps,
    RouteComponentProps,
    WithErrorHandlerProps,
    WithInfoHandlerProps {
  isModal: boolean | undefined;
  didDismiss?: (customers: Customer) => void;
}

const CustomerDetailsPage = (props: CustomerDetailsProps) => {
  const { state } = useContext(UserContext);
  const [customer, setCustomer] = useState<Customer | undefined>(undefined);
  const [originalCustomer, setOriginalCustomer] = useState<
    Customer | undefined
  >(undefined);
  const [isNewUser, setIsNewUser] = useState<boolean>(false);
  const [isLoadingPage, setLoadingPage] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isOmaUserModalOpen, setIsOmaUserModalOpen] = useState(false);
  const { t } = useTranslation();

  const [isConfirmDeactivationOpen, setIsConfirmDeactivationOpen] = useState<
    boolean
  >(false);
  const [isConfirmActivationOpen, setIsConfirmActivationOpen] = useState<
    boolean
  >(false);

  const adminRole =
    state.role === AdminUserRole.ADMIN ||
    state.role === AdminUserRole.SUPERADMIN;

  const keys = ["firstName", "lastName", "home", "users"];
  const requiredKeys = ["firstName", "lastName", "home"];
  const isEdited = isChanged(customer, originalCustomer, keys);
  const validCustomer = isValid(customer, requiredKeys);

  const handleDeactivate = (e: any) => {
    e.preventDefault();
    setIsConfirmDeactivationOpen(true);
  };
  const handleActivate = (e: any) => {
    e.preventDefault();
    setIsConfirmActivationOpen(true);
  };

  const loadCustomer = (customerId: number) => {
    setLoading(true);
    props.customerService
      .fetchCustomer(customerId)
      .then((response: any) => {
        setCustomer(response.customer);
        setOriginalCustomer(response.customer);
        console.log("response.customer: ", response.customer);
      })
      .catch((error: any) => {
        props.handleError(error);
      })
      .finally(() => {
        setLoading(false);
        setLoadingPage(false);
      });
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    console.log("*** handleUpdateCustomer", customer);
    setLoading(true);
    props.customerService
      // @ts-ignore
      .postUpdateCustomer({ customer })
      .then((response: any) => {
        setCustomer(response);
        setOriginalCustomer(response);
        setIsNewUser(false);
      })
      .catch((error: any) => {
        props.handleError(error);
      })
      .finally(() => setLoading(false));
  };

  const doDeactivate = () => {
    setLoading(true);
    props.customerService
      // @ts-ignore
      .postDeactivateCustomer({ customer })
      .then((response: any) => {
        // @ts-ignore
        loadCustomer(customer.id);
        props.showInfo(
          t("customerDeactivated", {
            name: `${customer?.firstName} ${customer?.lastName}`,
          })
        );
      })
      .catch((error: any) => {
        props.handleError(error);
      })
      .finally(() => setLoading(false));
  };

  const doActivate = () => {
    setLoading(true);
    props.customerService
      // @ts-ignore
      .postActivateCustomer({ customer })
      .then((response: any) => {
        // @ts-ignore
        loadCustomer(customer.id);
        props.showInfo(
          t("customerActivated", {
            name: `${customer?.firstName} ${customer?.lastName}`,
          })
        );
      })
      .catch((error: any) => {
        props.handleError(error);
      })
      .finally(() => setLoading(false));
  };

  const handleCreate = (e: any) => {
    e.preventDefault();
    console.log("*** handleCreateCustomer", customer);

    setLoading(true);
    props.customerService
      // @ts-ignore
      .postCreateCustomer({ customer })
      .then((response: any) => {
        setCustomer(response);
        setOriginalCustomer(response);
        setIsNewUser(false);
      })
      .catch((error: any) => {
        props.handleError(error);
      })
      .finally(() => setLoading(false));
  };

  const editCustomer = (newState: {}) => {
    if (customer) {
      const newValue = { ...customer, ...newState };
      setCustomer(newValue);
    }
  };

  useEffect(() => {
    // @ts-ignore
    if (props.match.params.id) {
      // Existing user
      setLoadingPage(true);
      setIsNewUser(false);
      // @ts-ignore
      const id: number = parseInt(props.match.params.id);
      loadCustomer(id);
    } else {
      // New user
      console.log("******NEW USER");
      setIsNewUser(true);
      setCustomer({} as Customer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.url]);

  const renderOmaUserDetails = () => {
    if (!customer) {
      return <></>;
    }

    return (
      <>
        <IonItemDivider>
          <IonLabel>{t("customerInfo")}</IonLabel>
        </IonItemDivider>
        <IonItem lines="none">
          <IonLabel>{t("omaUserFirstName")}</IonLabel>
          <IonInput
            value={customer.firstName}
            placeholder={t("firstNamePL")}
            required={true}
            onIonChange={(e: any) =>
              editCustomer({ firstName: (e.target as HTMLInputElement).value })
            }
          />
        </IonItem>
        <IonItem lines="none">
          <IonLabel>{t("omaUserLastName")}</IonLabel>
          <IonInput
            value={customer.lastName}
            placeholder={t("lastNamePL")}
            required={true}
            onIonChange={(e: any) =>
              editCustomer({ lastName: (e.target as HTMLInputElement).value })
            }
          />
        </IonItem>
      </>
    );
  };

  if (!customer) {
    return <></>;
  }

  const onWizardCompleted = (customer: Customer, omaUser: OmaUser) => {
    setIsOmaUserModalOpen(false);
  };

  const renderPage = () => (
    <IonPage className="pageLightGrey customerDetailsPage">
      <WizardModal
        isOpen={isOmaUserModalOpen}
        customer={customer}
        onWizardCompleted={onWizardCompleted}
        onCancel={() => setIsOmaUserModalOpen(false)}
      />
      <PageHeader
        title={
          isNewUser
            ? t("createNewCustomer")
            : `${customer.firstName} ${customer.lastName}`
        }
      />
      {renderContent()}
    </IonPage>
  );

  const renderContent = () => {
    return (
      <IonContent>
        <EsperiSpinner size={SpinnerSize.Large} hidden={!isLoading} />

        <IonAlert
          isOpen={isConfirmDeactivationOpen}
          onDidDismiss={() => setIsConfirmDeactivationOpen(false)}
          header={t("confirmCustomerDeactivationHeader")}
          message={t("confirmCustomerDeactivation", {
            name: `${customer?.firstName} ${customer?.lastName}`,
          })}
          buttons={[
            {
              text: t("cancel"),
              role: "cancel",
              handler: () => {},
            },
            {
              text: t("OK"),
              handler: () => {
                doDeactivate();
              },
            },
          ]}
        />
        <IonAlert
          isOpen={isConfirmActivationOpen}
          onDidDismiss={() => setIsConfirmActivationOpen(false)}
          header={t("confirmCustomerActivationHeader")}
          message={t("confirmCustomerActivation", {
            name: `${customer?.firstName} ${customer?.lastName}`,
          })}
          buttons={[
            {
              text: t("cancel"),
              role: "cancel",
              handler: () => {},
            },
            {
              text: t("OK"),
              handler: () => {
                doActivate();
              },
            },
          ]}
        />
        <IonGrid>
          <IonRow hidden={isLoadingPage}>
            <IonCol size="6">
              <div className="AdminFormContainer">{renderOmaUserDetails()}</div>
            </IonCol>
            <IonCol size="6">
              <div className="AdminFormContainer" hidden={props.isModal}>
                <IonItemDivider>
                  <IonLabel>{t("selectCustomerOmaUsers")}</IonLabel>
                </IonItemDivider>
                <OmaUserPicker
                  activeOmaUsers={customer.users}
                  adminRole={adminRole}
                  onChange={(selected) =>
                    editCustomer({ ...customer, users: selected })
                  }
                />
              </div>
              <div className="AdminFormContainer">
                <IonItemDivider>
                  <IonLabel>{t("selectCustomerHome")}</IonLabel>
                </IonItemDivider>
                <HomePicker
                  activeIds={customer.home ? [customer.home] : undefined}
                  singleSelect={true}
                  adminRole={adminRole}
                  onChange={(selected) =>
                    editCustomer({ ...customer, home: selected })
                  }
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow className="AdminFormContainer" hidden={isLoadingPage}>
            <IonCol>
              <IonItem lines="none" class="ion-text-end">
                {isNewUser && !props.isModal && (
                  <IonButton
                    slot="end"
                    onClick={handleCreate}
                    disabled={!validCustomer}
                  >
                    {t("newCustomer")}
                  </IonButton>
                )}
                {!isNewUser && (
                  <>
                    {customer.active && (
                      <IonButton slot="end" onClick={handleDeactivate}>
                        {t("deactivateCustomer")}
                      </IonButton>
                    )}
                    {!customer.active && (
                      <IonButton slot="end" onClick={handleActivate}>
                        {t("activateCustomer")}
                      </IonButton>
                    )}
                    <IonButton
                      slot="end"
                      onClick={handleSave}
                      disabled={!isEdited || !validCustomer}
                    >
                      {t("save")}
                    </IonButton>
                  </>
                )}
                {props.isModal && (
                  <IonButton
                    slot="end"
                    onClick={() =>
                      props.didDismiss ? props.didDismiss(customer) : {}
                    }
                    disabled={!validCustomer}
                  >
                    {t("wizardCustomerNext")}
                  </IonButton>
                )}
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    );
  };

  if (props.isModal) {
    return renderContent();
  } else {
    return renderPage();
  }
};

export default withRouter(
  withCustomerService(withErrorHandler(withInfoHandler(CustomerDetailsPage)))
);

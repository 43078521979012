import React, { createContext, useReducer, Dispatch } from "react";
import { initialState, UIAppState, reducer } from "./UIReducer";
import { UIActionTypes } from "./UIActionTypes";
import { logger } from "../index";

interface UIContextProps {
  state: UIAppState;
  dispatch: Dispatch<UIActionTypes>;
}

let UIContext = createContext({} as UIContextProps);
const loggerReducer = logger(reducer, "UI");

function UIContextProvider(props: any) {
  let [state, dispatch] = useReducer(loggerReducer, initialState);
  let value = { state, dispatch };

  return (
    <UIContext.Provider value={value}>{props.children}</UIContext.Provider>
  );
}

export { UIContext, UIContextProvider };

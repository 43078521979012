import React from "react";
import { IonLabel, IonCheckbox, IonRow, IonCol } from "@ionic/react";

import "./EsperiCheckbox.css";

interface CheckBoxProps {
  title: string;
  isChecked: boolean;
  onCheckedChanged: (checked: boolean) => void;
}

const EsperiRegisterCheckBox: React.FC<CheckBoxProps> = (props) => {
  return (
    <IonRow className="ion-text-center">
      <IonCol />
      <IonCol size="auto">
        <IonCheckbox
          className="registerCheckBox"
          checked={props.isChecked}
          onIonChange={(e) => props.onCheckedChanged(e.detail.checked)}
        />
      </IonCol>
      <IonCol size="auto">
        <IonLabel className="registerCheckBoxText">{props.title}</IonLabel>
      </IonCol>
      <IonCol />
    </IonRow>
  );
};

export default EsperiRegisterCheckBox;

import {
  ActionType,
  Employee,
  EmployeeActionTypes,
} from "./EmployeeActionTypes";

export interface EmployeeAppState {
  employees: Employee[]; // Array containing homes where user has family
}

export const initialState: EmployeeAppState = {
  employees: [],
};

export const employeeReducer = (
  state: EmployeeAppState,
  action: EmployeeActionTypes
) => {
  switch (action.type) {
    case ActionType.ClearEmployees: {
      return { ...state, employees: [] };
    }
    case ActionType.UpdateEmployeeResponse:
    case ActionType.GetEmployeeResponse:
      const index = state.employees.findIndex(
        (a) => a.id === action.employee.id
      );
      const employees =
        index >= 0
          ? [
              ...state.employees.slice(0, index),
              action.employee,
              ...state.employees.slice(index + 1),
            ]
          : [...state.employees, action.employee];

      return { ...state, employees };
    case ActionType.GetEmployeesResponse:
      return { ...state, employees: [...state.employees, ...action.employees] };
    default:
      return { ...state };
  }
};

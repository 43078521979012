import { Customer } from "providers/Customer/CustomerActionTypes";

export enum ActionType {
  ClearOmaUsers = "ClearOmaUsers",
  GetOmaUsersResponse = "GetOmaUsersResponse",
  GetOmaUserResponse = "GetOmaUserResponse",
  PostDeactivateOmaUserResponse = "PostDeactivateOmaUserResponse",
  PostReactivateOmaUserResponse = "PostReactivateOmaUserResponse",
  PostInviteOmaUserResponse = "PostInviteOmaUserResponse",
  PostUpdateOmaUserResponse = "PostUpdateOmaUserResponse"
}

export interface ClearOmaUsers {
  type: ActionType.ClearOmaUsers;
}

export interface GetOmaUsersResponse {
  type: ActionType.GetOmaUsersResponse;
  omaUsers: OmaUser[];
}

export interface GetOmaUserResponse {
  type: ActionType.GetOmaUserResponse;
  omaUser: OmaUser;
}

export interface PostDeactivateOmaUserResponse {
  type: ActionType.PostDeactivateOmaUserResponse;
  omaUser: OmaUser;
}

export interface PostReactivateOmaUserResponse {
  type: ActionType.PostReactivateOmaUserResponse;
  omaUser: OmaUser;
}

export interface PostInviteOmaUserResponse {
  type: ActionType.PostInviteOmaUserResponse;
  omaUser: OmaUser;
}

export interface PostUpdateOmaUserResponse {
  type: ActionType.PostUpdateOmaUserResponse;
  omaUser: OmaUser;
}

export type OmaUserActionTypes =
  | ClearOmaUsers
  | GetOmaUsersResponse
  | GetOmaUserResponse
  | PostDeactivateOmaUserResponse
  | PostReactivateOmaUserResponse
  | PostInviteOmaUserResponse
  | PostUpdateOmaUserResponse

/*
 MODEL
 */

export enum OmaUserStatus {
  CREATED = "CREATED",
  INVITED = "INVITED",
  REGISTERED = "REGISTERED",
  DEACTIVATED = "DEACTIVATED",
}

export interface OmaUser {
  id: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  status: string;
  customers: Customer[] | undefined;
  canInvite?: boolean;
  invitedAt?: string;
}

// export interface Customer {
//   id: number;
//   firstName: string;
//   lastName: string;
// }

export interface OmaUsersResponse {
  omaUser: OmaUser[];
  nextPage: string;
  unreadCount: number;
}

export interface OmaUserResponse {
  omaUser: OmaUser;
}

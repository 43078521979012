import React, { useContext } from "react";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { UserContext } from "providers/User";
import { HTTP_API_KEY, HTTP_LOGS_ENABLED } from "util/Constants";
import { SessionUtils } from "util/SessionUtils";
import UserService from "./UserService";
import FeedService from "./FeedService";
import AdminService from "./AdminService";
import { AdminContext } from "../providers/Admin";
import EmployeeService from "./EmployeeService";
import { EmployeeContext } from "../providers/Employee";
import DeviceService from "./DeviceService";
import { DeviceContext } from "../providers/Device";
import OmaUserService from "./OmaUserService";
import { OmaUserContext } from "../providers/OmaUser";
import CustomerService from "./CustomerService";
import { CustomerContext } from "../providers/Customer";
import CustomerNewsService from "./CustomerNewsService";
import { CustomerNewsContext } from "providers/CustomerNews";


/*
 AXIOS CONFIGURATIONS
 */
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers["x-api-key"] = HTTP_API_KEY;

const refreshAuthCall = (failedRequest: XMLHttpRequest) =>
  SessionUtils.initSession()
    .then((userSession) => {
      failedRequest.response.config.headers["Authorization"] =
        userSession.IdToken;
      return Promise.resolve();
    })
    .catch(() => {
      return Promise.reject();
    });

createAuthRefreshInterceptor(axios, refreshAuthCall, {
  statusCodes: [401],
});

export function setupAxios(history: any) {
  if (HTTP_LOGS_ENABLED) {
    axios.interceptors.request.use((request) => {
      console.log("HTTP starting request: " + request.url, request);
      return request;
    });
    axios.interceptors.response.use((response) => {
      console.log(
        "HTTP response for " + response.request.responseURL + " :",
        response
      );
      return response;
    });
  }
}

/*
 COMMON MODEL
 */

export interface Image {
  url: string;
}

/*
 SERVICES
 */

export interface WithUserServiceProps {
  userService: UserService;
}

export const withUserService = <P extends WithUserServiceProps>(
  Component: React.ComponentType<P>
): React.FC<Pick<P, Exclude<keyof P, keyof WithUserServiceProps>>> => (
  props: Pick<P, Exclude<keyof P, keyof WithUserServiceProps>>
) => {
  const { state, dispatch } = useContext(UserContext);
  const userService = (): UserService => new UserService(state, dispatch);

  return <Component {...(props as P)} userService={userService()} />;
};

export interface WithFeedServiceProps {
  feedService: FeedService;
}

export const withFeedService = <P extends WithFeedServiceProps>(
  Component: React.ComponentType<P>
): React.FC<Pick<P, Exclude<keyof P, keyof WithFeedServiceProps>>> => (
  props: Pick<P, Exclude<keyof P, keyof WithFeedServiceProps>>
) => {
  const { state } = useContext(UserContext);
  const feedService = (): FeedService => new FeedService(state);

  return <Component {...(props as P)} feedService={feedService()} />;
};

export interface WithAdminServiceProps {
  adminService: AdminService;
}

export const withAdminService = <P extends WithAdminServiceProps>(
  Component: React.ComponentType<P>
): React.FC<Pick<P, Exclude<keyof P, keyof WithAdminServiceProps>>> => (
  props: Pick<P, Exclude<keyof P, keyof WithAdminServiceProps>>
) => {
  const { state } = useContext(UserContext);
  const { dispatch } = useContext(AdminContext);
  const adminService = (): AdminService => new AdminService(state, dispatch);

  return <Component {...(props as P)} adminService={adminService()} />;
};

export interface WithEmployeeServiceProps {
  employeeService: EmployeeService;
}

export const withEmployeeService = <P extends WithEmployeeServiceProps>(
  Component: React.ComponentType<P>
): React.FC<Pick<P, Exclude<keyof P, keyof WithEmployeeServiceProps>>> => (
  props: Pick<P, Exclude<keyof P, keyof WithEmployeeServiceProps>>
) => {
  const { state } = useContext(UserContext);
  const { dispatch } = useContext(EmployeeContext);
  const employeeService = (): EmployeeService =>
    new EmployeeService(state, dispatch);

  return <Component {...(props as P)} employeeService={employeeService()} />;
};

export interface WithDeviceServiceProps {
  deviceService: DeviceService;
}

export const withDeviceService = <P extends WithDeviceServiceProps>(
  Component: React.ComponentType<P>
): React.FC<Pick<P, Exclude<keyof P, keyof WithDeviceServiceProps>>> => (
  props: Pick<P, Exclude<keyof P, keyof WithDeviceServiceProps>>
) => {
  const { state } = useContext(UserContext);
  const { dispatch } = useContext(DeviceContext);
  const deviceService = (): DeviceService => new DeviceService(state, dispatch);

  return <Component {...(props as P)} deviceService={deviceService()} />;
};

export interface WithOmaUserServiceProps {
  omaUserService: OmaUserService;
}

export const withOmaUserService = <P extends WithOmaUserServiceProps>(
  Component: React.ComponentType<P>
): React.FC<Pick<P, Exclude<keyof P, keyof WithOmaUserServiceProps>>> => (
  props: Pick<P, Exclude<keyof P, keyof WithOmaUserServiceProps>>
) => {
  const { state } = useContext(UserContext);
  const { dispatch } = useContext(OmaUserContext);
  const omaUserService = (): OmaUserService =>
    new OmaUserService(state, dispatch);

  return <Component {...(props as P)} omaUserService={omaUserService()} />;
};

export interface WithCustomerServiceProps {
  customerService: CustomerService;
}

export const withCustomerService = <P extends WithCustomerServiceProps>(
  Component: React.ComponentType<P>
): React.FC<Pick<P, Exclude<keyof P, keyof WithCustomerServiceProps>>> => (
  props: Pick<P, Exclude<keyof P, keyof WithCustomerServiceProps>>
) => {
  const { state } = useContext(UserContext);
  const { dispatch } = useContext(CustomerContext);
  const customerService = (): CustomerService =>
    new CustomerService(state, dispatch);

  return <Component {...(props as P)} customerService={customerService()} />;
};

export interface WithCustomerNewsServiceProps {
  customerNewsService: CustomerNewsService;
}

export const withCustomerNewsService = <P extends WithCustomerNewsServiceProps>(
  Component: React.ComponentType<P>
): React.FC<Pick<P, Exclude<keyof P, keyof WithCustomerNewsServiceProps>>> => (
  props: Pick<P, Exclude<keyof P, keyof WithCustomerNewsServiceProps>>
) => {
  const { state } = useContext(UserContext);
  const { dispatch } = useContext(CustomerNewsContext);
  const customerNewsService = (): CustomerNewsService =>
    new CustomerNewsService(state, dispatch);

  return <Component {...(props as P)} customerNewsService={customerNewsService()} />;
};
import { MainTab } from "./index";
import { HomeItem } from "../Admin/AdminActionTypes";

export enum ActionType {
  SplitPaneVisibilityChanged = "SplitPaneVisibilityChanged",
  MainPageTabChanged = "MainPageTabChanged",
  SetActiveHome = "SetActiveHome",
  SetActiveCSTargetChanged = "SetActiveCSTargetChanged"
}

interface SplitPaneVisibilityChanged {
  type: ActionType.SplitPaneVisibilityChanged;
  isSplitPaneVisible: boolean;
}

interface MainPageTabChanged {
  type: ActionType.MainPageTabChanged;
  selectedMainTab: MainTab;
}

interface ActiveHomeChanged {
  type: ActionType.SetActiveHome;
  activeHome: HomeItem | undefined;
}

interface ActiveCSTargetChanged {
  type: ActionType.SetActiveCSTargetChanged;
  csTargetHome: HomeItem | undefined;
  csItemType: string  | undefined 
}

export type UIActionTypes =
  | SplitPaneVisibilityChanged
  | MainPageTabChanged
  | ActiveHomeChanged
  | ActiveCSTargetChanged

import React, { createContext, useReducer, Dispatch } from "react";
import { logger } from "../index";
import {
  CustomerAppState,
  customerReducer,
  initialState,
} from "./CustomerReducer";
import { CustomerActionTypes } from "./CustomerActionTypes";

interface CustomerContextProps {
  state: CustomerAppState;
  dispatch: Dispatch<CustomerActionTypes>;
}

let CustomerContext = createContext({} as CustomerContextProps);
const loggerReducer = logger(customerReducer, "Customer");

function CustomerContextProvider(props: any) {
  let [state, dispatch] = useReducer(loggerReducer, initialState);
  let value = { state, dispatch };

  return (
    <CustomerContext.Provider value={value}>
      {props.children}
    </CustomerContext.Provider>
  );
}

export { CustomerContext, CustomerContextProvider };
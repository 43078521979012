import { MainTab } from "./index";
import { ActionType, UIActionTypes } from "./UIActionTypes";
import { HomeItem } from "../Admin/AdminActionTypes";

export class UIActions {
  static setSplitPaneVisible(visible: boolean): UIActionTypes {
    return {
      type: ActionType.SplitPaneVisibilityChanged,
      isSplitPaneVisible: visible,
    };
  }

  static changeMainTab(newTab: MainTab): UIActionTypes {
    return {
      type: ActionType.MainPageTabChanged,
      selectedMainTab: newTab,
    };
  }

  static setActiveHome(activeHome: HomeItem | undefined): UIActionTypes {
    return {
      type: ActionType.SetActiveHome,
      activeHome: activeHome,
    };
  }

  static setActiveCustomerNewsTarget(activeHome: HomeItem | undefined, itemType: string): UIActionTypes {
    return {
      type: ActionType.SetActiveCSTargetChanged,
      csTargetHome: activeHome,
      csItemType: itemType,
    };
  }
}

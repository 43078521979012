import {
  ActionType,
  Customer,
  CustomerActionTypes,
} from "./CustomerActionTypes";

export class CustomerActions {
  static clearCustomers(): CustomerActionTypes {
    return {
      type: ActionType.ClearCustomers,
    };
  }

  static onGetCustomersResponse(customers: Customer[]): CustomerActionTypes {
    return {
      type: ActionType.GetCustomersResponse,
      customers: customers,
    };
  }

  static onDeactivateCustomerResponse(customer: Customer): CustomerActionTypes {
    return {
      type: ActionType.PostDeactivateCustomerResponse,
      customer: customer,
    };
  }

  static onActivateCustomerResponse(customer: Customer): CustomerActionTypes {
    return {
      type: ActionType.PostActivateCustomerResponse,
      customer: customer,
    };
  }

  static onGetCustomerResponse(customer: Customer): CustomerActionTypes {
    return {
      type: ActionType.GetCustomerResponse,
      customer: customer,
    };
  }

  static onUpdatedCustomerResponse(customer: Customer): CustomerActionTypes {
    return {
      type: ActionType.UpdateCustomerResponse,
      customer: customer,
    };
  }
}

import {
  ActionType,
  ConsentItem,
  HomeItem,
  SettingItem,
  UserActionTypes,
} from "./UserActionTypes";

export interface UserAppState {
  initialized: boolean; // True when we have checked for existing session
  invitationToken: string; // Token used to start register flow
  confirmationToken: string; // Token used with SMS code
  registrationToken: string; // Token used with set password
  IdToken: string; // Token to identify user
  RefreshToken: string; // Token to fetch new idToken and accessToken
  AccessToken: string; // Token to get access for AWS e.g. S3 bucket
  ClientId: string; // Cognito user pool client id
  UserPoolId: string; // Cognito user pool id
  consents: ConsentItem[]; // Array containing consents user has not approved yet
  firstName: string; // User first name
  lastName: string; // User last name
  phoneNumber: string; // User phone number
  email: string; // User email
  recoveryEmail: string; // Email where password recovery instructions were sent
  settings: SettingItem[]; // Array containing user settings
  homes: HomeItem[]; // Array containing homes where user has family
  role: string; //role of the user
  activeHome: HomeItem | undefined;
  business: string | undefined;
}

export const initialState: UserAppState = {
  initialized: false,
  invitationToken: "",
  confirmationToken: "",
  registrationToken: "",
  IdToken: "",
  RefreshToken: "",
  AccessToken: "",
  ClientId: "",
  UserPoolId: "",
  consents: [],
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  recoveryEmail: "",
  settings: [],
  homes: [],
  role: "",
  activeHome: undefined,
  business: undefined,
};

export const userReducer = (state: UserAppState, action: UserActionTypes) => {
  switch (action.type) {
    case ActionType.Initialized: {
      if (action.session) {
        return { ...state, ...action.session, initialized: action.initialized };
      }
      return { ...state };
    }
    case ActionType.GetConfirmInvitationResponse:
    case ActionType.PostConfirmInvitationResponse:
    case ActionType.PostConfirmCodeResponse:
    case ActionType.PostSignupResponse:
    case ActionType.PostSigninResponse:
    case ActionType.ConsentsResponse:
    case ActionType.RecoveryEmailSent:
    case ActionType.SettingsResponse:
    case ActionType.UserProfileResponse:
      return { ...state, ...action };
    case ActionType.SignedOut:
      return { ...initialState };
  }
};

import React, { useEffect, useState } from "react";
import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonPage,
  IonRow,
} from "@ionic/react";

import "./OmaUsers.css";

import PageHeader from "components/PageHeader";
import { RouteComponentProps, withRouter } from "react-router";
import {
  withErrorHandler,
  WithErrorHandlerProps,
  withInfoHandler,
  WithInfoHandlerProps,
} from "../../index";

import { useTranslation } from "react-i18next";
import {
  default as EsperiSpinner,
  SpinnerSize,
} from "../../../components/EsperiSpinner";
import { Formatter } from "../../../util/Formatter";
import { isChanged, isValid } from "../../../util/Validator";
import { withOmaUserService, WithOmaUserServiceProps } from "../../../service";
import {
  OmaUser,
  OmaUserStatus,
} from "../../../providers/OmaUser/OmaUserActionTypes";
import { CustomerPicker } from "components/pickers";
import { Customer } from "providers/Customer/CustomerActionTypes";
import moment from 'moment';

interface OmaUserDetailsProps
  extends WithOmaUserServiceProps,
  RouteComponentProps,
  WithErrorHandlerProps,
  WithInfoHandlerProps {
  createForCustomer: Customer | undefined
  isModal: boolean | undefined
  didDismiss?: (omaUser: OmaUser) => void
}

const OmaUserDetailsPage = (props: OmaUserDetailsProps) => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [isLoadingPage, setLoadingPage] = useState(false);
  const [omaUser, setOmaUser] = useState<OmaUser | undefined>(undefined);
  const [originalOmaUser, setOriginalOmaUser] = useState<OmaUser | undefined>(
    undefined
  );
  const [isNewUser, setIsNewUser] = useState<boolean>(false);
  // const [password, setPassword] = useState<string | undefined>(undefined);
  // const [generateUsername, setGenerateUsername] = useState<boolean | undefined>(
  //   true
  // );
  const [isConfirmDeactivationOpen, setIsConfirmDeactivationOpen] = useState<
    boolean
  >(false);
  const [isConfirmReactivationOpen, setIsConfirmReactivationOpen] = useState<
    boolean
  >(false);

  const lockedCustomer = !!props.createForCustomer

  // const isActive = omaUser
  //   ? omaUser.status === OmaUserStatus.CREATED ||
  //   omaUser.status === OmaUserStatus.REGISTERED
  //   : false;

  const invitedAt = (): string | undefined => {
    if (omaUser?.status !== OmaUserStatus.INVITED || !omaUser?.invitedAt) return undefined
    return moment(omaUser.invitedAt).format("l LT");
  }

  const keys = ["firstName", "lastName", "email", "phoneNumber", "customers"];
  const requiredKeys = ["firstName", "lastName", "email", "phoneNumber"];
  const isEdited = isChanged(omaUser, originalOmaUser, keys);
  const validOmauser = isValid(omaUser, requiredKeys);

  const loadOmaUser = (omaUserId: number) => {
    setLoading(true);
    props.omaUserService
      .fetchOmaUser(omaUserId)
      .then((response) => {
        setOmaUser(response.omaUser);
        setOriginalOmaUser(response.omaUser);
        // setPassword(undefined);
      })
      .catch((error) => {
        props.handleError(error);
      })
      .finally(() => {
        setLoading(false);
        setLoadingPage(false);
      });
  };

  useEffect(() => {
    // @ts-ignore
    if (props.match.params.id && !props.isModal) {
      // Existing user
      setLoadingPage(true);
      setIsNewUser(false);
      // @ts-ignore
      const omaUserId: number = parseInt(props.match.params.id);
      loadOmaUser(omaUserId);
    } else {
      // New user
      console.log('******NEW USER');
      setIsNewUser(true);
      // setPassword(undefined);

      setOmaUser({} as OmaUser)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.url, props.createForCustomer]);

  const editOmaUser = (newState: {}) => {
    if (omaUser) {
      const newValue = { ...omaUser, ...newState };
      setOmaUser(newValue);
    }
  };

  const doDeactivate = () => {
    setLoading(true);
    props.omaUserService
      // @ts-ignore
      .postDeactivateOmaUser({ omaUser })
      .then((response: any) => {
        // @ts-ignore
        loadOmaUser(omaUser.id);
        props.showInfo(
          t("omaUserDeactivated", {
            name: `${omaUser?.firstName} ${omaUser?.lastName}`,
          })
        );
      })
      .catch((error: any) => {
        props.handleError(error);
      })
      .finally(() => setLoading(false));
  };

  const doReactivate = () => {
    setLoading(true);
    props.omaUserService
      // @ts-ignore
      .postReactivateOmaUser({ omaUser })
      .then((response: any) => {
        // @ts-ignore
        loadOmaUser(omaUser.id);
        props.showInfo(
          t("omaUserReactivated", {
            name: `${omaUser?.firstName} ${omaUser?.lastName}`,
          })
        );
      })
      .catch((error: any) => {
        props.handleError(error);
      })
      .finally(() => setLoading(false));
  };

  const handleSave = (e: any) => {
    debugger
    e.preventDefault();
    console.log('handleSave');

    setLoading(true);
    props.omaUserService
      // @ts-ignore
      .postUpdateOmaUser({ omaUser })
      .then((response: any) => {
        setOmaUser(response);
        setOriginalOmaUser(response);
        // setPassword(undefined);
        props.showInfo(t("profileSaved"));
      })
      .catch((error: any) => {
        props.handleError(error);
      })
      .finally(() => setLoading(false));
  }

  const handleCreate = (e: any) => {
    e.preventDefault();
    console.log('handleCreate');

    setLoading(true);
    props.omaUserService
      // @ts-ignore
      .postCreateOmaUser({ omaUser })
      .then((omaUser: any) => {
        setOmaUser(omaUser);
        setOriginalOmaUser(omaUser);
        // setPassword(undefined);
        setIsNewUser(false);
      })
      .catch((error: any) => {
        props.handleError(error);
      })
      .finally(() => setLoading(false));
  }

  const handleSendInvite = (e: any) => {
    e.preventDefault();
    console.log('TODO: handleSendInvite');

    setLoading(true);
    props.omaUserService
      // @ts-ignore
      .postInviteOmaUser({ omaUser })
      .then((response: any) => {
        console.log('postInviteOmaUser response', response);
        setOmaUser({ ...response.data });
        setOriginalOmaUser({ ...response.data });
      }).catch((error: any) => {
        props.handleError(error);
      })
      .finally(() => setLoading(false));
  }

  const handleDeactivate = (e: any) => {
    e.preventDefault();
    setIsConfirmDeactivationOpen(true);
  };

  const handleReactivate = (e: any) => {
    e.preventDefault();
    setIsConfirmReactivationOpen(true);
  };

  const handleModalDismiss = (e: any) => {
    e.preventDefault();
    if (props.didDismiss && omaUser) {
      props.didDismiss(omaUser)
    }
  }

  const renderOmaUserDetails = () => {
    if (!omaUser) {
      return <></>;
    }

    return (
      <>
        <IonItemDivider>
          <IonLabel>{t("omaUserInfo")}</IonLabel>
        </IonItemDivider>
        <IonItem lines="none">
          <IonLabel>{t("omaUserFirstName")}</IonLabel>
          <IonInput
            value={omaUser.firstName}
            placeholder={t("firstNamePL")}
            required={true}
            onIonChange={(e: any) =>
              editOmaUser({ firstName: (e.target as HTMLInputElement).value })
            }
          />
        </IonItem>
        <IonItem lines="none">
          <IonLabel>{t("omaUserLastName")}</IonLabel>
          <IonInput
            value={omaUser.lastName}
            placeholder={t("lastNamePL")}
            required={true}
            onIonChange={(e: any) =>
              editOmaUser({ lastName: (e.target as HTMLInputElement).value })
            }
          />
        </IonItem>
        <IonItem lines="none">
          <IonLabel>{t("omaUserEmail")}</IonLabel>
          <IonInput
            value={omaUser.email}
            placeholder={t("emailPL")}
            required={true}
            onIonChange={(e: any) =>
              editOmaUser({ email: (e.target as HTMLInputElement).value })
            }
          />
        </IonItem>
        <IonItem lines="none">
          <IonLabel>{t("omaUserPhone")}</IonLabel>
          <IonInput
            value={omaUser.phoneNumber}
            placeholder={t("phoneNumberPL")}
            required={true}
            onIonChange={(e: any) =>
              editOmaUser({ phoneNumber: (e.target as HTMLInputElement).value })
            }
          />
        </IonItem>
      </>
    );
  };

  const renderOmaUserStatus = () => {
    if (!omaUser || !omaUser.status) {
      return <></>;
    }

    const status = (status: string): string => {
      const lc = status.toLowerCase();
      const keyCapitalized = lc.charAt(0).toUpperCase() + lc.slice(1);
      const key = `adminLongStatus${keyCapitalized}`;
      return t(key);
    };

    return (
      <>
        <IonItemDivider>
          <IonLabel>{t("omaUserState")}</IonLabel>
        </IonItemDivider>
        <IonItem lines="none">
          <IonLabel className="invitationStatus">
            {Formatter.omaUserStatusLocalization(omaUser.status, t)}
            {omaUser.invitedAt && <span>{invitedAt()}</span>}
          </IonLabel>


          {omaUser.canInvite && (
            <IonButton slot="end" onClick={handleSendInvite}>
              {t("invite")}
            </IonButton>
          )}
          {(omaUser.status === OmaUserStatus.INVITED ||
            omaUser.status === OmaUserStatus.CREATED ||
            omaUser.status === OmaUserStatus.REGISTERED) && (
              <IonButton slot="end" onClick={handleDeactivate}>
                {t("deactivateOmaUser")}
              </IonButton>
            )}
          {(omaUser.status === OmaUserStatus.DEACTIVATED) && (
            <IonButton slot="end" onClick={handleReactivate}>
              {t("reactivateOmaUser")}
            </IonButton>
          )}
        </IonItem>
      </>
    );
  };

  if (!omaUser) {
    return <></>;
  }


  const renderContent = () => {
    return (
      <IonContent>
        <EsperiSpinner size={SpinnerSize.Large} hidden={!isLoading} />
        <IonAlert
          isOpen={isConfirmDeactivationOpen}
          onDidDismiss={() => setIsConfirmDeactivationOpen(false)}
          header={t("confirmOmaUserDeactivationHeader")}
          message={t("confirmOmaUserDeactivation", {
            name: `${omaUser?.firstName} ${omaUser?.lastName}`,
          })}
          buttons={[
            {
              text: t("cancel"),
              role: "cancel",
              handler: () => { },
            },
            {
              text: t("OK"),
              handler: doDeactivate,
            },
          ]}
        />
        <IonAlert
          isOpen={isConfirmReactivationOpen}
          onDidDismiss={() => setIsConfirmReactivationOpen(false)}
          header={t("confirmOmaUserReactivationHeader")}
          message={t("confirmOmaUserReactivation", {
            name: `${omaUser?.firstName} ${omaUser?.lastName}`,
          })}
          buttons={[
            {
              text: t("cancel"),
              role: "cancel",
              handler: () => { },
            },
            {
              text: t("OK"),
              handler: doReactivate,
            },
          ]}
        />
        <IonGrid>
          <IonRow hidden={isLoadingPage}>
            <IonCol size="6">
              <div className="AdminFormContainer">{renderOmaUserDetails()}</div>
            </IonCol>
            <IonCol size="6">
              {!lockedCustomer && <div className="AdminFormContainer">
                <CustomerPicker
                  activeCustomers={omaUser.customers}
                  adminRole={true}
                  onChange={(selected) =>
                    editOmaUser({ ...omaUser, customers: selected })
                  }
                />
              </div>}
              <div className="AdminFormContainer">
                {renderOmaUserStatus()}
              </div>
            </IonCol>
          </IonRow>
          <IonRow className="AdminFormContainer" hidden={isLoadingPage}>
            <IonCol>
              <IonItem lines="none" class="ion-text-end">
                {isNewUser && !props.isModal && (
                  <IonButton
                    slot="end"
                    onClick={handleCreate}
                    disabled={!validOmauser}
                  >
                    {t("newOmaUser")}
                  </IonButton>
                )}
                {!isNewUser && !props.isModal && (
                  <IonButton
                    slot="end"
                    onClick={handleSave}
                    disabled={!isEdited}
                  >
                    {t("save")}
                  </IonButton>
                )}
                {props.isModal && (
                  <IonButton
                    slot="end"
                    onClick={handleModalDismiss}
                    disabled={!validOmauser}
                  >
                    {t("wizardGo")}
                  </IonButton>
                )}
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    )
  }

  const renderPage = () => {
    return (
      <IonPage className="pageLightGrey calendarEventDetails">
        <PageHeader
          title={isNewUser ? t("createNewOmaUser") : `${omaUser.firstName} ${omaUser.lastName}`}
        />
        {renderContent()}
      </IonPage>)
  }

  if (props.isModal) {
    return renderContent()
  } else {
    return renderPage()
  }
};

export default withRouter(
  withOmaUserService(withErrorHandler(withInfoHandler(OmaUserDetailsPage)))
);

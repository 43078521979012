import { ActionType, OmaUser, OmaUserActionTypes } from "./OmaUserActionTypes";

export interface OmaUserAppState {
  omaUsers: OmaUser[]; // Array containing homes where user has family
}

export const initialState: OmaUserAppState = {
  omaUsers: [],
};

export const omaUserReducer = (
  state: OmaUserAppState,
  action: OmaUserActionTypes
) => {
  switch (action.type) {
    case ActionType.ClearOmaUsers: {
      return { ...state, omaUsers: [] };
    }
    case ActionType.PostUpdateOmaUserResponse:
    case ActionType.GetOmaUserResponse:
      const index = state.omaUsers.findIndex((a) => a.id === action.omaUser.id);
      const omaUsers =
        index >= 0
          ? [
              ...state.omaUsers.slice(0, index),
              action.omaUser,
              ...state.omaUsers.slice(index + 1),
            ]
          : [...state.omaUsers, action.omaUser];

      return { ...state, omaUsers };
    case ActionType.GetOmaUsersResponse:
      return { ...state, omaUsers: [...state.omaUsers, ...action.omaUsers] };
    default:
      return { ...state };
  }
};

import React, { createContext, useReducer, Dispatch } from "react";
import { logger } from "../index";
import {
  initialState,
  OmaUserAppState,
  omaUserReducer,
} from "./OmaUserReducer";
import { OmaUserActionTypes } from "./OmaUserActionTypes";

interface OmaUserContextProps {
  state: OmaUserAppState;
  dispatch: Dispatch<OmaUserActionTypes>;
}

let OmaUserContext = createContext({} as OmaUserContextProps);
const loggerReducer = logger(omaUserReducer, "OmaUser");

function OmaUserContextProvider(props: any) {
  let [state, dispatch] = useReducer(loggerReducer, initialState);
  let value = { state, dispatch };

  return (
    <OmaUserContext.Provider value={value}>
      {props.children}
    </OmaUserContext.Provider>
  );
}

export { OmaUserContext, OmaUserContextProvider };

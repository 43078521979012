import React, { createContext, useReducer, Dispatch } from "react";
import { logger } from "../index";
import { DeviceActionTypes } from "./DeviceActionTypes";
import { DeviceAppState, deviceReducer, initialState } from "./DeviceReducer";

interface DeviceContextProps {
  state: DeviceAppState;
  dispatch: Dispatch<DeviceActionTypes>;
}

let DeviceContext = createContext({} as DeviceContextProps);
const loggerReducer = logger(deviceReducer, "Device");

function DeviceContextProvider(props: any) {
  let [state, dispatch] = useReducer(loggerReducer, initialState);
  let value = { state, dispatch };

  return (
    <DeviceContext.Provider value={value}>
      {props.children}
    </DeviceContext.Provider>
  );
}

export { DeviceContext, DeviceContextProvider };

import React, { useState, useEffect } from "react"
import { DropTargetMonitor, useDrop } from "react-dnd"
import { NativeTypes } from "react-dnd-html5-backend"
import { useTranslation } from "react-i18next";

import "./PhotoUpload.css";

interface PhotoUploadProps {
    setPhoto: (photo: File) => void
    className?: string
    src: string
}

const PhotoUpload: React.FC<PhotoUploadProps> = ({
    setPhoto,
    className,
    src,
}: PhotoUploadProps) => {
    const [previewImage, setPreviewImage] = useState(src)
    const { t } = useTranslation();

    useEffect(() => {
        setPreviewImage(src)
    }, [src])

    const handlePhotoChange = (event: any) => {
        const file = event.target.files[0]
        if (!file) return
        setPhoto(file)
        setPreviewImage(URL.createObjectURL(file))
    }

    const [{ canDrop, isOver }, drop] = useDrop(
        () => ({
            accept: [NativeTypes.FILE],
            drop(item: { files: any[] }) {
                const file = item.files[0]
                setPhoto(file)
                setPreviewImage(URL.createObjectURL(file))
            },
            collect: (monitor: DropTargetMonitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
        }),
        []
    )

    const isActive = canDrop && isOver

    return (
        <>
            <div
                className={`${className} flImageContainer`}
                ref={drop}
            >
                <label htmlFor="file-upload">

                    {previewImage && <img src={previewImage} />}
                    {!previewImage && <div className="previewImageBox">{t('customerAddImage')}</div>}

                    {isActive && (
                        <div className="flImageReleaseMask">
                            <span>Release to drop</span>
                        </div>
                    )}
                </label>
                <div className="editIcon">
                </div>
            </div>

            <input
                id="file-upload"
                type="file"
                accept=".png,.jpg,.jpeg"
                onChange={handlePhotoChange}
                hidden
            />
        </>
    )
}

export default PhotoUpload

import React from "react";
import {
  IonIcon,
  IonButton,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonCol,
  IonRow,
} from "@ionic/react";
import "./EsperiHeader.css";
import { RouteComponentProps, withRouter } from "react-router";

interface PageHeaderProps extends RouteComponentProps {
  title: string;
}

const PageHeader = (props: PageHeaderProps) => {
  return (
    <IonHeader>
      <IonToolbar>
        <IonRow slot="start">
          <IonCol>
            <IonButton
              fill="clear"
              onClick={() => {
                props.history.goBack();
              }}
            >
              <IonIcon
                className="appTitleIconNormal"
                src={"/assets/ic_arrow_left.svg"}
              />
            </IonButton>
          </IonCol>
        </IonRow>
        <IonTitle className="pageHeaderTitle grey">{props.title}</IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export default withRouter(PageHeader);

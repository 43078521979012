import React, { useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonContent,
  IonFooter,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSlide,
  IonSlides,
} from "@ionic/react";

import "./OnboardingPage.css";

import { RouteComponentProps, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import EsperiAppHeader, { HeaderLeftIcon } from "components/EsperiAppHeader";
import { UIContext } from "providers/UI/UIAppState";
import { UIActions } from "../../providers/UI/UIActions";
import { MainTab } from "../../providers/UI";
import { getBrandStrings } from '../../util/Branding';

const OnboardingPage: React.FC<RouteComponentProps> = (props) => {
  const { state, dispatch } = useContext(UIContext);
  const [activeIndex, setActiveIndex] = useState(0);
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/ohje") {
      // @ts-ignore
      document.getElementById("slides")?.slideTo(0);
    }
  }, [location]);

  const onButtonClick = (e: any) => {
    e.preventDefault();
    if (activeIndex < 2) {
      // @ts-ignore
      document.getElementById("slides")?.slideNext();
    } else {
      dispatch(UIActions.changeMainTab(MainTab.EmployeesTab));
      props.history.replace("/etusivu");
    }
  };

  function getButtonText(): string {
    if (activeIndex < 2) {
      return t("next");
    } else {
      return t("close");
    }
  }

  const slide1 = () => {
    return (
      <IonSlide>
        <IonList className="onboardingSlide" lines="none">
          <IonItem className="onBoardingInfoText">
            <IonLabel className="ion-text-wrap">
              <span className="onBoardBoldRedText">
                {t("onboarding1Header", getBrandStrings()).toUpperCase()}
              </span>
              <br />
              <br />
              {t("onboarding1", getBrandStrings())}
            </IonLabel>
          </IonItem>
        </IonList>
      </IonSlide>
    );
  };

  const slide2 = () => {
    const { appName } = getBrandStrings();
    return (
      <IonSlide>
        <IonList className="onboardingSlide" lines="none">
          <IonItem className="onBoardingInfoText">
            <IonLabel className="ion-text-wrap">
              <span className="onBoardBoldRedText">
                {t("employees").toUpperCase()}
              </span>{" "}
              {t("onboarding2", getBrandStrings())}
              <br />
              <br />
              {appName}-sovelluksen käyttöoikeus luodaan "Lisää uusi käyttäjä" -napin
              kautta.
              <ol>
                <li>Täytä työntekijän yhteystiedot</li>
                <li>Valitse työntekijän rooli</li>
                <li>Valitse oikea hoivakoti</li>
                <li>Paina "Luo uusi käyttäjä"</li>
                <li>Paina "Uusi salasana"</li>
                <li>
                  Kerro työntekijälle hänen käyttäjätunnuksensa sekä salasanansa
                </li>
              </ol>
              Työntekijä pääsee kirjauduttuaan muuttamaan salasanan
              mieleisekseen. Mikäli työntekijä unohtaa salasanansa, tulee
              hänelle luoda väliaikaisesti uusi salasana; salasanaa ei voi
              palauttaa.
              <br />
              <br />
              Deaktivoi-napista voitte poistaa työntekijältä oikeuden käyttää {appName}-sovellusta.
            </IonLabel>
          </IonItem>
        </IonList>
      </IonSlide>
    );
  };

  const slide3 = () => {
    const { appName } = getBrandStrings();
    return (
      <IonSlide>
        <IonList className="onboardingSlide" lines="none">
          <IonItem className="onBoardingInfoText">
            <IonLabel className="ion-text-wrap">
              <span className="onBoardBoldRedText">
                {t("devices").toUpperCase()}
              </span>{" "}
              {t("onboarding3", getBrandStrings())}
              <br />
              <br />
              <span className="onBoardBoldRedText">HUOM.</span> jotta hoitaja
              voi käyttää {appName}-sovellusta, tulee hänen käyttää sitä sellaisen
              laitteen kautta, joka on paritettu Portaaliin. Laite tulee olla
              paritettuna, ennen kuin {appName}-sovellukseen voi kirjautua sisään!
              <br />
              <br />
              Voitte parittaa laitteen painamalla "Lisää uusi laite".
              <ol>
                <li>Nimeä laite</li>
                <li>Valitse hoivakoti, jossa laitetta käytetään</li>
                <li>Paina "Luo uusi"</li>
                <li>
                  Paina "Rekisteröi" ja lue ruutuun ilmestyvä QR-koodi sillä
                  laitteella, jota olet parittamassa (Avaa {appName}-sovellus
                  laitteessa)
                </li>
              </ol>
              Laite on tämän jälkeen paritettu, ja sillä voi kirjautua sisään {appName}-sovellukseen.
            </IonLabel>
          </IonItem>
        </IonList>
      </IonSlide>
    );
  };

  return (
    <IonPage className="onboardingRoot">
      <EsperiAppHeader
        hideLeftIcon={state.isSplitPaneVisible}
        hideEsperiLogo={state.isSplitPaneVisible}
        hideRightIcon={false}
        leftIconType={HeaderLeftIcon.Menu}
      />
      <IonContent className="contentLightGrey contentMaxWidth">
        <IonSlides
          id="slides"
          pager
          onIonSlideWillChange={() => {
            (document.getElementById("slides") as HTMLIonSlidesElement)
              ?.getActiveIndex()
              .then((index: number) => {
                setActiveIndex(index);
              })
              .catch(() => { });
          }}
        >
          {slide1()}
          {slide2()}
          {slide3()}
        </IonSlides>
      </IonContent>
      <IonFooter className="ion-padding ion-no-border onboardingFooter contentMaxWidth">
        <IonButton
          className="ion-margin-horizontal buttonRed"
          expand="block"
          onClick={onButtonClick}
        >
          {getButtonText()}
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default OnboardingPage;

import React, { createContext, useReducer, Dispatch } from "react";
import { logger } from "../index";
import { CustomerNewsActionTypes } from "./CustomerNewsActionTypes";
import {
  CustomerNewsAppState,
  customerNewsReducer,
  initialState,
} from "./CustomerNewsReducer";

interface CustomerNewsContextProps {
  state: CustomerNewsAppState;
  dispatch: Dispatch<CustomerNewsActionTypes>;
}

let CustomerNewsContext = createContext({} as CustomerNewsContextProps);
const loggerReducer = logger(customerNewsReducer, "CustomerNews");

function CustomerNewsContextProvider(props: any) {
  let [state, dispatch] = useReducer(loggerReducer, initialState);
  let value = { state, dispatch };

  return (
    <CustomerNewsContext.Provider value={value}>
      {props.children}
    </CustomerNewsContext.Provider>
  );
}

export { CustomerNewsContext, CustomerNewsContextProvider };
import {
  ActionType,
  AdminActionTypes,
  AdminUser,
  GetHomesResponse,
} from "./AdminActionTypes";

export class AdminActions {
  static clearAdmins(): AdminActionTypes {
    return {
      type: ActionType.ClearAdminUsers,
    };
  }

  static onGetAdminUsersResponse(adminUsers: AdminUser[]): AdminActionTypes {
    return {
      type: ActionType.GetAdminUsersResponse,
      adminUsers: adminUsers,
    };
  }

  static onGetAdminUserResponse(adminUser: AdminUser): AdminActionTypes {
    return {
      type: ActionType.GetAdminUserResponse,
      adminUser: adminUser,
    };
  }

  static onUpdatedAdminUserResponse(adminUser: AdminUser): AdminActionTypes {
    return {
      type: ActionType.UpdateAdminUserResponse,
      adminUser: adminUser,
    };
  }

  static onInviteAdminResponse(): AdminActionTypes {
    return {
      type: ActionType.InviteAdminResponse,
    };
  }

  static onDeactivateAdminResponse(adminUser: AdminUser): AdminActionTypes {
    return {
      type: ActionType.DeactivateAdminResponse,
      adminUser: adminUser,
    };
  }

  static onActivateAdminResponse(adminUser: AdminUser): AdminActionTypes {
    return {
      type: ActionType.ActivateAdminResponse,
      adminUser: adminUser,
    };
  }

  static onGetHomesResponse(response: GetHomesResponse): AdminActionTypes {
    return {
      type: ActionType.GetHomesResponse,
      homes: response.homes,
    };
  }
}

import React, { useContext, useEffect, useState } from "react";
import { withCustomerService, WithCustomerServiceProps } from "service";
import { RouteComponentProps, withRouter } from "react-router";
import { UIContext } from "../../../providers/UI/UIAppState";
import { IonAlert, IonButton, IonCol, IonContent, IonGrid, IonListHeader, IonRow, IonText, IonToolbar } from "@ionic/react";
import EsperiSpinner, { SpinnerSize } from "components/EsperiSpinner";
import ErrorView from "components/ErrorView";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchMenu from "components/SearchMenu";
import EmptyView, { EmptyViewType } from "components/EmptyView";
import { useTranslation } from "react-i18next";
import ErrorViewSmall from "components/ErrorViewSmall";
import { CustomerContext } from "../../../providers/Customer";
import LoadMoreView from "components/LoadMoreView";
import { Customer } from "providers/Customer/CustomerActionTypes";
import CustomerCell from "./CustomerCell";
import WizardModal from "components/WizardModal";

interface CustomersProps extends WithCustomerServiceProps, RouteComponentProps {
  hidden: boolean;
}

const Customers = (props: CustomersProps) => {
  const { state: uiState } = useContext(UIContext);
  const { state: customerState } = useContext(CustomerContext);

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [query, setQuery] = useState<string | undefined>(undefined);
  const [activeOnly, setActiveOnly] = useState<boolean>(true);
  const [nextPage, setNextPage] = useState<string>("0");
  const [hasMoreItems, setHasMoreItems] = useState<boolean>(false);
  const [isFetchMoreError, setFetchMoreError] = useState(false);
  const [isConfirmUseWizardOpen, setIsConfirmUseWizardOpen] = useState<boolean>(false)
  const [isWizardOpen, setIsWizardOpen] = useState<boolean>(false)

  const { t } = useTranslation();
  
  useEffect(() => {
    fetchCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiState.activeHome, query, activeOnly]);
  
  function renderFetchMoreError() {
    if (isFetchMoreError) {
      return (
        <ErrorViewSmall
          title={t("adminUsersLoadingMoreError")}
          onTryAgain={() => {
            setFetchMoreError(false);
            setHasMoreItems(true);
          }}
        />
      );
    }
  }

  const isEmpty = !isLoading && customerState.customers.length === 0;

  function fetchCustomers() {
    setLoading(true);
    setError(false);
    props.customerService
      .fetchCustomers("0", uiState.activeHome, activeOnly, query)
      .then((response) => {
        setLoading(false);
        setNextPage(response.nextPage);
        setHasMoreItems(response.nextPage !== null);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        setHasMoreItems(false);
      });
  }

  const fetchMore = () => {
    setFetchMoreError(false);
    setHasMoreItems(false);
    props.customerService
      .fetchCustomers(nextPage, uiState.activeHome, activeOnly, query)
      .then((response) => {
        setNextPage(response.nextPage);
        setHasMoreItems(response.nextPage !== null);
      })
      .catch(() => {
        setFetchMoreError(true);
        setHasMoreItems(false);
      });
  };

  const handleCreateNewClick = () => {
    setIsConfirmUseWizardOpen( true)
  }

  return (
    <>
      <IonContent
        className="contentLightGrey contentMaxWidth"
        hidden={props.hidden}
      >
        <WizardModal isOpen={isWizardOpen} 
            customer={undefined} 
            onWizardCompleted={() => setIsWizardOpen( false)} 
            onCancel={() => setIsWizardOpen(false)}/>
        <IonAlert
          isOpen={isConfirmUseWizardOpen}
          onDidDismiss={() => setIsConfirmUseWizardOpen(false)}
          header={t("confirmUseWizardCustomerTitle")}
          message={t("confirmUseWizardCustomer")}
          buttons={[
            {
              text: t("no"),
              role: "no",
              handler: () => {
                props.history.push("/asiakas/uusi");
              },
            },
            {
              text: t("yes"),
              handler: () => {setIsWizardOpen(true)},//doDeactivate,
            },
          ]}
        />

        <div id="contentId" className="feed">
          <EsperiSpinner size={SpinnerSize.Large} hidden={!isLoading} />
          <ErrorView
            hidden={!isError}
            onTryAgain={() => {
              fetchCustomers();
            }}
          />
          <InfiniteScroll
            scrollableTarget="contentId"
            dataLength={customerState.customers.length}
            next={fetchMore}
            hasMore={hasMoreItems}
            loader={""}
          >
            <IonGrid className="feedList ion-padding">
              <IonListHeader className="itemListHeader">
                <IonToolbar className="headerToolbar">
                  <IonButton
                    className="buttonAddNew"
                    onClick={handleCreateNewClick}
                  >
                    {t("createNewCustomer")}
                  </IonButton>
                  <SearchMenu
                    onQueryChange={(q: string | undefined) => setQuery(q)}
                    onActiveOnlyChange={(activeOnly: boolean) =>
                      setActiveOnly(activeOnly)
                    }
                    isLoading={isLoading}
                  />
                </IonToolbar>
              </IonListHeader>
              <EmptyView hidden={!isEmpty} type={EmptyViewType.Customers} />
              <IonRow hidden={isEmpty} style={{ background: "white" }}>
                <IonCol
                  size="4"
                  className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <IonText className="body1Bold black ion-padding-bottom">
                    {t("customer")}
                  </IonText>
                </IonCol>
                <IonCol
                  size="4"
                  className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <IonText className="body1Bold black ion-padding-bottom">
                    {t("omaUser")}
                  </IonText>
                </IonCol>
                <IonCol
                  size="4"
                  className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <IonText className="body1Bold black ion-padding-bottom">
                    {t("home")}
                  </IonText>
                </IonCol>
              </IonRow>
              {customerState.customers.map((item: Customer) => {
                return (
                  <CustomerCell
                    key={item.id}
                    customer={item}
                    onClick={() => {
                      props.history.push("/asiakas/muokkaa/" + item.id);
                    }}
                  />
                );
              })}
              {renderFetchMoreError()}
            </IonGrid>
          </InfiniteScroll>
        </div>
      </IonContent>
    </>
  );
};

export default withRouter(withCustomerService(Customers));

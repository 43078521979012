import axios from "axios";
import React from "react";

import { HTTP_API_URL } from "util/Constants";
import { BaseService } from "./BaseService";
import {
  AdminActionTypes,
  AdminUser,
  AdminUserResponse,
  AdminUsersResponse,
  GetAdminUsersResponse,
  UpdateAdminUserResponse,
} from "../providers/Admin/AdminActionTypes";
import { AdminActions } from "../providers/Admin";
import { UserAppState } from "../providers/User/UserReducer";
import { PostSettingsRequest } from "./RegisterRequests";
import { UserActions } from "../providers/User";
import {
  PostActivateAdmin,
  PostAdminInvite,
  PostCreateAdmin,
  PostDeactivateAdmin,
  PostUpdateAdmin,
} from "./AdminRequests";
import { AdminAppState } from "../providers/Admin/AdminReducer";

export default class AdminService extends BaseService {
  /**
   * Using static field for debouncing the mark as read API call.
   */
  private static timeout: any | null = null;

  private readonly dispatch: React.Dispatch<AdminActionTypes>;

  constructor(state: UserAppState, dispatch: React.Dispatch<AdminActionTypes>) {
    super(state);
    this.dispatch = dispatch;
  }

  fetchHomes(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/homes");
      try {
        const response = await axios.get(
          url.toString(),
          this.addAuthorizationHeaders()
        );
        this.dispatch(AdminActions.onGetHomesResponse(response.data));
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  fetchAdmins(role: string, page: string = "0"): Promise<AdminUsersResponse> {
    return new Promise(async (resolve, reject) => {
      if (page === "0") {
        this.dispatch(AdminActions.clearAdmins());
      }

      const url = new URL(HTTP_API_URL + "/admins");
      url.searchParams.append("pagesize", "20");
      url.searchParams.append("page", page);
      url.searchParams.append("role", role);
      try {
        const response = await axios.get(
          url.toString(),
          this.addAuthorizationHeaders()
        );
        response.data.admins.sort((a: AdminUser, b: AdminUser) => {
          // @ts-ignore
          return new Date(a.lastName) - new Date(b.lastName);
        });
        this.dispatch(
          AdminActions.onGetAdminUsersResponse(response.data.admins)
        );
        resolve(response.data as AdminUsersResponse);
      } catch (e) {
        reject(e);
      }
    });
  }

  fetchAdmin(adminId: number): Promise<AdminUserResponse> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/admins/" + adminId);

      try {
        const response = await axios.get(
          url.toString(),
          this.addAuthorizationHeaders()
        );

        this.dispatch(AdminActions.onGetAdminUserResponse(response.data));
        resolve({ adminUser: response.data });
      } catch (e) {
        reject(e);
      }
    });
  }

  postCreateAdminUser(request: PostCreateAdmin): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/admins");
      try {
        const response = await axios.post(
          url.toString(),
          request.adminUser,
          this.addAuthorizationHeaders()
        );

        this.dispatch(AdminActions.onUpdatedAdminUserResponse(response.data));
        resolve(response.data);
      } catch (e) {
        reject(e);
      }
    });
  }

  postUpdateAdminUser(request: PostUpdateAdmin): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/admins/" + request.adminUser.id);
      try {
        const response = await axios.put(
          url.toString(),
          request.adminUser,
          this.addAuthorizationHeaders()
        );

        this.dispatch(AdminActions.onUpdatedAdminUserResponse(response.data));
        resolve(response.data);
      } catch (e) {
        reject(e);
      }
    });
  }

  postAdminInvite(request: PostAdminInvite): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(
        HTTP_API_URL + "/admins/" + request.adminUser.id + "/invite"
      );
      try {
        const response = await axios.post(
          url.toString(),
          request.adminUser,
          this.addAuthorizationHeaders()
        );

        this.dispatch(AdminActions.onInviteAdminResponse());
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }

  postDeactivateAdmin(request: PostDeactivateAdmin): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(
        HTTP_API_URL + "/admins/" + request.adminUser.id + "/deactivate"
      );
      try {
        const response = await axios.post(
          url.toString(),
          request.adminUser,
          this.addAuthorizationHeaders()
        );

        this.dispatch(AdminActions.onDeactivateAdminResponse(response.data));
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  postActivateAdmin(request: PostActivateAdmin): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(
        HTTP_API_URL + "/admins/" + request.adminUser.id + "/reactivate"
      );
      try {
        const response = await axios.post(
          url.toString(),
          request.adminUser,
          this.addAuthorizationHeaders()
        );

        this.dispatch(AdminActions.onActivateAdminResponse(response.data));
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }
}

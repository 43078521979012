import React, { useContext, useEffect, useState } from "react";
import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonListHeader,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";

import "./Employees.css";

import PageHeader from "components/PageHeader";
import { RouteComponentProps, withRouter } from "react-router";
import {
  withEmployeeService,
  WithEmployeeServiceProps,
} from "../../../service";
import {
  withErrorHandler,
  WithErrorHandlerProps,
  withInfoHandler,
  WithInfoHandlerProps,
} from "../../index";
import {
  Employee,
  EmployeeStatus,
} from "../../../providers/Employee/EmployeeActionTypes";
import { UserContext } from "../../../providers/User";
import { EmployeeContext } from "../../../providers/Employee";
import { useTranslation } from "react-i18next";
import { AdminContext } from "../../../providers/Admin";
import { HomePicker } from "../../../components/pickers";
import {
  AdminUserStatus,
  HomeItem,
} from "../../../providers/Admin/AdminActionTypes";
import {
  default as EsperiSpinner,
  SpinnerSize,
} from "../../../components/EsperiSpinner";
import { Formatter } from "../../../util/Formatter";
import { AdminUserRole } from "../../../providers/User/UserActionTypes";
import { isChanged, isValid } from "../../../util/Validator";

interface EmployeeDetailsProps
  extends WithEmployeeServiceProps,
    RouteComponentProps,
    WithErrorHandlerProps,
    WithInfoHandlerProps {}

const EmployeesDetailsPage = (props: EmployeeDetailsProps) => {
  const { t } = useTranslation();

  const { state } = useContext(UserContext);

  const [isLoading, setLoading] = useState(false);
  const [isLoadingPage, setLoadingPage] = useState(false);
  const [employee, setEmployee] = useState<Employee | undefined>(undefined);
  const [originalEmployee, setOriginalEmployee] = useState<
    Employee | undefined
  >(undefined);
  const [isNewUser, setIsNewUser] = useState<boolean>(false);
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [generateUsername, setGenerateUsername] = useState<boolean | undefined>(
    true
  );
  const [isConfirmNewPasswordOpen, setIsConfirmNewPasswordOpen] = useState<
    boolean
  >(false);

  const isActive = employee
    ? employee.status === EmployeeStatus.CREATED ||
      employee.status === EmployeeStatus.REGISTERED
    : false;

  const keys = ["firstName", "lastName", "role", "username", "homes"];
  const isEdited = isChanged(employee, originalEmployee, keys);
  const validEmployee = isValid(employee, keys);

  const loadEmployee = (employeeId: number) => {
    setLoading(true);
    props.employeeService
      .fetchEmployee(employeeId)
      .then((response) => {
        setEmployee(response.employee);
        setOriginalEmployee(response.employee);
        setPassword(undefined);
      })
      .catch((error) => {
        props.handleError(error);
      })
      .finally(() => {
        setLoading(false);
        setLoadingPage(false);
      });
  };

  console.log(props.match.url);
  useEffect(() => {
    debugger;
    // @ts-ignore
    if (props.match.params.id) {
      setLoadingPage(true);
      setIsNewUser(false);
      // @ts-ignore
      const employeeId: number = parseInt(props.match.params.id);
      loadEmployee(employeeId);
    } else {
      setPassword(undefined);
      setIsNewUser(true);
      setEmployee({ role: "ypa" } as Employee);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.url]);

  const editEmployee = (newState: {}) => {
    if (employee) {
      const newValue = { ...employee, ...newState };
      // @ts-ignore
      if (
        isNewUser &&
        generateUsername &&
        // @ts-ignore
        (newState.firstName || newState.lastName) &&
        newValue.firstName &&
        newValue.lastName
      ) {
        newValue.username =
          newValue.firstName.toLowerCase() +
          "." +
          newValue.lastName.toLowerCase();
      }

      setPassword(undefined);
      setEmployee(newValue);
    }
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    setLoading(true);
    props.employeeService
      // @ts-ignore
      .postUpdateEmployee({ employee })
      .then((response: any) => {
        setEmployee(response);
        setOriginalEmployee(response);
        setPassword(undefined);
        props.showInfo(t("profileSaved"));
      })
      .catch((error: any) => {
        props.handleError(error);
      })
      .finally(() => setLoading(false));
  };

  const handleCreate = (e: any) => {
    e.preventDefault();
    setLoading(true);
    props.employeeService
      // @ts-ignore
      .postCreateEmployee({ employee })
      .then((employee: any) => {
        setEmployee(employee);
        setOriginalEmployee(employee);
        setPassword(undefined);
        setIsNewUser(false);
      })
      .catch((error: any) => {
        props.handleError(error);
      })
      .finally(() => setLoading(false));
  };

  const handleActivate = (e: any) => {
    e.preventDefault();
    setLoading(true);
    props.employeeService
      // @ts-ignore
      .postActivateEmployee({ employee })
      .then((response: any) => {
        // @ts-ignore
        loadEmployee(employee.id);
        props.showInfo(t("employeeActivated"));
      })
      .catch((error: any) => {
        props.handleError(error);
      })
      .finally(() => setLoading(false));
  };

  const handleDeactivate = (e: any) => {
    e.preventDefault();
    setLoading(true);
    props.employeeService
      // @ts-ignore
      .postDeactivateEmployee({ employee })
      .then((response: any) => {
        // @ts-ignore
        loadEmployee(employee.id);
        props.showInfo(t("employeeDeactivated"));
      })
      .catch((error: any) => {
        props.handleError(error);
      })
      .finally(() => setLoading(false));
  };

  const generatePassword = () => {
    setLoading(true);
    props.employeeService
      // @ts-ignore
      .postPasswordReset({ employee })
      .then((response: any) => {
        setPassword(response.password);
      })
      .catch((error: any) => {
        props.handleError(error);
      })
      .finally(() => setLoading(false));
  };

  const handleGeneratePassword = (e: any) => {
    e.preventDefault();
    setIsConfirmNewPasswordOpen(true);
  };

  const renderUserDetails = () => {
    if (!employee) {
      return <></>;
    }

    return (
      <>
        <IonItemDivider>
          <IonLabel>{t("userInfo")}</IonLabel>
        </IonItemDivider>
        <IonItem lines="none">
          <IonLabel>{t("firstName")}</IonLabel>
          <IonInput
            value={employee.firstName}
            placeholder={t("firstNamePL")}
            required={true}
            onIonChange={(e: any) =>
              editEmployee({ firstName: (e.target as HTMLInputElement).value })
            }
          />
        </IonItem>
        <IonItem lines="none">
          <IonLabel>{t("lastName")}</IonLabel>
          <IonInput
            value={employee.lastName}
            placeholder={t("lastNamePL")}
            required={true}
            onIonChange={(e: any) =>
              editEmployee({ lastName: (e.target as HTMLInputElement).value })
            }
          />
        </IonItem>
        <IonItem lines="none">
          <IonLabel>{t("userName")}</IonLabel>
          <IonInput
            value={employee.username}
            placeholder={t("userNamePL")}
            readonly={!isNewUser}
            required={true}
            onKeyPress={(e: any) => setGenerateUsername(false)}
            onIonChange={(e: any) =>
              editEmployee({ username: (e.target as HTMLInputElement).value })
            }
          />
        </IonItem>
      </>
    );
  };

  const renderUserRoles = () => {
    if (!employee) {
      return <></>;
    }

    return (
      <>
        <IonItemDivider>
          <IonLabel>{t("role")}</IonLabel>
        </IonItemDivider>

        <IonRadioGroup
          value={employee.role}
          onIonChange={(e: any) =>
            editEmployee({ role: (e.target as HTMLInputElement).value })
          }
        >
          <IonItem lines="none">
            <IonLabel>{Formatter.roleLocalization("ypa", t)}</IonLabel>
            <IonRadio slot="start" value="ypa" />
          </IonItem>
          <IonItem lines="none">
            <IonLabel>{Formatter.roleLocalization("tiimari", t)}</IonLabel>
            <IonRadio slot="start" value="tiimari" />
          </IonItem>
          <IonItem lines="none">
            <IonLabel>{Formatter.roleLocalization("nurse", t)}</IonLabel>
            <IonRadio slot="start" value="hoitaja" />
          </IonItem>
        </IonRadioGroup>
      </>
    );
  };

  const renderUserHomes = () => {
    if (!employee) {
      return <></>;
    }

    const adminRole =
      state.role === AdminUserRole.ADMIN ||
      state.role === AdminUserRole.SUPERADMIN;
    return (
      <>
        <IonItemDivider>
          <IonLabel>{t("selectEmployeeHome")}</IonLabel>
        </IonItemDivider>
        <HomePicker
          activeIds={employee.homes}
          adminRole={adminRole}
          onChange={(selected) =>
            editEmployee({ ...employee, homes: selected })
          }
        />
      </>
    );
  };

  const renderEmployeeState = () => {
    if (!employee || !employee.status) {
      return <></>;
    }

    const statusTextLocalization = (status: string): string => {
      const lc = status.toLowerCase();
      const keyCapitalized = lc.charAt(0).toUpperCase() + lc.slice(1);
      const key = `adminLongStatus${keyCapitalized}`;
      return t(key);
    };

    return (
      <>
        <IonItemDivider>
          <IonLabel>{t("Käyttäjän tila")}</IonLabel>
        </IonItemDivider>
        <IonItem lines="none">
          <IonLabel style={{ "font-weight": "normal" }}>
            {statusTextLocalization(employee.status)}
          </IonLabel>
          {(employee.status === EmployeeStatus.CREATED ||
            employee.status === EmployeeStatus.REGISTERED) && (
            <IonButton slot="end" onClick={handleDeactivate}>
              {t("deactivateEmployee")}
            </IonButton>
          )}
          {employee.status === EmployeeStatus.DEACTIVATED && (
            <IonButton slot="end" onClick={handleActivate}>
              {t("activateEmployee")}
            </IonButton>
          )}
        </IonItem>
      </>
    );
  };

  const renderPassword = () => {
    if (!employee) {
      return <></>;
    }

    return (
      <>
        <IonItemDivider>
          <IonLabel>{t("identification")}</IonLabel>
        </IonItemDivider>
        <IonItem lines="none">
          <IonLabel className="ion-text-wrap" hidden={!!isActive}>
            {t("passwordNotActive")}
          </IonLabel>
          <IonLabel className="ion-text-wrap" hidden={!isActive}>
            {t("password")}
          </IonLabel>
          <IonInput
            hidden={!isActive}
            value={password ? password : "************"}
            placeholder="************"
            type={password ? "text" : "password"}
            readonly={true}
          />
          <IonButton
            onClick={handleGeneratePassword}
            hidden={!isActive}
            slot="end"
          >
            {t("generatePassword")}
          </IonButton>
        </IonItem>
      </>
    );
  };

  if (!employee) {
    return <></>;
  }

  return (
    <IonPage className="pageLightGrey calendarEventDetails">
      <PageHeader
        title={employee.username ? employee.username : t("createEmployee")}
      />
      <IonContent>
        <EsperiSpinner size={SpinnerSize.Large} hidden={!isLoading} />
        <IonAlert
          isOpen={isConfirmNewPasswordOpen}
          onDidDismiss={() => setIsConfirmNewPasswordOpen(false)}
          header={t("confirmNewPasswordHeader")}
          message={t("confirmNewPassword")}
          buttons={[
            {
              text: t("cancel"),
              role: "cancel",
              handler: () => {},
            },
            {
              text: t("OK"),
              handler: generatePassword,
            },
          ]}
        />
        <IonGrid>
          <IonRow hidden={isLoadingPage}>
            <IonCol size="6">
              <div className="AdminFormContainer">{renderUserDetails()}</div>
              {!isNewUser && (
                <div className="AdminFormContainer">{renderPassword()}</div>
              )}
            </IonCol>
            <IonCol size="6">
              <div className="AdminFormContainer">{renderUserRoles()}</div>
              <div className="AdminFormContainer">{renderUserHomes()}</div>
            </IonCol>
          </IonRow>
          <IonRow className="AdminFormContainer" hidden={isLoadingPage}>
            <IonCol>
              <IonItem lines="none" class="ion-text-end">
                {isNewUser && (
                  <IonButton
                    slot="end"
                    onClick={handleCreate}
                    disabled={!validEmployee}
                  >
                    {t("newEmployee")}
                  </IonButton>
                )}
                {(employee.status === EmployeeStatus.CREATED ||
                  employee.status === EmployeeStatus.REGISTERED) && (
                  <IonButton slot="end" onClick={handleDeactivate}>
                    {t("deactivateEmployee")}
                  </IonButton>
                )}
                {employee.status === EmployeeStatus.DEACTIVATED && (
                  <IonButton slot="end" onClick={handleActivate}>
                    {t("activateEmployee")}
                  </IonButton>
                )}
                {!isNewUser && (
                  <IonButton
                    slot="end"
                    onClick={handleSave}
                    disabled={!isEdited}
                  >
                    {t("save")}
                  </IonButton>
                )}
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(
  withEmployeeService(withErrorHandler(withInfoHandler(EmployeesDetailsPage)))
);

import { UserSession } from "service/UserService";
import { Image } from "service";

export enum ActionType {
  Initialized = "Initialized",
  GetConfirmInvitationResponse = "GetConfirmInvitationResponse",
  PostConfirmInvitationResponse = "PostConfirmInvitationResponse",
  PostConfirmCodeResponse = "PostConfirmCodeResponse",
  PostSignupResponse = "PostSignupResponse",
  ConsentsResponse = "ConsentsResponse",
  PostSigninResponse = "PostSigninResponse",
  RecoveryEmailSent = "RecoveryEmailSent",
  SignedOut = "SignedOut",
  SettingsResponse = "SettingsResponse",
  UserProfileResponse = "UserProfileResponse",
  GetHomesResponse = "GetHomesResponse",
}

export interface Initialized {
  type: ActionType.Initialized;
  session: UserSession | null;
  initialized: boolean;
}

export interface GetConfirmInvitationResponse {
  type: ActionType.GetConfirmInvitationResponse;
  invitationToken: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  role: string;
  homes: HomeItem[];
}

export interface PostConfirmInvitationResponse {
  type: ActionType.PostConfirmInvitationResponse;
  confirmationToken: string;
}

export interface PostConfirmCodeResponse {
  type: ActionType.PostConfirmCodeResponse;
  registrationToken: string;
}

export interface PostSignupResponse {
  type: ActionType.PostSignupResponse;
  IdToken: string;
  RefreshToken: string;
  AccessToken: string;
  ClientId: string;
  UserPoolId: string;
  business: string;
}

export interface ConsentsResponse {
  type: ActionType.ConsentsResponse;
  consents: ConsentItem[];
}

export interface PostSigninResponse {
  type: ActionType.PostSigninResponse;
  IdToken: string;
  RefreshToken: string;
  AccessToken: string;
  ClientId: string;
  UserPoolId: string;
  business: string;
}

export interface RecoveryEmailSent {
  type: ActionType.RecoveryEmailSent;
  recoveryEmail: string;
}

export interface SignedOut {
  type: ActionType.SignedOut;
}

export interface SettingsResponse {
  type: ActionType.SettingsResponse;
  settings: SettingItem[];
}

export interface UserProfileResponse {
  type: ActionType.UserProfileResponse;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  role: string;
  homes: HomeItem[];
}

export type UserActionTypes =
  | Initialized
  | GetConfirmInvitationResponse
  | PostConfirmInvitationResponse
  | PostConfirmCodeResponse
  | PostSignupResponse
  | ConsentsResponse
  | PostSigninResponse
  | RecoveryEmailSent
  | SignedOut
  | SettingsResponse
  | UserProfileResponse;

/*
 MODEL
 */

export enum AdminUserRole {
  SUPERADMIN = "superadmin",
  ADMIN = "admin",
  YPA = "ypa",
}

export interface ConsentItem {
  id: number;
  title: string;
  description: string;
  descriptionHighlights: string[];
  checkbox: string;
  type: string;
}

export interface SettingItem {
  id: number;
  name: string;
  type: string;
  value: string | null;
  required: boolean;
}

export interface HomeItem {
  id: number;
  name: string;
  description: string;
  images: Image[];
  contactPerson: ContactPerson;
  contactsBody: string | null;
  attachments: Attachment[];
}

export interface ContactPerson {
  name: string;
  title: string | null;
  telephone: string | null;
  email: string | null;
}

export interface Attachment {
  name: string;
  iconUrl: string;
  url: string;
}

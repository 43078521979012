import React, { useContext, useEffect, useState } from "react";
import { IonButton, IonContent, IonLabel, IonPage } from "@ionic/react";
import { MainTab } from "providers/UI";
import { UIContext } from "providers/UI/UIAppState";
import { UIActions } from "providers/UI/UIActions";

import "./MainPage.css";
import EsperiAppHeader, { HeaderLeftIcon } from "components/EsperiAppHeader";
import { withUserService, WithUserServiceProps } from "service";
import { RouteComponentProps, useLocation } from "react-router";
import { UserUtil } from "util/UserUtil";
import { useTranslation } from "react-i18next";
import PlaceholderContent from "../PlaceholderContent";
import { SessionUtils } from "util/SessionUtils";
import { UserActions, UserContext } from "providers/User";
import Admins from "./Admins/Admins";
import Employees from "./Employees/Employees";
import Devices from "./Devices/Devices";
import OmaUsers from "./OmaUsers/OmaUsers";
import Customers from "./Customers/Customers"
import CustomerNews from "./CustomerNews/CustomerNews";
import WizardModal from "components/WizardModal";

const MainPage = (props: WithUserServiceProps & RouteComponentProps) => {
  const { state, dispatch } = useContext(UIContext);
  const { dispatch: userDispatch } = useContext(UserContext);
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    props.userService
      .fetchConsents()
      .then((response) => {
        if (response.data.consents?.length > 0) {
          props.history.push("/ehdot", {
            consent: response.data.consents[0],
          });
        }
      })
      .catch((error) => {
        // TODO How to handle?
      });

    props.userService
      .fetchProfile()
      .then(() => {
        // no-op
      })
      .catch((e) => {
        // TODO FIND GENERIC SOLUTION FOR THIS
        if (e?.response?.status === 401) {
          SessionUtils.clearSession(true);
          userDispatch(UserActions.onSignedOut());
          props.history.replace("/");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonPage className="pageWhite">
      <EsperiAppHeader
        hideLeftIcon={state.isSplitPaneVisible}
        hideEsperiLogo={state.isSplitPaneVisible}
        hideRightIcon={false}
        leftIconType={HeaderLeftIcon.Menu}
      />
      <>
        {state.selectedMainTab === MainTab.AdminsTab && (
          <Admins hidden={false} />
        )}
        {state.selectedMainTab === MainTab.EmployeesTab && (
          <Employees hidden={false} />
        )}
        {state.selectedMainTab === MainTab.DevicesTab && (
          <Devices hidden={false} />
        )}
        {state.selectedMainTab === MainTab.OmaUsersTab && (
          <OmaUsers hidden={false} />
        )}
        {state.selectedMainTab === MainTab.CustomersTab && (
          <Customers hidden={false} />
        )}
        {state.selectedMainTab === MainTab.CustomersNewsTab && (
          <CustomerNews hidden={false} />
        )}
      </>
    </IonPage>
  );
};

export default withUserService(MainPage);

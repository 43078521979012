import { ActionType, OmaUser, OmaUserActionTypes } from "./OmaUserActionTypes";

export class OmaUserActions {
  static clearOmaUsers(): OmaUserActionTypes {
    return {
      type: ActionType.ClearOmaUsers,
    };
  }

  static onGetOmaUsersResponse(omaUsers: OmaUser[]): OmaUserActionTypes {
    return {
      type: ActionType.GetOmaUsersResponse,
      omaUsers: omaUsers,
    };
  }

  static onGetOmaUserResponse(omaUser: OmaUser): OmaUserActionTypes {
    return {
      type: ActionType.GetOmaUserResponse,
      omaUser: omaUser,
    };
  }

  static onDeactivateOmaUserResponse(omaUser: OmaUser): OmaUserActionTypes {
    return {
      type: ActionType.PostDeactivateOmaUserResponse,
      omaUser: omaUser,
    };
  }

  static onReactivateOmaUserResponse(omaUser: OmaUser): OmaUserActionTypes {
    return {
      type: ActionType.PostReactivateOmaUserResponse,
      omaUser: omaUser,
    };
  }

  static onInviteOmaUserResponse(omaUser: OmaUser): OmaUserActionTypes {
    return {
      type: ActionType.PostInviteOmaUserResponse,
      omaUser: omaUser,
    };
  }

  static onUpdatedOmaUserResponse(omaUser: OmaUser): OmaUserActionTypes {
    return {
      type: ActionType.PostUpdateOmaUserResponse,
      omaUser: omaUser,
    };
  }
}

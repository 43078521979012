import React, { useState } from "react";
import { IonCol, IonImg, IonItem, IonRow, IonText } from "@ionic/react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { withAdminService, WithAdminServiceProps } from "service";
import "../MainPage.css";
import { AdminUser } from "../../../providers/Admin/AdminActionTypes";
import { Formatter } from "../../../util/Formatter";

interface AdminCellProps extends WithAdminServiceProps {
  adminUser: AdminUser;
  onClick: () => void;
}

const AdminCell = (props: AdminCellProps) => {
  const { t } = useTranslation();

  return (
    <div
      className="AdminCell"
      onClick={(e) => {
        e.preventDefault();
        props.onClick();
      }}
    >
      <IonRow style={{ background: "white" }}>
        <IonCol
          size="4"
          className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <IonText className="body2 grey ion-padding-bottom">
            {`${props.adminUser.lastName}, ${props.adminUser.firstName}`}
          </IonText>
        </IonCol>
        <IonCol
          size="4"
          className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <IonText className="body2 grey ion-padding-bottom">
            {Formatter.roleLocalization(props.adminUser.role, t)}
          </IonText>
        </IonCol>
        <IonCol
          size="4"
          className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <IonText className="body2 grey ion-padding-bottom">
            {Formatter.statusLocalization(props.adminUser.status, t)}
          </IonText>
        </IonCol>
      </IonRow>
    </div>
  );
};

export default withAdminService(AdminCell);

import React, { useState } from "react";
import { IonCol, IonImg, IonItem, IonRow, IonText } from "@ionic/react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  withAdminService,
  WithAdminServiceProps,
  withEmployeeService,
  WithEmployeeServiceProps,
} from "service";
import "../MainPage.css";
import { AdminUser } from "../../../providers/Admin/AdminActionTypes";
import { Employee } from "../../../providers/Employee/EmployeeActionTypes";
import { Formatter } from "../../../util/Formatter";

interface EmployeeCellProps extends WithEmployeeServiceProps {
  employee: Employee;
  onClick: () => void;
}

const EmployeeCell = (props: EmployeeCellProps) => {
  const { t } = useTranslation();

  return (
    <div
      className="EmployeeCell"
      onClick={(e) => {
        e.preventDefault();
        props.onClick();
      }}
    >
      <IonRow style={{ background: "white" }}>
        <IonCol
          size="4"
          className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <IonText className="body2 grey ion-padding-bottom">
            {`${props.employee.lastName}, ${props.employee.firstName}`}
          </IonText>
        </IonCol>
        <IonCol
          size="4"
          className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <IonText className="body2 grey ion-padding-bottom">
            {Formatter.roleLocalization(props.employee.role, t)}
          </IonText>
        </IonCol>
        <IonCol
          size="4"
          className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <IonText className="body2 grey ion-padding-bottom">
            {Formatter.employeeStatusLocalization(props.employee.status, t)}
          </IonText>
        </IonCol>
      </IonRow>
    </div>
  );
};

export default withEmployeeService(EmployeeCell);

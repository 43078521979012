import React, { useRef, useState } from "react";
import {
  IonButton,
  IonContent,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  useIonViewWillEnter,
} from "@ionic/react";

import EsperiHeaderWithoutNavigation from "components/EsperiHeaderWithoutNavigation";
import EsperiRegisterCheckBox from "components/EsperiRegisterCheckBox";
import { withUserService, WithUserServiceProps } from "service";
import { RouteComponentProps } from "react-router";
import { Formatter } from "util/Formatter";
import { UserUtil } from "util/UserUtil";
import { ConsentItem } from "providers/User/UserActionTypes";
import { useTranslation } from "react-i18next";
import { withErrorHandler, WithErrorHandlerProps } from "../index";

const ConsentPage = (
  props: WithUserServiceProps & RouteComponentProps & WithErrorHandlerProps
) => {
  const contentRef = useRef<HTMLIonContentElement>(null);
  const [isSending, setIsSending] = useState(false);
  const [isCheckBoxChecked, setCheckBoxChecked] = useState(false);
  const { t } = useTranslation();

  useIonViewWillEnter(() => {
    contentRef.current?.getScrollElement().then((scrollElement) => {
      scrollElement.scrollTo(0, 0);
    });
  }, []);

  function getConsent(): ConsentItem | null {
    // @ts-ignore
    return props.location.state?.consent;
  }

  function onButtonClicked(e: any) {
    e.preventDefault();
    setIsSending(true);

    props.userService
      .approveConsent(getId())
      .then((response) => {
        if (response.data.consents?.length > 0) {
          setIsSending(false);
          props.history.push("/ehdot", {
            consent: response.data.consents[0],
          });
          setCheckBoxChecked(false);
        } else {
          setIsSending(false);
          props.history.replace("/etusivu");
        }
      })
      .catch((error) => {
        setIsSending(false);
        props.handleError(error);
      });
  }

  function getId(): number {
    return getConsent()?.id || -1;
  }

  function getTitle() {
    return getConsent()?.title || "";
  }

  function getDescription() {
    const consent = getConsent();
    if (consent) {
      let description = consent.description;
      description = Formatter.boldParts(
        description,
        consent.descriptionHighlights
      );
      return description;
    }
    return "";
  }

  function getCheckBox() {
    return getConsent()?.checkbox || "";
  }

  const description = getDescription();

  return (
    <IonPage className="pageWhite pageWidth centered">
      <IonLoading isOpen={isSending} message={t("registeringAnswer")} />
      <IonContent ref={contentRef} className="ion-padding-horizontal">
        <EsperiHeaderWithoutNavigation />
        <IonItem className="title centered theme" lines="none">
          <IonLabel>{getTitle()}</IonLabel>
        </IonItem>
        <div className="marginTop10px" />
        <IonItem className="body1 grey" lines="none">
          <p
            className={description.length < 100 ? "centered" : ""}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </IonItem>
      </IonContent>
      <div className="marginTop30px" />
      <EsperiRegisterCheckBox
        title={getCheckBox()}
        isChecked={isCheckBoxChecked}
        onCheckedChanged={(checked: boolean) => {
          setCheckBoxChecked(checked);
        }}
      />
      <IonItem>
        <IonButton
          className="buttonRed buttonBottomConsent"
          expand="block"
          onClick={(e) => onButtonClicked(e)}
          disabled={!isCheckBoxChecked}
        >
          {t("continue")}
        </IonButton>
      </IonItem>
    </IonPage>
  );
};

export default withUserService(withErrorHandler(ConsentPage));

import React, { useContext, useState } from "react";

import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonSearchbar,
} from "@ionic/react";
import { HomeItem } from "../../providers/Admin/AdminActionTypes";
import { useTranslation } from "react-i18next";
import { withAdminService, WithAdminServiceProps } from "../../service";
import { AdminContext } from "../../providers/Admin";
import "./Picker.css";
import { UserContext } from "../../providers/User";

interface HomePickerProps extends WithAdminServiceProps {
  homes?: HomeItem[] | undefined;
  activeIds?: HomeItem[] | undefined;
  onChange?: (selected: HomeItem[] | HomeItem) => void;
  adminRole?: boolean | undefined;
  singleSelect?: boolean;
}

const HomePicker = ({homes, activeIds, onChange, adminRole, singleSelect = false}: HomePickerProps) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [openModeIds, setOpenModeIds] = useState<HomeItem[]>([]);
  const { state: adminState } = useContext(AdminContext);
  const { state: userState } = useContext(UserContext);

  const handleOk = () => {
    if (onChange) {
      if(singleSelect) {
        if(openModeIds[0]) onChange(openModeIds[0])
      }
      else onChange(openModeIds);
    }
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const renderOpenMode = () => {
    const defaultHomes = adminRole ? adminState.homes : userState.homes;
    const homesSelected = homes ? homes : defaultHomes;

    const handleCheckboxClick = (id: number, value: boolean) => {
      if (!value) {
        setOpenModeIds(openModeIds.filter((i) => i.id !== id));
      } else {
        if (!openModeIds.find((i) => i.id === id)) {
          const home: HomeItem | undefined = homesSelected.find((i) => i.id === id);
          if (home) {
            setOpenModeIds([...openModeIds, { ...home }]);
          }
        }
      }
    };

    const handleRadioButtonClick = (id: number) => {
      const home: HomeItem | undefined = homesSelected.find((i) => i.id === id);
      if(home)
        setOpenModeIds([home]);
    }

    const Checkboxes = 
      homesSelected.filter((h) => searchText ? h.name.search(new RegExp(searchText, "i")) >= 0 : true)
      .map((h, i) => {
        const checked = openModeIds
          ? !!openModeIds.find((i) => h.id === i.id)
          : false;
        return (
          <IonItem
          key={i}
          >
            <IonCheckbox
              className="registerCheckBox"
              checked={checked}
              value={h.id.toString()}
              onIonChange={(e: any) =>
                handleCheckboxClick(e.detail.value, e.detail.checked)
              }
            />
            <IonLabel style={{ "marginLeft": "10px" }}>{h.name}</IonLabel>
          </IonItem>
        );
      });

    const RadioButtons =
      <IonRadioGroup value={openModeIds[0]?.id.toString()} onIonChange={e => handleRadioButtonClick(e.detail.value)}>
        {homesSelected.filter((h) => searchText ? h.name.search(new RegExp(searchText, "i")) >= 0 : true)
        .map((h, i) => {
          return (
            <IonItem
            key={i}
            >
                <IonRadio style={{"marginInlineEnd": "0"}} slot="start" value={h.id.toString()} />
                <IonLabel style={{ "marginLeft": "10px" }}>{h.name}</IonLabel>
            </IonItem>
          );
        })}
      </IonRadioGroup>

    return (
      <IonModal
        isOpen={isOpen}
        cssClass="pickerModal"
        showBackdrop={false}
        onDidDismiss={() => { setIsOpen(false); }}
      >
        <IonSearchbar
          value={searchText}
          onIonChange={(e: any) => setSearchText(e.detail.value!)}
        />
        <IonContent>
          {!singleSelect ? 
            <IonList lines="none">
              {Checkboxes}
            </IonList> 
            :
            <IonList lines="none">
              {RadioButtons}
            </IonList> 
          }
        </IonContent>
        <IonItem lines="none" class="ion-text-end">
          <IonButton onClick={handleOk}>{t("ok")}</IonButton>
          <IonButton onClick={handleCancel}>{t("cancel")}</IonButton>
        </IonItem>
      </IonModal>
    );
  };

  const renderListMode = () => {
    const handleOpen = () => {
      setIsOpen(true);
      setOpenModeIds(
        activeIds ? activeIds.map((i) => ({ ...i })) : []
      );
    };

    const renderActiveHomes = (homes: HomeItem[]) => {
      return homes.map((h) => {
        return (
          <IonList key={h.id} onClick={handleOpen}>
            <IonItem lines="none">
              <IonLabel>{h.name}</IonLabel>
            </IonItem>
          </IonList>
        );
      });
    };

    return (
      <IonList>
        {activeIds &&
          activeIds.length > 0 &&
          renderActiveHomes(activeIds)}
        {!(activeIds && activeIds.length > 0) && (
          <IonItem lines="none">
            <IonLabel onClick={handleOpen}>{t("pickHomesEmpty")}</IonLabel>
          </IonItem>
        )}
      </IonList>
    );
  };

  return (
    <>
      {renderListMode()}
      {isOpen && renderOpenMode()}
    </>
  );
};

export default withAdminService(HomePicker);

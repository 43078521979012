import React, { useContext, useState } from "react";
import {
  IonPage,
  IonContent,
  IonItem,
  IonLabel,
  IonButton,
  IonLoading,
} from "@ionic/react";
import { UserContext } from "providers/User";

import "./Register.css";

import EsperiHeaderWithoutNavigation from "components/EsperiHeaderWithoutNavigation";
import EsperiRegisterCheckBox from "components/EsperiRegisterCheckBox";
import { withUserService, WithUserServiceProps } from "service";
import { RouteComponentProps } from "react-router";
import { UserUtil } from "util/UserUtil";
import { useTranslation } from "react-i18next";
import { withErrorHandler, WithErrorHandlerProps } from "../index";

interface RegisterEmailNotificationsProps
  extends RouteComponentProps,
  WithUserServiceProps,
  WithErrorHandlerProps { }

const RegisterEmailNotifications = (props: RegisterEmailNotificationsProps) => {
  const { state } = useContext(UserContext);
  const [isSending, setIsSending] = useState(false);
  const [isCheckBoxChecked, setCheckBoxChecked] = useState(false);
  const { t } = useTranslation();

  function emailNotificationsChecked(e: any) {
    e.preventDefault();

    setIsSending(true);
    const settings = UserUtil.setEmailNotificationsEnabled(
      isCheckBoxChecked,
      state.settings
    );
    props.userService
      .saveSettings({ settings })
      .then(() => {
        setIsSending(false);
        props.history.replace("/etusivu");
      })
      .catch((error) => {
        setIsSending(false);
        props.handleError(error);
      });
  }

  return (
    <IonPage className="pageWhite pageWidth centered">
      <IonLoading isOpen={isSending} message={t("registeringAnswer")} />
      <IonContent className="contentPaddingHorizontal">
        <EsperiHeaderWithoutNavigation />
        <IonItem className="title centered theme" lines="none">
          <IonLabel>{t("emailNotifications")}</IonLabel>
        </IonItem>
        <IonItem className="body1 centered grey" lines="none">
          <IonLabel className="ion-text-wrap">
            {t("emailNotificationsInfo")}
          </IonLabel>
        </IonItem>
        <IonItem lines="none" />
        <EsperiRegisterCheckBox
          isChecked={isCheckBoxChecked}
          title={t("allowNotifications")}
          onCheckedChanged={(checked: boolean) => setCheckBoxChecked(checked)}
        />
      </IonContent>
      <IonItem>
        <IonButton
          className="buttonGreen buttonBottom"
          onClick={(e) => emailNotificationsChecked(e)}
        >
          {t("done")}
        </IonButton>
      </IonItem>
    </IonPage>
  );
};

export default withUserService(withErrorHandler(RegisterEmailNotifications));

import React, { useContext, useEffect, useState, useRef } from "react";
import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonModal,
  IonSearchbar,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import "./Picker.css";
import { Customer } from "providers/Customer/CustomerActionTypes";
import { CustomerContext } from "providers/Customer";
import { withCustomerService, WithCustomerServiceProps } from "service";
import { UIContext } from "providers/UI/UIAppState";
import { IonSpinner } from '@ionic/react';
import InfiniteScroll from "react-infinite-scroll-component";
import LoadMoreView from "components/LoadMoreView";
import ErrorView from "components/ErrorView";
import ErrorViewSmall from "components/ErrorViewSmall";


interface CustomerPickerProps extends WithCustomerServiceProps {
  activeCustomers?: Customer[] | undefined;
  onChange?: (selected: Customer[]) => void;
  adminRole?: boolean | undefined;
}

const CustomerPicker = ({ activeCustomers, onChange, adminRole, customerService }: CustomerPickerProps) => {
  const { t } = useTranslation();
  const { state: uiState } = useContext(UIContext);

  const [isOpen, setIsOpen] = useState(false);
  const [openModeIds, setOpenModeIds] = useState<Customer[]>([]);
  const { state: customerState } = useContext(CustomerContext);
  const [query, setQuery] = useState<string | undefined>(undefined);


  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [activeOnly, setActiveOnly] = useState<boolean>(false);
  const [nextPage, setNextPage] = useState<string>("0");
  const [hasMoreItems, setHasMoreItems] = useState<boolean>(false);
  const [isFetchMoreError, setFetchMoreError] = useState(false);

   // Ref for resetting focus on searchbar, when customerPicker is open
   const inputRef = useRef<any>(null);

   // UseEffect which will make sure that focus is reset on
   // search bar when customerPicker modal is open
   useEffect(() => {
     if (isOpen) {
       setTimeout(() => inputRef.current.setFocus(), 100);
     }
   })

  const handleOk = () => {
    if (onChange) {
      onChange(openModeIds);
    }
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    fetchCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  function fetchCustomers() {
    setLoading(true);
    setError(false);
    customerService
      .fetchCustomers("0", uiState.activeHome, activeOnly, query)
      .then((response) => {
        setLoading(false);
        setNextPage(response.nextPage);
        setHasMoreItems(response.nextPage !== null);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        setHasMoreItems(false);
      });
  }

  const fetchMore = () => {
    console.log('*** fetchMore');
    setFetchMoreError(false);
    setHasMoreItems(false);
    customerService
      .fetchCustomers(nextPage, uiState.activeHome, activeOnly, query)
      .then((response) => {
        setNextPage(response.nextPage);
        setHasMoreItems(response.nextPage !== null);
      })
      .catch(() => {
        setFetchMoreError(true);
        setHasMoreItems(false);
      });
  };

  // const addNewCustomer = () => {
  //   console.log('*** addNewCustomer');
  // }

  const renderOpenMode = () => {
    const allCustomers = adminRole ? customerState.customers : [];

    const handleCheckboxClick = (id: number, value: boolean) => {
      if (!value) {
        setOpenModeIds(openModeIds.filter((i) => i.id.toString() !== id.toString()));
      } else {
        if (!openModeIds.find((i) => i.id === id)) {
          const user: Customer | undefined = allCustomers.find((i) => i.id.toString() === id.toString());
          if (user) {
            setOpenModeIds([...openModeIds, { ...user }]);
          }
        }
      }
    };

    const createCheckbox = (checked: boolean, value: string, name: string) => {
      return (
        <IonItem key={value}>
          <IonCheckbox
            className="registerCheckBox"
            checked={checked}
            value={value}
            onIonChange={(e: any) =>
              handleCheckboxClick(e.detail.value, e.detail.checked)
            }
          />
          <IonLabel style={{ "marginLeft": "10px" }}>{name}</IonLabel>
        </IonItem>
      )
    }

    const Checkboxes =
      <IonList lines="none">
        {
          allCustomers.map((user: Customer) => {
            const checked = openModeIds ? !!openModeIds.find((i) => user.id === i.id) : false;
            const value = user.id.toString();
            const name = `${user.lastName}, ${user.firstName}`;
            return createCheckbox(checked, value, name)
          })
        }
      </IonList>
    ;

    return (
      <IonModal
        isOpen={isOpen}
        cssClass="pickerModal"
        showBackdrop={false}
        onDidDismiss={() => { setIsOpen(false); }}
      >
        <IonSearchbar
          value={query}
          debounce={1000}
          disabled={isLoading}
          onIonChange={(e: any) => setQuery(e.detail.value!)}
          ref={(ref) => inputRef.current = ref}
        />
        <IonContent id="scrollable">
          <IonSpinner
            color="primary"
            name="crescent"
            className="pickerSpinner"
            hidden={!isLoading}
          />
          <ErrorView
            hidden={!isError}
            onTryAgain={() => {
              fetchCustomers();
            }}
          />
          <InfiniteScroll
            scrollableTarget="scrollable"
            dataLength={customerState.customers.length}
            next={fetchMore}
            hasMore={hasMoreItems}
            loader={""}
          >
            {Checkboxes}
            {isFetchMoreError &&
              <ErrorViewSmall
                title={t("adminUsersLoadingMoreError")}
                onTryAgain={() => {
                  setFetchMoreError(false);
                  setHasMoreItems(true);
                }}
              />
            }
          </InfiniteScroll>
        </IonContent>
        <IonItem lines="none" class="ion-text-end">
          <IonButton onClick={handleOk}>{t("OK")}</IonButton>
          <IonButton onClick={handleCancel}>{t("cancel")}</IonButton>
        </IonItem>
      </IonModal>
    );
  };

  const renderListMode = () => {
    const handleOpen = () => {
      setIsOpen(true);
      setOpenModeIds(
        activeCustomers ? activeCustomers.map((i) => ({ ...i })) : []
      );
    };

    const renderActiveCustomers = (customers: Customer[]) => {
      return customers.map((customer, i) => {
        return (
          <IonList key={i} onClick={handleOpen}>
            <IonItem lines="none">
              <IonLabel>{`${customer.firstName} ${customer.lastName}`}</IonLabel>
            </IonItem>
          </IonList>
        );
      });
    };

    return (
      <>
        <IonItemDivider>
          <IonLabel>
            {t("omaUserCustomers")}
          </IonLabel>
          {/* <IonButton
          color="red"
          slot="end"
          size="small"
          fill="clear"
          onClick={addNewCustomer}
        >
          <IonIcon icon={"/assets/ic_user.svg"} />
        </IonButton> */}
        </IonItemDivider>
        <IonList>
          {activeCustomers &&
            activeCustomers.length > 0 &&
            renderActiveCustomers(activeCustomers)}
          {!(activeCustomers && activeCustomers.length > 0) && (
            <IonItem lines="none">
              <IonLabel onClick={handleOpen}>{t("pickCustomersEmpty")}</IonLabel>
            </IonItem>
          )}
        </IonList>
      </>
    );
  };

  return (
    <>
      {renderListMode()}
      {isOpen && renderOpenMode()}
    </>
  );
};

export default withCustomerService(CustomerPicker);

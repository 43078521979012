import { ActionType, CustomerNews, CustomerNewsActionTypes } from "./CustomerNewsActionTypes";

export interface CustomerNewsAppState {
  customerNews: CustomerNews[];
  nextPage: string | undefined;
  unreadCount: number | undefined;
}

export const initialState: CustomerNewsAppState = {
  customerNews: [],
  nextPage: undefined,
  unreadCount: undefined
};

export const customerNewsReducer = (
  state: CustomerNewsAppState,
  action: CustomerNewsActionTypes
) => {
  switch (action.type) {
    case ActionType.ClearCustomerNews: 
      return { ...state, customerNews: [] };
    case ActionType.GetCustomerNewsResponse:
      return { ...state, customerNews: [...state.customerNews, ...action.customerNews], 
        nextPage: action.nextPage, unreadCount: action.unreadCount };
    case ActionType.UpdateCustomerNewsResponse:
    case ActionType.UpdateCustomerNewsResponse:
    case ActionType.GetCustomerNewsDetailsResponse:
      const index = state.customerNews.findIndex(
        (a) => a.id === action.customerNewsDetails.id
      );
      const customers =
        index >= 0
          ? [
              ...state.customerNews.slice(0, index),
              action.customerNewsDetails,
              ...state.customerNews.slice(index + 1),
            ]
          : [...state.customerNews, action.customerNewsDetails];

      return { ...state, customers };
    default:
      return { ...state };
  }
};

import React, { useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonContent,
  IonItem,
  IonLoading,
  IonMenu,
  IonPage,
  IonText,
  isPlatform,
} from "@ionic/react";

import "./SideMenu.css";

import { RouteComponentProps, useLocation, withRouter } from "react-router";
import { UIContext } from "providers/UI/UIAppState";
import { MainTab } from "providers/UI";
import { UIActions } from "providers/UI/UIActions";
import {
  withAdminService,
  WithAdminServiceProps,
  withUserService,
  WithUserServiceProps,
} from "service";
import { UserContext } from "providers/User";
import EsperiAppHeader, { HeaderLeftIcon } from "components/EsperiAppHeader";
import { useTranslation } from "react-i18next";
import { AdminContext } from "../../providers/Admin";
import { HomeItem } from "../../providers/Admin/AdminActionTypes";
import { AdminUserRole } from "providers/User/UserActionTypes";

const SideMenu = (
  props: RouteComponentProps & WithUserServiceProps & WithAdminServiceProps
) => {
  const { state: uiState, dispatch: uiDispatch } = useContext(UIContext);
  const { state: adminState } = useContext(AdminContext);
  const { state } = useContext(UserContext);
  const [loadingText, setLoadingText] = useState<string | null>(null);
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (state.AccessToken) {
      props.adminService
        .fetchHomes()
        .then(() => {
          // no-op
        })
        .catch((error) => {
          // TODO HANDLE ERROR?
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.AccessToken]);

  const logOut = (e: any) => {
    e.preventDefault();
    setLoadingText(t("loggingOut"));
    props.userService
      .signOut()
      .then(() => {
        setLoadingText(null);
        props.history.replace("/kirjautuminen");
      })
      .catch(() => { });
  };

  function toggleMenu() {
    // @ts-ignore
    document.getElementById("mainMenu")?.toggle();
  }

  function getClassName(
    path: string,
    tab: MainTab | null,
    fullPath: boolean = false
  ): string {
    let tabValid = true;
    if (tab != null && uiState.selectedMainTab !== tab) {
      tabValid = false;
    }
    let pathValid;
    if (fullPath) {
      pathValid = location.pathname === path;
    } else {
      pathValid = location.pathname.split("/")[1] === path;
    }

    // TODO Make routing better for these tabs
    if (tabValid && !pathValid && tab === MainTab.MessagesTab) {
      pathValid = location.pathname.split("/")[1] === "viestit";
    }

    return tabValid && pathValid ? "tabSelected" : "tabNotSelected";
  }

  return (
    <IonMenu
      className="pageWhite sideMenu"
      id="mainMenu"
      contentId="router"
      type="overlay"
    >
      <IonPage>
        <IonLoading
          isOpen={loadingText !== null}
          message={loadingText as string}
        />
        <IonContent>
          <EsperiAppHeader
            hideLeftIcon={uiState.isSplitPaneVisible}
            hideEsperiLogo={false}
            hideRightIcon={uiState.isSplitPaneVisible}
            leftIconType={HeaderLeftIcon.Close}
          />
          <div
            className="ion-padding"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {(state.role === AdminUserRole.ADMIN ||
              state.role === AdminUserRole.SUPERADMIN) && (
                <IonItem
                  className="clickable heading1SemiBold"
                  lines="none"
                  onClick={() => {
                    props.history.replace("/etusivu");
                    uiDispatch(UIActions.changeMainTab(MainTab.AdminsTab));
                    toggleMenu();
                  }}
                >
                  <IonText className={getClassName("etusivu", MainTab.AdminsTab)}>
                    {t("users").toUpperCase()}
                  </IonText>
                </IonItem>
              )}
            <IonItem
              className="clickable heading1SemiBold"
              lines="none"
              onClick={() => {
                props.history.replace("/etusivu");
                uiDispatch(UIActions.changeMainTab(MainTab.EmployeesTab));
                toggleMenu();
              }}
            >
              <IonText
                className={getClassName("etusivu", MainTab.EmployeesTab)}
              >
                {t("employees").toUpperCase()}
              </IonText>
            </IonItem>
            <IonItem
              className="clickable heading1SemiBold"
              lines="none"
              onClick={() => {
                props.history.replace("/etusivu");
                uiDispatch(UIActions.changeMainTab(MainTab.DevicesTab));
                toggleMenu();
              }}
            >
              <IonText className={getClassName("etusivu", MainTab.DevicesTab)}>
                {t("devices").toUpperCase()}
              </IonText>
            </IonItem>
            <IonItem
              className="clickable heading1SemiBold"
              lines="none"
              onClick={() => {
                props.history.replace("/etusivu");
                uiDispatch(UIActions.changeMainTab(MainTab.CustomersTab));
                toggleMenu();
              }}
            >
              <IonText className={getClassName("etusivu", MainTab.CustomersTab)}>
                {t("customers").toUpperCase()}
              </IonText>
            </IonItem>
            <IonItem
              className="clickable heading1SemiBold"
              lines="none"
              onClick={() => {
                props.history.replace("/etusivu");
                uiDispatch(UIActions.changeMainTab(MainTab.OmaUsersTab));
                toggleMenu();
              }}
            >
              <IonText className={getClassName("etusivu", MainTab.OmaUsersTab)}>
                {t("omaUsers").toUpperCase()}
              </IonText>
            </IonItem>
            <IonItem
              className="clickable heading1SemiBold"
              lines="none"
              onClick={() => {
                props.history.replace("/etusivu");
                uiDispatch(UIActions.changeMainTab(MainTab.CustomersNewsTab));
                toggleMenu();
              }}
            >
              <IonText className={getClassName("etusivu", MainTab.CustomersNewsTab)}>
                {t("customernews").toUpperCase()}
              </IonText>
            </IonItem>
            <div className="sideMenuDivider" />
            <IonItem
              className="clickable heading1SemiBold"
              lines="none"
              onClick={() => {
                toggleMenu();
                props.history.replace("/ohje");
              }}
            >
              <IonText className={getClassName("ohje", null)}>
                {t("onboarding").toUpperCase()}
              </IonText>
            </IonItem>
            <div className="sideMenuDivider" />
            <div className="marginTop30px" />
          </div>
          <IonButton
            className="buttonRedOutline sideMenuLogOut"
            expand="block"
            onClick={logOut}
          >
            {t("logOut")}
          </IonButton>
        </IonContent>
      </IonPage>
    </IonMenu>
  );
};

export default withUserService(withAdminService(withRouter(SideMenu)));

import React from "react";
import { IonRow, IonText, IonGrid, IonImg } from "@ionic/react";
import "./EmptyView.css";
import { useTranslation } from "react-i18next";
import { getBrandStrings } from 'util/Branding';

export enum EmptyViewType {
  News,
  Messages,
  Calendar,
  Employees,
  Devices,
  Admin,
  DeviceDeleted,
  OmaUsers,
  Customers,
  CustomerNews
}

interface EmptyViewProps {
  hidden: boolean;
  type: EmptyViewType;
}

const EmptyView = (props: EmptyViewProps) => {
  const { t } = useTranslation();

  function getImage() {
    return undefined;
  }

  function getBody() {
    switch (props.type) {
      case EmptyViewType.Employees:
        return t("employeesEmpty", getBrandStrings());
      case EmptyViewType.Devices:
        return t("devicesEmpty");
      case EmptyViewType.Admin:
        return t("adminEmpty", getBrandStrings());
      case EmptyViewType.OmaUsers:
        return t("omaUsersEmpty");
      case EmptyViewType.Customers:
        return t("customersEmpty");
      case EmptyViewType.CustomerNews:
        return t("customerNewsEmpty");
      case EmptyViewType.News:
        return t("newsEmpty");
      case EmptyViewType.Messages:
        return t("messagesEmpty");
      case EmptyViewType.Calendar:
        return t("calendarEmpty");
    }
  }

  return (
    <IonGrid hidden={props.hidden} className="emptyView contentMaxWidth">
      <div className="marginAuto">
        <IonRow>
          <IonImg className="centered emptyImage" src={getImage()} />
        </IonRow>
        <IonRow>
          <IonText className="centered body1Bold emptyTitle">
            {getBody()}
          </IonText>
        </IonRow>
      </div>
    </IonGrid>
  );
};

export default EmptyView;
